/**
 * This component is used to display the required
 * terms of use statement which can be found in a
 * link in the about tab.
 */
const TermsOfUse = () => {
    return (
        <div>
            <h1>Terms of Use</h1>
        </div>
    );
};

export default TermsOfUse;
