import {
    Button,
    Dialog,
    DialogActions,
    DialogBody,
    DialogContent,
    DialogSurface,
    DialogTitle,
    DialogTrigger,
    Field,
    Input,
    InputOnChangeData,
    makeStyles,
    shorthands,
    Tooltip,
} from "@fluentui/react-components";
import { SettingsRegular } from "@fluentui/react-icons";
import { Icon } from "@iconify/react";
import { app } from "@microsoft/teams-js";
import { useState } from "react";
import { http } from "../models/http";
import { checkTenant } from "../services/dynizer/dynizer";
import { errorColor, successColor } from "./Tab";

type Props = {
    context: app.Context | undefined;
    tenant: string;
    setTenant: (tenant: string) => void;
};

export default function Header({ setTenant, tenant, context }: Props) {
    const [show, setShow] = useState(false);
    const [showPowerBIDialog, setShowPowerBIDialog] = useState(false);

    const [tenantInput, setTenantInput] = useState(tenant);
    const [message, setMessage] = useState("");
    const [pbiError, setError] = useState("");
    const [color, setColor] = useState(errorColor);
    const [loading, setLoading] = useState(false);

    const styles = useStyles();

    const resetValues = () => {
        setTenantInput(tenant);
        setMessage("");
    };

    const valuesChanged = () => {
        return tenantInput.trim() !== tenant;
    };

    const valuesInvalid = () => {
        return !tenantInput || tenantInput !== tenantInput.trim();
    };

    const onTenantChange = (
        e: React.ChangeEvent<HTMLInputElement>,
        data: InputOnChangeData
    ) => {
        setTenantInput(data.value);
    };

    const checkValidTenant = async () => {
        setLoading(true);
        setMessage("");

        let ok = await checkTenant(tenantInput);

        if (ok) {
            setTenant(tenantInput);
            setColor(successColor);
            setMessage(`Connected to tenant '${tenantInput}'`);
        } else {
            setColor(errorColor);
            setMessage(`Cannot reach tenant '${tenantInput}'`);
        }

        setLoading(false);
    };

    const [contextField, contextInfo] = context
        ? context.team?.groupId
            ? [`Team group id`, context.team?.groupId]
            : context.channel?.id
            ? [`Channel id`, context.channel.id] // channel id is empty in General tab, non-empty in other tab
            : context.chat?.id
            ? [`Chat id`, context.chat.id]
            : context.meeting?.id
            ? [`Meeting id`, context.meeting.id]
            : context.user?.id
            ? [`Teams user id`, context.user.id]
            : ["", ""]
        : ["", ""];

    const openPowerBI = async () => {
        var xhttp = new XMLHttpRequest();
        var dashboardUrl = `/powerbi/dashboard-id`;

        xhttp.open("GET", dashboardUrl, true);

        xhttp.setRequestHeader("dynizer-tenant", tenant);

        xhttp.onreadystatechange = () => {
            if (xhttp.readyState == 4) {
                if (xhttp.status === http.OK) {
                    let dashboardID = xhttp.responseText;
                    openDashboard(dashboardID);
                } else {
                    catchXhttpNon2XX(
                        dashboardUrl,
                        xhttp.status,
                        xhttp.statusText,
                        xhttp.responseType === "text" ||
                            xhttp.responseType === ""
                            ? xhttp.responseText
                            : ""
                    );
                }
            }
        };

        xhttp.send();
    };

    const openDashboard = async (dashboardID: string) => {
        let teamID = context?.team?.groupId ? context.team.groupId : "user";
        let userID = context?.user?.id;

        const table = "query_params";
        const columnTeamID = "team_id";
        const columnUserID = "user_id";

        let url = `https://app.powerbi.com/groups/b1f947c8-d5c6-419b-a33c-e34c268a9c56/reports/${dashboardID}/ReportSection?experience=power-bi`;

        let filter = `&filter=${table}%2F${columnTeamID}%20eq%20%27${teamID}%27%20and%20${table}%2F${columnUserID}%20eq%20%27${userID}%27`;

        window.open(url + filter, "_blank");
    };

    const catchXhttpNon2XX = (
        url: string,
        status: number,
        statusText: string,
        responseText: string
    ) => {
        setError(
            `Request to ${url} failed with status ${status}, ${statusText}: ${responseText}`
        );
    };

    const loadDashboardID = () => {};

    return (
        <>
            <div className="title">
                <h2>Document Analysis</h2>
                <div
                    style={{
                        flex: 1,
                        justifyContent: "flex-end",
                        display: "flex",
                    }}
                >
                    <Tooltip content="Power BI Dashboard" relationship="label">
                        <Button
                            onClick={() => {
                                setShowPowerBIDialog(true);
                                loadDashboardID();
                            }}
                            icon={<Icon icon="mdi:monitor-dashboard" />}
                            size="small"
                            className="mr-1"
                        />
                    </Tooltip>

                    <Tooltip content="Settings" relationship="label">
                        <Button
                            onClick={() => {
                                resetValues();
                                setShow(true);
                            }}
                            icon={<SettingsRegular />}
                            size="small"
                        />
                    </Tooltip>
                </div>
            </div>

            <Dialog
                open={show}
                onOpenChange={(event, data) => setShow(data.open)}
            >
                <DialogSurface>
                    <DialogBody>
                        <DialogTitle>Settings</DialogTitle>
                        <DialogContent>
                            <div className={styles.form}>
                                <Field
                                    label={contextField}
                                    validationState="none"
                                >
                                    <Input
                                        value={contextInfo}
                                        disabled={true}
                                    />
                                </Field>

                                <Field label="Dynizer" validationState="none">
                                    <Input
                                        value={tenantInput}
                                        onChange={onTenantChange}
                                    />
                                </Field>
                            </div>
                            <div
                                style={{ color: color }}
                                className="pre-wrap mt-2"
                            >
                                {message}
                            </div>
                        </DialogContent>

                        <DialogActions>
                            <DialogTrigger disableButtonEnhancement>
                                <Button appearance="secondary">Close</Button>
                            </DialogTrigger>
                            <Button
                                appearance="primary"
                                onClick={() => {
                                    checkValidTenant();
                                }}
                                disabled={
                                    loading ||
                                    !valuesChanged() ||
                                    valuesInvalid()
                                }
                            >
                                Save Changes
                            </Button>
                        </DialogActions>
                    </DialogBody>
                </DialogSurface>
            </Dialog>

            <Dialog
                open={showPowerBIDialog}
                onOpenChange={(event, data) => setShowPowerBIDialog(data.open)}
            >
                <DialogSurface>
                    <DialogBody>
                        <DialogTitle>Power BI Dashboard</DialogTitle>
                        <DialogContent>
                            <div
                                style={{ color: errorColor }}
                                className="pre-wrap mt-2"
                            >
                                {pbiError}
                            </div>
                        </DialogContent>

                        <DialogActions>
                            <DialogTrigger disableButtonEnhancement>
                                <Button appearance="secondary">Cancel</Button>
                            </DialogTrigger>
                            <Button
                                appearance="primary"
                                onClick={() => {
                                    openPowerBI();
                                }}
                            >
                                Open Dashboard
                            </Button>
                        </DialogActions>
                    </DialogBody>
                </DialogSurface>
            </Dialog>
        </>
    );
}

const useStyles = makeStyles({
    form: {
        maxWidth: "400px",
        display: "flex",
        flexDirection: "column",
        ...shorthands.gap("2px"),
    },
});
