import { useEffect } from "react";

type Props = {
    pollHook: () => void;
    intervalMs: number;
};

const Polling = (props: Props) => {
    useEffect(() => {
        const poll = () => {
            props.pollHook();
        };

        const intervalId = setInterval(poll, props.intervalMs);

        return () => clearInterval(intervalId);
    }, [props.pollHook, props.intervalMs]);

    return null;
};

export default Polling;
