import "bootstrap/dist/css/bootstrap.min.css";
import { createRoot } from "react-dom/client";
import App from "./components/App";
import "./index.css";
import './components/webcomponents/polyfills.be4631ccdcd07ea5';
import './components/webcomponents/main.19e3a506f7f9d600';
import './components/webcomponents/runtime.074a8dee3fdd6703';
import './components/webcomponents/styles.aec3b53a754327df.css';

const container = document.getElementById("root");
const root = createRoot(container);
root.render(<App />);
