import React, { useEffect, useRef } from "react";

type Props = { inJson: any };

const Pseudo: React.FC<Props> = ({ inJson }) => {
    const refElm = useRef<any>(null);

    useEffect(() => {
        if (inJson) {
            let data = fixNullList(inJson);
            refElm.current.data = data;
        }
    }, [inJson]);

    return (
        <consono-pseudonymize-document
            ref={refElm}
        ></consono-pseudonymize-document>
    );
};
export default Pseudo;

const fixNullList = (inJson: any) => {
    let data = JSON.parse(inJson);
    if (!data["pseudo_value_lists"]) {
        data["pseudo_value_lists"] = [];
    }
    return data;
};
