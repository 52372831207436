import {
    Button,
    Input,
    InputOnChangeData,
    Label,
    makeStyles,
    shorthands,
    useId,
} from "@fluentui/react-components";
import { useState } from "react";
import { checkTenant } from "../services/dynizer/dynizer";
import { errorColor } from "./Tab";

type Props = {
    setTenant: (tenant: string) => void;
};

export default function Settings(props: Props) {
    const [input, setInput] = useState("");
    const [message, setMessage] = useState("");
    const [loading, setLoading] = useState(false);

    const styles = useStyles();
    const inputId = useId("input");

    const onTenantChange = (
        e: React.ChangeEvent<HTMLInputElement>,
        data: InputOnChangeData
    ) => {
        setInput(data.value);
    };

    const checkValidTenant = async () => {
        setLoading(true);
        setMessage("");

        let ok = await checkTenant(input);

        if (ok) {
            props.setTenant(input);
        } else {
            setMessage(`Cannot reach tenant '${input}'`);
        }

        setLoading(false);
    };

    const submitDisabled = !input || loading;

    const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (!submitDisabled && e.key === "Enter") {
            e.preventDefault();
            if (input) {
                checkValidTenant();
            }
        }
    };

    return (
        <>
            <div className={styles.form}>
                <Label htmlFor={inputId}>Dynizer:</Label>
                <Input
                    id={inputId}
                    onChange={onTenantChange}
                    value={input}
                    onKeyDown={onKeyDown}
                />
            </div>
            <Button
                className="mt-2"
                appearance="primary"
                disabled={submitDisabled}
                onClick={() => {
                    checkValidTenant();
                }}
            >
                Start
            </Button>
            <div style={{ color: errorColor }} className="pre-wrap mt-2">
                {message}
            </div>
        </>
    );
}

const useStyles = makeStyles({
    form: {
        maxWidth: "400px",
        display: "flex",
        flexDirection: "column",
        ...shorthands.gap("2px"),
    },
});
