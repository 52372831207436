import { Button } from "@fluentui/react-components";
import { useState } from "react";
import Modal from "react-bootstrap/Modal";
import ComponentDetection from "./ComponentDetection";
import KeySegments from "./KeySegments";
import Pseudo from "./Pseudo";
import Summary from "./Summary";
import ToneAnalysis from "./ToneAnalysis";
import WordCloud from "./WordCloud";

type Props = { inJson: any; inPseudoJson: any };

const webcomponent = {
    WWWW: "Who-What-Where-When",
    Summary: "Summary",
    KeySegments: "Key Segments",
    WordCloud: "Word Cloud",
    ToneAnalysis: "Tone Analysis",
    Pseudo: "Anonymization",
};

export default function Tiles(props: Props) {
    const [show, setShow] = useState(false);
    const [title, setTitle] = useState("");

    const titles = [
        webcomponent.WWWW,
        webcomponent.Summary,
        webcomponent.KeySegments,
        webcomponent.WordCloud,
        webcomponent.ToneAnalysis,
        webcomponent.Pseudo,
    ];

    let buttons = titles.map((title) => (
        <Button
            appearance="primary"
            onClick={() => {
                setShow(true);
                setTitle(title);
            }}
            key={title}
            className="mt-1 mr-1 mb-1"
        >
            {title}
        </Button>
    ));

    const getModalbody = () => {
        switch (title) {
            case webcomponent.WWWW:
                return (
                    <ComponentDetection
                        inJson={props.inJson}
                    ></ComponentDetection>
                );
            case webcomponent.Summary:
                return <Summary inJson={props.inJson}></Summary>;
            case webcomponent.KeySegments:
                return <KeySegments inJson={props.inJson}></KeySegments>;
            case webcomponent.WordCloud:
                return <WordCloud inJson={props.inJson}></WordCloud>;
            case webcomponent.ToneAnalysis:
                return <ToneAnalysis inJson={props.inJson}></ToneAnalysis>;
            case webcomponent.Pseudo:
                return <Pseudo inJson={props.inPseudoJson}></Pseudo>;
        }
    };

    return (
        <>
            <div>{buttons}</div>

            <Modal
                show={show}
                onHide={() => setShow(false)}
                dialogClassName="tiles-modal"
            >
                <Modal.Header closeButton>
                    <Modal.Title>{title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>{getModalbody()}</Modal.Body>
            </Modal>
        </>
    );
}
