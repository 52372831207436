import React, { useEffect, useRef } from "react";

type Props = { inJson: any };

const WordCloud: React.FC<Props> = ({ inJson }) => {
    const refElm = useRef<any>(null);

    useEffect(() => {
        if (inJson) {
            refElm.current.data = JSON.parse(inJson).document;
        }
    }, [inJson]);

    return <consono-word-cloud ref={refElm}></consono-word-cloud>;
};
export default WordCloud;
