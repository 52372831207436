import React, { useEffect, useRef } from "react";

type Props = { inJson: any };

const Summary: React.FC<Props> = ({ inJson }) => {
    const refElm = useRef<any>(null);

    useEffect(() => {
        if (inJson && refElm.current) {
            refElm.current.data = JSON.parse(inJson).document;
        }
    }, [inJson]);

    return <consono-summary ref={refElm}></consono-summary>;
};

export default Summary;
