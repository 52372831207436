import React, { useEffect, useRef } from "react";

type Props = { inJson: any };

const KeySegments: React.FC<Props> = ({ inJson }) => {
    const refElm = useRef<any>(null);

    useEffect(() => {
        if (inJson) {
            refElm.current.data = JSON.parse(inJson).document;
        }
    }, [inJson]);

    return <consono-keysegments ref={refElm}></consono-keysegments>;
};

export default KeySegments;
