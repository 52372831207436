import { Button, Checkbox, Dialog, Tooltip } from "@fluentui/react-components";
import { Icon } from "@iconify/react";
import { ProviderState, Providers } from "@microsoft/mgt-element";
import { DriveItem } from "@microsoft/microsoft-graph-types";
import { useEffect, useState } from "react";
import ReactModal from "react-bootstrap/Modal";
import urlJoin from "url-join";
import { DriveItemsResponse } from "../models/ms-graph";
import {
    allowedFileTypes,
    mimeTypeToDynizerType,
    pipeline,
    status,
} from "../models/pipeline";
import FileCustom from "./FileCustom";
import FileListModalAnalyze from "./FileListModalAnalyze";
import FileListModalRag from "./FileListModalRag";
import Loading from "./Loading";
import Rag from "./Rag";
import VirtualScroller from "./VirtualScroller";
import { center } from "./css";

type Props = {
    reload: boolean;
    fileClick: (file: DriveItem) => void;
    startAnalysis: (files: DriveItem[]) => void;
    folderUp: () => void;
    fetchAndSetPipelines: (
        items: DriveItem[],
        append: boolean
    ) => Promise<void>;
    fetchPipelines: (items: DriveItem[]) => Promise<pipeline[]>;
    setError: (err: string) => void;
    currentFolder: string;
    pipelines: pipeline[];
    selectedFile: DriveItem | undefined;
    ctx: any;
    items: DriveItem[];
    setItems: (items: DriveItem[]) => void;
    tenant: string;
};

export default function FileList(props: Props) {
    const [filterActive, setFilterActive] = useState(true);
    const [noFiles, setNoFiles] = useState(false);
    const [nextPageUrl, setNextPageUrl] = useState("");
    const [selectedItems, setSelectedItems] = useState<DriveItem[]>([]);
    const [graphBaseUri, setGraphBaseUri] = useState(null as any);
    const [checkAllItems, setCheckAllItems] = useState(false);
    const [loadingMore, setLoadingMore] = useState(false);
    const [loading, setLoading] = useState(false);
    const [initialLoading, setInitialLoading] = useState(true);
    const [showRagChat, setShowRagChat] = useState(false);
    const [showAnalyzeFileList, setShowAnalyzeFileList] = useState(false);
    const [showRagFileList, setShowRagFileList] = useState(false);
    const [ragFilesID, setRagFilesID] = useState<string[]>([]);

    useEffect(() => {
        if (props.ctx) {
            if (props.ctx?.team?.groupId) {
                setGraphBaseUri(`groups/${props.ctx?.team?.groupId}`);
            } else if (props.ctx?.chat?.id) {
                setGraphBaseUri("me");
            } else {
                setGraphBaseUri("me");
            }
        }
    }, [props.ctx]);

    useEffect(() => {
        loadItems();
        setCheckAllItems(false);
    }, [graphBaseUri, props.currentFolder, props.reload]);

    useEffect(() => {
        updateEmptyState();
    }, [filterActive, props.items]);

    useEffect(() => {
        setSelectedItems(
            checkAllItems ? props.items.filter((item) => hasCheckBox(item)) : []
        );
    }, [checkAllItems]);

    const handleCheckChange = async (item: DriveItem, checked: boolean) => {
        setSelectedItems(
            checked
                ? [...selectedItems, item]
                : selectedItems.filter(
                      (selectedItem) => item.id !== selectedItem.id
                  )
        );
    };

    const handleFilterChange = () => {
        setFilterActive(!filterActive);
    };

    const handleToggleAllChange = () => {
        setCheckAllItems(!checkAllItems);
    };

    const handleStartAnalysis = (files: DriveItem[]) => {
        props.startAnalysis(files);
        setShowAnalyzeFileList(false);
    };

    const handleStartRag = (files: DriveItem[]) => {
        setRagFilesID(files.map((file) => file.id || ""));
        setShowRagFileList(false);
        setShowRagChat(true);
    };

    const fullCurrentFolder = (currentFolder: string) => {
        let out = "";

        if (currentFolder === "/") {
            out = `${graphBaseUri}/drive/root/children`;
        } else {
            let base = `${graphBaseUri}/drive/root:/`;
            out = urlJoin(base, currentFolder) + ":/children";
        }

        return out;
    };

    const updateEmptyState = () => {
        if (props.items.length === 0) {
            setNoFiles(true);
        } else if (!filterActive) {
            setNoFiles(false);
        } else {
            setNoFiles(
                !props.items.find(
                    (item: DriveItem) => hasValidMimeType(item) || !item.file
                )
            );
        }
    };

    const loadItems = () => {
        if (!graphBaseUri) return;

        setLoading(true);

        Providers.globalProvider.graph.client
            .api(fullCurrentFolder(props.currentFolder))
            .get(async (err, itemsResponse: DriveItemsResponse) => {
                if (err) {
                    if (err.statusCode === 401) {
                        Providers.globalProvider.setState(
                            ProviderState.SignedOut
                        );
                    }
                    props.setError(err.message);
                } else {
                    await props.fetchAndSetPipelines(
                        itemsResponse.value,
                        false
                    );

                    setNextPageUrl(
                        itemsResponse["@odata.nextLink"]
                            ? itemsResponse["@odata.nextLink"]
                            : ""
                    );

                    props.setItems(itemsResponse.value);
                    setSelectedItems([]);
                }

                setLoading(false);
                setInitialLoading(false);
            });
    };

    const loadNextPage = () => {
        if (!nextPageUrl) return;

        setLoadingMore(true);
        setLoading(true);

        Providers.globalProvider.graph.client
            .api(nextPageUrl)
            .get(async (err, itemsResponse: DriveItemsResponse) => {
                if (err) {
                    if (err.statusCode === 401) {
                        Providers.globalProvider.setState(
                            ProviderState.SignedOut
                        );
                    }
                    props.setError(err.message);
                } else {
                    await props.fetchAndSetPipelines(itemsResponse.value, true);

                    props.setItems([...props.items, ...itemsResponse.value]);

                    setNextPageUrl(
                        itemsResponse["@odata.nextLink"]
                            ? itemsResponse["@odata.nextLink"]
                            : ""
                    );
                }

                setLoadingMore(false);
                setLoading(false);
            });
    };

    const buttons = (
        <>
            <Button
                appearance="primary"
                disabled={props.currentFolder === "/" || loading}
                onClick={() => {
                    props.folderUp();
                }}
                className="mr-1 mb-1"
            >
                <Icon className="mr-1" icon="mdi:undo-variant" />
                Back
            </Button>

            <Button
                appearance="primary"
                onClick={() => {
                    try {
                        loadItems();
                    } catch (err: any) {
                        props.setError(err.message);
                    }
                }}
                className="mr-1 mb-1"
            >
                Reload
            </Button>

            <Button
                appearance="primary"
                disabled={!selectedItems.length}
                onClick={() => {
                    setShowAnalyzeFileList(true);
                }}
                className="mr-1 mb-1"
            >
                Analyze{" "}
            </Button>

            <Button
                appearance="primary"
                disabled={!selectedItems.length}
                onClick={() => {
                    setShowRagFileList(true);
                }}
                className="mr-1 mb-1"
            >
                MAG Chat
            </Button>

            <div>
                <Checkbox
                    checked={checkAllItems}
                    label={"Select all"}
                    onChange={handleToggleAllChange}
                />

                <Tooltip
                    relationship="description"
                    content={`Check to only show files that can be processed by Dynizer: ${allowedFileTypes()}`}
                >
                    <Checkbox
                        checked={filterActive}
                        label={"Relevant files only"}
                        onChange={handleFilterChange}
                        defaultChecked={true}
                    />
                </Tooltip>
            </div>
        </>
    );

    const noFilesJsx = (
        <div style={center}>
            {props.items.length === 0 ? (
                <div>Folder is empty</div>
            ) : (
                <div>No relevant files</div>
            )}
        </div>
    );

    const fileList = props.items
        .filter((item) => !filterActive || !item.file || hasValidMimeType(item))
        .map((item) => (
            <FileCustom
                file={item}
                checked={!!selectedItems.find((el) => el.id === item.id)}
                checkChange={handleCheckChange}
                onClick={props.fileClick}
                key={item.id}
                pipeline={props.pipelines.find(
                    (pipeline) => pipeline.teamsId === item.id
                )}
                selected={props.selectedFile?.id === item.id}
            ></FileCustom>
        ));

    return (
        <>
            <Dialog
                open={showAnalyzeFileList}
                onOpenChange={(event, data) =>
                    setShowAnalyzeFileList(data.open)
                }
            >
                <FileListModalAnalyze
                    fetchPipelines={props.fetchPipelines}
                    selectedItems={selectedItems}
                    startAnalysis={handleStartAnalysis}
                    graphBaseUri={graphBaseUri}
                    setError={props.setError}
                ></FileListModalAnalyze>
            </Dialog>

            <Dialog
                open={showRagFileList}
                onOpenChange={(event, data) => setShowRagFileList(data.open)}
            >
                <FileListModalRag
                    fetchPipelines={props.fetchPipelines}
                    selectedItems={selectedItems}
                    startAnalysis={handleStartRag}
                    graphBaseUri={graphBaseUri}
                    setError={props.setError}
                ></FileListModalRag>
            </Dialog>

            <ReactModal
                show={showRagChat}
                onHide={() => setShowRagChat(false)}
                dialogClassName="tiles-modal"
            >
                <ReactModal.Header closeButton>
                    <ReactModal.Title>MAG Chat</ReactModal.Title>
                </ReactModal.Header>
                <ReactModal.Body>
                    <Rag
                        tenant={props.tenant}
                        fileIDs={ragFilesID}
                        context={props.ctx}
                    ></Rag>
                </ReactModal.Body>
            </ReactModal>

            <div>{buttons}</div>

            {initialLoading ? (
                <Loading />
            ) : noFiles && !nextPageUrl ? (
                noFilesJsx
            ) : (
                <VirtualScroller
                    loadingMore={loadingMore}
                    jsxItems={fileList}
                    loadNextPage={loadNextPage}
                    hasMore={!!nextPageUrl}
                    nbLoadedFiled={props.items.length}
                ></VirtualScroller>
            )}
        </>
    );
}

export const canBeAnalyzed = (item: DriveItem, pipelines: pipeline[]) => {
    return (
        hasValidMimeType(item) &&
        !pipelines.find((pipeline) => pipeline.teamsId === item.id)
    );
};

export const isAnalyzed = (item: DriveItem, pipelines: pipeline[]) => {
    return (
        hasValidMimeType(item) &&
        pipelines.find(
            (pipeline) =>
                pipeline.teamsId === item.id &&
                pipeline.status === status.SUCCESS
        )
    );
};

export const hasCheckBox = (item: DriveItem) => {
    return item.folder || hasValidMimeType(item);
};

export const hasValidMimeType = (file: DriveItem) =>
    !!file.file?.mimeType && !!mimeTypeToDynizerType[file.file?.mimeType];
