/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

/**
 * Action
 * Message to hold a **Action**
 */
export interface Action {
  /** The unique name for the **Action**. */
  action_name?: string;
  /** The **ActionLabels** of the **Action**. */
  action_labels?: ActionLabel[];
}

/**
 * ActionInstanceRecord
 * Message to hold a result row for **Instances** of an specific **Action**
 */
export interface ActionInstanceRecord {
  /** The special UUID identifying an **Instance**. */
  instance_id?: string;
  /** the string representation of the values. */
  instance_cell_values?: string[];
}

/**
 * ActionLabel
 * An **ActionLabel** defines a group of columns with the same name and **ComponentType**.<br>The default **DataType** is used when no overwrite is used.<br>Min and Max occurrence is used to limit the amount of values which can be used for an **ActionLabel**.<br>The alternative **dataTypes** can be used to constrain which **DataTypes** can be used for the overwrite.<br>With it you can define all the **DataTypes** allowed to be used under this **ActionLabel**.
 */
export interface ActionLabel {
  /** The unique name of the **ActionLabel**. */
  action_label_name: string;
  /** The **ComponentType** for this **ActionLabel**. */
  component: ComponentType;
  /** The **DataType** for this **ActionLabel**.<br>This **DataType** will be used when creating **Instances** with this **ActionLabel**. */
  default_data_type: DataType;
  /**
   * Allows to set minimum number of values, which need to be provided when using this **ActionLabel*** in an **Instance**.
   * @format int64
   */
  min_occurrence: number;
  /**
   * Allows to set maximum number of values, which need to be provided when using this **ActionLabel*** in an **Instance**.<br>This value needs to be either zero or it can be equal or greater then *min_occurrence*.<br>Setting it to 0 will be interpreted as open-ended.
   * @format int64
   */
  max_occurrence: number;
  /** List of additional allowed **DataTypes** for this **ActionLabel**.<br>If left empty only the *default_data_type* is allowed for the values of this **ActionLabel**. */
  alternative_data_types: DataType[];
  /** The **ShareName** to use by default when an new instance is created and a value is set for this **ActionLabel** */
  share_name?: string;
}

/**
 * ActionLabelLink
 * The **ActionLabel** links of the request **Actions**.
 */
export interface ActionLabelLink {
  /** The left **ActionLabels** of the link */
  left_action_label: ActionNameActionLabelName;
  /** The left **ActionLabels** of the link */
  right_action_label: ActionNameActionLabelName;
  /**
   * The total values in the left **ActionLabel**
   * @format int64
   */
  left_total: string;
  /**
   * The total values in the right **ActionLabel**
   * @format int64
   */
  right_total: string;
  /**
   * The total shared values in both **ActionLabel**
   * @format int64
   */
  shared_total: string;
}

/**
 * ActionLabelLinkPair
 * This is used to link **Actions**.
 */
export interface ActionLabelLinkPair {
  /** The first **ActionLabels** which will be used to link **Actions**. */
  first_action_label: ActionLabelSelector;
  /** The second **ActionLabels** which will be used to link **Actions**. */
  second_action_label: ActionLabelSelector;
}

/**
 * ActionLabelLinkRequest
 * This is used to link **Actions**.
 */
export interface ActionLabelLinkReq {
  /** The user friendly name of the **ActionLabel** link **Actions**. */
  action_label_link_name: string;
  /** The **ActionLabels** which will be used to link **Actions**. */
  action_label_pair: ActionLabelLinkPair;
  /** If set to true, the links are build immediately */
  sync_build?: boolean;
}

/**
 * ActionLabelLinkResponse
 * This is ActionLabelLink
 */
export interface ActionLabelLinkRes {
  /** The user friendly name of the **ActionLabel** link **Actions**. */
  action_label_link_name: string;
  /** The **ActionLabels** which will be used to link **Actions**. */
  action_label_pair: ActionLabelLinkPair;
}

/**
 * ActionLabelSelector
 * This is used to define a unique **Label** withing an **Action**.
 */
export interface ActionLabelSelector {
  /** The name of the **Actions**. */
  action_name: string;
  /** The name of the **ActionLabel**. */
  action_label: string;
}

export interface ActionName {
  name?: string;
}

/**
 * ActionNameActionLabelName
 * **Action** name and label pair
 */
export interface ActionNameActionLabelName {
  /** The **Action** name */
  action_name: string;
  /** The **ActionLabel** name */
  action_label_name?: string;
}

/**
 * ActionNameArrayResponse
 * Response which holds an array of **Action** records.
 */
export interface ActionNameArrayRes {
  /**
   * Total number of **Actions** in the system
   * @format int64
   */
  total_actions: number;
  /** This holds an array of **Action** names. */
  action_names: string[];
}

/**
 * ActionResponse
 * Response which holds an **Action**.
 */
export interface ActionRes {
  /** This holds an **Action** */
  action: Action;
}

export interface ActionTypeLabel {
  label?: string;
}

/**
 * ActionTypeResponse
 * Response which holds an **ActionType**.
 */
export interface ActionTypeRes {
  /**
   * The **ActionType**.
   * @format int64
   */
  action_type?: string;
  /** This holds an **ActionTypeLabel** */
  action_type_label: string;
}

/**
 * AddUserRequest
 * Holds all properties which are needed to create a new user in the Dynizer.
 */
export interface AddUserReq {
  /** Name of the new user to be created */
  user: string;
  /** The password string which will be security hashed and stored. */
  password: string;
  /** All the roles for the new user. */
  roles?: DynizerRole[];
  /** If set to true, the new user will not be able to login. */
  disabled?: boolean;
}

/**
 * ChangePasswordRequest
 * Request for an user to change it's own password.
 */
export interface ChangePasswordReq {
  /** The old password string which will be security hashed and ompared with the stored. */
  old_password: string;
  /** The new password string which will be security hashed and stored. */
  new_password: string;
}

/**
 * CheckPasswordReq
 * Request checking a potential password.
 */
export interface CheckPasswordReq {
  /** the password which is to be checked */
  password: string;
}

/**
 * CheckPasswordRes
 * an array of ResultRow messages for the list request
 */
export interface CheckPasswordRes {
  /**
   * strength of the pwaasowrd in percent
   * @format int64
   * @max 100
   */
  strength: number;
  /** human readable information on how long it will take to crack the password */
  crack_time: string;
  /** if the password would be valid when used for a user.<br>If not the fail_reason will give the detaiuls */
  valid: boolean;
  /** The reason why the password does not meet the criterias. */
  fail_reason: string;
}

/**
 * ComponentType
 * - Who: subject data category
 *  - What: object data category
 *  - Where: spatial data category
 *  - When: temporal data category
 * @default "InvalidComponentType"
 */
export enum ComponentType {
  InvalidComponentType = "InvalidComponentType",
  Who = "Who",
  What = "What",
  Where = "Where",
  When = "When",
}

/** Returns details of the number of **Instances** found divided by **ComponentType** for the **DataElement** */
export interface ComponentTypeUsage {
  /** Indicates the **ComponentType** for the **DataElement** that was found. */
  component_type: ComponentType;
  /**
   * Indicates in how many different **Instances** the **DataElement** / **ComponetType** combinations was found.
   * @format int64
   */
  num_instances: number;
}

/**
 * ConfigKV
 * Holds Dynizer system configuration
 */
export interface ConfigKV {
  /** The name of the Dynizer config parameter */
  config_key: string;
  /**
   * Holds the value of a numeric config parameter
   * @format float
   */
  numeric_value?: number;
  /** Holds the value of a string config parameter */
  string_value?: string;
}

/**
 * CountResponse
 * Generic count response.
 */
export interface CountRes {
  /**
   * The count of the requested data.
   * @format int64
   */
  count: number;
}

/**
 * CreateActionRequest
 * This is used to create an **Action**.<br>If supplied **ActionLabels** can be addes as well.<br>The *if_not_exists* flag allows if set not to raise an error if the **Action** already exists.
 */
export interface CreateActionReq {
  /** The unique name for the **Action**.<br>Use the CheckActionName, call to verify the name, without trying to create it. */
  action_name: string;
  /** if set to true -> NO ERROR is returned if a **Action** by the provided name already exists in the system.<br>Any ActionLabels provided are IGNORED when if_not_exists == true and the **Action** already existed. */
  if_not_exists?: boolean;
  /** This allows to create the **ActionLabels** for the newly created **Action** in one go. */
  action_labels?: ActionLabel[];
}

/**
 * CreateActionTypeRequest
 * This is used to create an **ActionType**.<br>The *if_not_exists* flag allows if set not to raise an error if the **ActionType** already exists.
 */
export interface CreateActionTypeReq {
  /** The unique name for the **ActionType**. */
  action_type_label: string;
  /** if set to true -> NO ERROR is returned if a **ActionType** by the provided name already exists in the system. */
  if_not_exists?: boolean;
}

/**
 * CreateInstanceRequest
 * This will create an Instance, for each ActionLabel defined in the Action and element can be defined.<br>Each element can have multiple values.
 */
export interface CreateInstanceReq {
  /** This is the name of the **Action** for which the **Instance** will be created */
  action_name: string;
  /** Provide all ActionLabel and the associated values. */
  instance_elements: InstanceElement[];
  /** MetaData can also be added in one go */
  instance_metadata?: MetaDataKeyValue[];
}

/**
 * CreateMetaDataKeyRequest
 * Properties to create a new MetaDataKey
 */
export interface CreateMetaDataKeyReq {
  /** The unique name of a **MetaDataKey**.<br>The CheckMetaDataKeyName call can be used to check if the name can be used. */
  key_name: string;
  /** The **DataType** of a **MetaDataKey**.<br>This **DataType** will be used when creating values for the MetaData with this **MetaDataKey**. */
  default_data_type: DataType;
  /**
   * How many values at most can be stored in the **MetaDataKey** value array for one use.<br>Not setting this property or setting it to 0, will mean to the maximum based on system settings.
   * @format int64
   */
  max_occurrence?: number;
  /** List of additional allowed **DataTypes** for this **MetaDataKey**.<br>If left empty only the *default_data_type* is allowed for the values of this **MetaDataKey**. */
  alternative_data_types?: DataType[];
}

/** CreateObjectRequest */
export interface CreateObjectReq {
  /** The **Object** type to identify which **ObjectReference** will be created. */
  type: ObjectDataType;
}

/** Creates a new **ShareName** */
export interface CreateShareNameReq {
  /** Name of the share */
  share_name: string;
  /** Defines if an error should be returned if the **ShareName** already exists */
  if_not_exists?: boolean;
}

/**
 * DynizerQueryParameter
 * Holds Dynizer Query Parameter
 */
export interface DQLParam {
  /**
   * identifies the alias in the DQL which will be replaced by this parameter .
   * @format int64
   */
  index?: number;
  /** string value representation of the parameter, optional parsing can be specified by format. */
  string_value?: string;
  /**
   * binary value representation of the parameter, optional parsing can be specified by format.
   * @format byte
   */
  binary_value?: string;
  /**
   * overwrites the default_data_type from the ActionLabel definition for which field the paramter is used.
   * Allows to specify on how the value is to be parsed from string to the internal DataType for storing
   */
  spec?: DataTypeFormat;
}

/**
 * DynizerQueryParameterDescription
 * Holds Dynizer Query Parameter Description
 */
export interface DQLParamDescription {
  /**
   * identifies the alias in the DQL which will be replaced by this parameter .
   * @format int64
   */
  index?: number;
  /** The DQL parameter data type */
  data_type?: DataType;
}

/**
 * DynizerQueryLanguageRequest
 * DQL statment
 */
export interface DQLReq {
  /** The DQL string */
  dql: string;
  /** The DQL parameter. */
  parameters?: DQLParam[];
}

/**
 * DynizerQueryResponse
 * Holds the different Dynizer Query responses.
 */
export interface DQLRes {
  /** Holds a SELECT result */
  select_result?: SelectDQLResult;
  /**
   * Number of rows affected by the DQL statement.
   * @format int64
   */
  rows_affected?: number;
}

/**
 * DQLType
 * - DQL_None: Empty query type
 *  - DQL_Set: Type of a SET query
 *  - DQL_Show: Type of a SHOW query
 *  - DQL_Select: Type of a SELECT query
 *  - DQL_Insert: Type of a INSERT query
 *  - DQL_Update: Type of a UPDATE query
 *  - DQL_Delete: Type of a DELETE query
 *  - DQL_CreateSchema: Type of a CREATE SCHEMA query
 *  - DQL_CreateTable: Type of a CREATE_TABLE query
 *  - DQL_AlterSchema: Type of a ALTER SCHEMA query
 *  - DQL_AlterTable: Type of a ALTER TABLE query
 *  - DQL_DropSchema: Type of a DROP SCHEMA query
 *  - DQL_DropTable: Type of a DROP TABLE query
 *  - DQL_Begin: Type of a BEGIN query => Not implemented, no-op
 *  - DQL_Commit: Type of a COMMIT query => Not implemented, no-op
 *  - DQL_Rollback: Type of a ROLLBACK query => Not implemented, no-op
 * @default "DQL_None"
 */
export enum DQLType {
  DQLNone = "DQL_None",
  DQLSet = "DQL_Set",
  DQLShow = "DQL_Show",
  DQLSelect = "DQL_Select",
  DQLInsert = "DQL_Insert",
  DQLUpdate = "DQL_Update",
  DQLDelete = "DQL_Delete",
  DQLCreateSchema = "DQL_CreateSchema",
  DQLCreateTable = "DQL_CreateTable",
  DQLAlterSchema = "DQL_AlterSchema",
  DQLAlterTable = "DQL_AlterTable",
  DQLDropSchema = "DQL_DropSchema",
  DQLDropTable = "DQL_DropTable",
  DQLBegin = "DQL_Begin",
  DQLCommit = "DQL_Commit",
  DQLRollback = "DQL_Rollback",
}

/** Returns **DataElements** which match a contains value */
export interface DataElement {
  /** The string Value of the returned **DataElement**. */
  value: string;
  /** The **DataTypes** of the returned **DataElement**. */
  data_type: DataType;
  /**
   * Indicates in how many different **Instances** the **DataElement** was found.
   * @format int64
   */
  num_instances: number;
  /** Indicates in how many different **Instances** the **DataElement** was found. */
  by_component_type?: ComponentTypeUsage[];
  /** An array of values which can be used to perform contains on the query to filter the specific value as well as the expansions entered by the initial request. */
  contains?: string[];
}

/**
 * DataType
 * - DT_Integer: Integer
 *  - DT_String: String
 *  - DT_Boolean: Boolean
 *  - DT_Decimal: Decimal fixed
 *  - DT_Timestamp: Timestamp
 *  - DT_Uri: URI
 *  - DT_Void: VOID is a designated DataType to indicate no value
 *  - DT_Float: Float
 *  - DT_UnsignedInteger: UInt
 *  - DT_Binary: Byte Array
 *  - DT_Uuid: UUID
 *  - DT_Text: FullTextIndex large object
 *  - DT_Blob: Binary large object
 *  - DT_Clob: Character large object
 *  - DT_Any: DT_Date = 16;
 * DT_Time = 17;
 * special flag to indiacte all DataTypes, to be used in AlternativeDataTypes for ActionLabels and MetaDataKeys
 *  - DT_Delete: special flag to indiacte thet the value is to be removed
 * @default "InvalidDataType"
 */
export enum DataType {
  InvalidDataType = "InvalidDataType",
  DTInteger = "DT_Integer",
  DTString = "DT_String",
  DTBoolean = "DT_Boolean",
  DTDecimal = "DT_Decimal",
  DTTimestamp = "DT_Timestamp",
  DTUri = "DT_Uri",
  DTVoid = "DT_Void",
  DTFloat = "DT_Float",
  DTUnsignedInteger = "DT_UnsignedInteger",
  DTBinary = "DT_Binary",
  DTUuid = "DT_Uuid",
  DTText = "DT_Text",
  DTBlob = "DT_Blob",
  DTClob = "DT_Clob",
  DTAny = "DT_Any",
  DTDelete = "DT_Delete",
}

/**
 * DataTypeFormat
 * DataType override, and an optional format string
 */
export interface DataTypeFormat {
  /** overwrites the default_data_type from the default value */
  data_type?: DataType;
  /** is a format string which allows to specify on how the input-value is to be parsed from string to the internal DataType for storing */
  format?: string;
  /** Defines if the **ShareName** value is used to either clear the **ShareName** if a default is set or to use one or to use one which differs from the default one. */
  share_name_override?: boolean;
  /** The **ShareName** to use by default when an new instance is created and a value is set for this **ActionLabel** */
  share_name?: string;
}

/**
 * DownloadObjectDataResponse
 * URL to fetch an **Object**
 */
export interface DownloadObjectDataRes {
  /**
   * The **Object** data as bytes.
   * @format byte
   */
  data?: string;
}

/**
 * DynizerRole
 * Defines one Role of the Dynizer
 */
export interface DynizerRole {
  /** Unique name of the system-wide role */
  name: string;
}

/**
 * EmptyResponse
 * Response with no data.<br>Used whenever there is no payload to be returned
 */
export type EmptyRes = object;

/** ErrorArg holds an argument of an encoded gRPC error metadata */
export interface ErrorArg {
  /** @format int64 */
  int_arg?: string;
  bool_arg?: boolean;
  string_arg?: string;
  /** @format double */
  double_arg?: number;
  /** @format byte */
  bytes_arg?: string;
}

/**
 * ErrorResponse
 * Error message based on the [RFC 7807](https://tools.ietf.org/html/rfc7807) standard
 */
export interface ErrorResponse {
  /**
   * A URI reference
   * A URI reference [RFC3986](https://tools.ietf.org/html/rfc3986) that identifies the problem type.<br>This specification encourages that, when dereferenced, it provide human-readable documentation for the problem type (e.g., using HTML [W3C.REC-html5-20141028]).<br>When this member is not present, its value is assumed to be 'about:blank'.
   */
  type?: string;
  /**
   * summary in human readable form
   * A short, human-readable summary of the problem type. <br>It **SHOULD NOT** change from occurrence to occurrence of the problem, except for purposes of localization (e.g., using proactive content negotiation; see [RFC7231, Section 3.4](https://tools.ietf.org/html/rfc7231#section-3.4))
   */
  title?: string;
  /**
   * HTTP status code
   * The HTTP status code ([RFC7231, Section 6](https://tools.ietf.org/html/rfc7231#section-6)) generated by the origin server for this occurrence of the problem
   * @format int32
   */
  status?: number;
  /**
   * human readable explanation
   * A human-readable explanation specific to this occurrence of the problem
   */
  detail?: string;
  /**
   * RFC name instance, a reference URI
   * A URI reference that identifies the specific occurrence of the problem.<br>It may or may not yield further information if dereferenced
   */
  reference?: string;
  /**
   * Custom field containing the arguments passed to the error
   * The arguments of the error.<br>The arguments can be used to reconstruct the DynaStatus based on the code.
   */
  args?: ErrorArg[];
}

/**
 * ExportUserActionsRequest
 * Exports all **Actions**
 */
export interface ExportUserActionsReq {
  /** Optional list of **Actions** to export, if not all **Actions** should be exported. */
  actions?: string[];
}

/**
 * ExportUserActionsResponse
 * Returns all the exported **UserActions**
 */
export interface ExportUserActionsRes {
  /** The **Actions** with all **ActionLabels** */
  actions: Action[];
}

/** Returns **DataElements** with their count in the Instances slected by the filter or overall if no filter has been set */
export interface FilterQueryReq {
  /** List of allowed **Actions** for the returned **Instances**.<br>If left empty the search scope will include all **Actions**. */
  actions?: string[];
  /** The filter for the **Instance** requested */
  filters: InstanceFilter[];
}

/**
 * FindActionLabelLinksRequest
 * Used to find links between **ActionLabels** accross **Actions**.
 */
export interface FindActionLabelLinksReq {
  /** The names of the **Actions**. */
  action_names: string[];
}

/**
 * FindActionLabelLinksResponse
 * The **ActionLabel** links of the request **Actions**.
 */
export interface FindActionLabelLinksRes {
  /** The linked **ActionLabels** of the **Actions**. */
  links: ActionLabelLink[];
}

/**
 * GetDynizerConfigurationResponse
 * Holds Dynizer system configuration
 */
export interface GetDynizerConfigurationRes {
  /** The Dynizer configuration parameters */
  config_key_values: ConfigKV[];
}

/**
 * GetInstanceCellMetaDataKeyResponse
 * Holds all the MetaData values for a sepcific **MetaDataKey** information for an **Instance**.
 */
export interface GetInstanceCellMetaDataKeyRes {
  /** The special UUID identifying an **Instance**. */
  instance_id: string;
  /** The unique name of the **ActionLabel**. */
  action_label: string;
  /**
   * The 1-based position of the value within the values for an **ActionLabel**
   * @format int64
   */
  value_position: number;
  /** All the MetaData records linked to this **Instance** for the requested **MetaDataKey**. */
  meta_data: MetaDataOut;
}

/**
 * GetInstanceMetaDataKeyResponse
 * Holds all the values for a **MetaDataKey** linked to an **Instance**.
 */
export interface GetInstanceMetaDataKeyRes {
  /** The special UUID identifying an **Instance**. */
  instance_id: string;
  /** All the MetaData records linked to this **Instance** for the requested **MetaDataKey**. */
  meta_data: MetaDataOut;
}

/** Returns **DataElements** with their count in the Instances slected by the filter or overall if no filter has been set */
export interface GetTopNDataElementsReq {
  /**
   * Defines the topN **DataElement** to be returned based on occurrence.
   * @format int64
   */
  top?: number;
  /** List of allowed **Actions** for the returned **DataElement**.<br>If left empty the search scope will include all **Actions**. */
  actions?: string[];
  /** List of allowed **DataTypes** for the returned **DataElement**.<br>If left empty all **DataTypes** will be returned. */
  data_types?: DataType[];
  /** List of allowed **ComponentTypes** for the returned **DataElement**.<br>If left empty all **ComponentTypes** will be returned. */
  component_types?: ComponentType[];
  /**
   * List of **Instance** - filters for the returned **DataElement**.<br>If left empty no additional filter will by applied.
   * To limit the ActionLabel to a specific **Action** or specific **Actions** use the actions property.
   */
  filters?: InstanceFilter[];
}

/** Returns **DataElements** which match a contains value */
export interface GetTopNDataElementsRes {
  /** List of allowed **Actions** for the returned **DataElement**.<br>If left empty the search socpe will include all **Actions**. */
  who?: TopNDataElement[];
  /** List of allowed **Actions** for the returned **DataElement**.<br>If left empty the search socpe will include all **Actions**. */
  what?: TopNDataElement[];
  /** List of allowed **Actions** for the returned **DataElement**.<br>If left empty the search socpe will include all **Actions**. */
  where?: TopNDataElement[];
  /** List of allowed **Actions** for the returned **DataElement**.<br>If left empty the search socpe will include all **Actions**. */
  when?: TopNDataElement[];
}

/**
 * ImportUserActionsRequest
 * This is used to import **Actions**.
 */
export interface ImportUserActionsReq {
  /** The **Action** which will be imported. */
  actions: Action[];
  /** if set to true -> NO ERROR is returned if a **Action** by the provided name already exists in the system.<br>Any ActionLabels provided are IGNORED when if_not_exists == true and the **Action** already existed. */
  if_not_exists?: boolean;
}

/**
 * InstanceElement
 * each Element represents one ActionLabel and multiple values.<br>The specs allow for DataType override and pars format definition.<br>Additionaly can you also add MetaData for each value.
 */
export interface InstanceElement {
  /** The unique name of the **ActionLabel**. */
  action_label: string;
  /** the value as a string, which will be parsed into the correct Dynizer DataType.<br>With the specs parameter a parse format string can be provided. */
  values: string[];
  /** allows to add special handling for a value, based on its position in the array (1-based) */
  specs?: ValueSpec[];
  /** We can have more entries in here than we have Element values, as it is allowed to attach more than one MetaDataKey per value.<br>Each MetaDataKey allows multiple values, up to the amount configured. */
  meta_data?: InstanceElementMetaData[];
}

/**
 * InstanceElementMetaData
 * MetaData information to be added per individual InstanceElement value.<br>Multiple MetaDataKeys with values can be added per InstanceElement value position
 */
export interface InstanceElementMetaData {
  /**
   * 1-based to identify for which value from InstanceElement this treatment is to be used, 0 ... indicates that all values get the same overwrite
   * @format int64
   */
  position: number;
  /** MetaData Key Value defintion */
  meta_data_key_values: MetaDataKeyValue;
}

/** Defines an **Instance** - filter */
export interface InstanceFilter {
  /** The **ActionLabel** */
  action_label_name?: string;
  /** The possible values for the **ActionLabel** */
  values?: string[];
  /** The **Component Type** */
  component_type?: ComponentType;
}

/**
 * InstanceIDResponse
 * This holds the Instance ID
 */
export interface InstanceIDRes {
  /** The UUID identifying an **Instance**. */
  instance_id: string;
}

/**
 * InstanceRes response which returns one Action Instance row
 * This holds an **Instance** row
 */
export interface InstanceRes {
  /** The special UUID identifying an **Instance**. */
  instance_id: string;
  /** All the **ActionsLabels** used in this the **Instance** row. */
  action_labels?: ResultLabel[];
  /** All the values for each **InstanceCell**. */
  instance_cell_values?: string[];
  /** This is propergated if any cell would have a dataype override from the default. */
  alternative_data_types?: ResultValueAlternativeMetaDataPosition[];
}

/**
 * InstanceUpdateElementCell
 * each Element represents one ActionLabel and multiple values.<br>The specs allow for DataType override and pars format definition.<br>Additionaly can you also add MetaData for each value.
 */
export interface InstanceUpdateElementCell {
  /** The unique name of the **ActionLabel**. */
  action_label: string;
  /** the value as a string, which will be parsed into the correct Dynizer DataType.<br>With the specs parameter a parse format string can be provided. */
  value: string;
  /**
   * The 1-based position of the value within the values for an **ActionLabel**
   * @format int64
   * @min 1
   */
  value_position: number;
  /** overwrites the default_data_type from the MetaDataKey definition and/or provides a format string to parse the values */
  override?: DataTypeFormat;
}

/**
 * LabelResponse
 * Response which holds an **Action** annd all its **ActionLabels**.
 */
export interface LabelRes {
  /** This holds an **Actions**. */
  action?: string;
  /** This holds an **ActionLabel**. */
  action_label: ActionLabel;
}

/**
 * ListInstanceCellMetaDataRes
 * Holds all the MetaData information for an **Instance**.
 */
export interface ListInstanceCellMetaDataRes {
  /** The special UUID identifying an **Instance**. */
  instance_id: string;
  /** The unique name of the **ActionLabel**. */
  action_label: string;
  /**
   * The 1-based position of the value within the values for an **ActionLabel**
   * @format int64
   */
  value_position: number;
  /**
   * Total number of MetaData entries for this **InstanceCell** returned
   * @format int64
   */
  total_meta_data_entries: number;
  /** All the MetaData records linked to this **Instance** for the requested **MetaDataKey**. */
  meta_data: MetaDataOut[];
}

/**
 * ListInstanceMetaDataResponse
 * Holds all the MetaData information for an **Instance**.
 */
export interface ListInstanceMetaDataRes {
  /** The special UUID identifying an **Instance**. */
  instance_id: string;
  /**
   * Total number of MetaData entries attached to this **Instance**
   * @format int64
   */
  total_meta_data_entries: number;
  /** All the MetaData records linked to this **Instance**. */
  meta_data: MetaDataOut[];
}

/**
 * ListRolesResponse
 * Roles selected by the window
 */
export interface ListRolesRes {
  /**
   * Total number of roles in the system
   * @format int64
   */
  total_roles: number;
  /** The roles for the window. */
  roles: DynizerRole[];
}

/**
 * ListUserRolesResponse
 * Response with all roles for a user
 */
export interface ListUserRolesRes {
  /**
   * Total number of roles in the system.
   * @format int64
   */
  total_roles: number;
  /** All the roles which have been assigned to the user. */
  roles: DynizerRole[];
}

/**
 * ListUsersResponse
 * Response which holds all users.
 */
export interface ListUsersRes {
  /**
   * Total number of users in the system
   * @format int64
   */
  total_users: number;
  /** The users known to the Dynizer */
  users: User[];
}

/**
 * LoginRequest
 * Login Request with user and password.
 */
export interface LoginReq {
  /** Unique name of the user */
  user: string;
  /** Private value only known to the user, hash will be checked with the db-hash */
  password: string;
}

/**
 * LoginResponse
 * JsonWebToken response for a successful login with including the users roles
 */
export interface LoginRes {
  /** The [JSON Web Token](https://jwt.io) defined by [RFC ](). */
  jwt: string;
  /** All the roles which have been granted. */
  roles: DynizerRole[];
}

/**
 * MetaDataKey
 * Message to hold a **MetaDataKey*
 */
export interface MetaDataKey {
  /** The unique name of a **MetaDataKey**. */
  key_name: string;
  /** The **DataType** for this **ActionLabel**.<br>This **DataType** will be used when creating **Instances** with this **ActionLabel**. */
  default_data_type: DataType;
  /**
   * How many values at most can be stored in the **MetaDataKey** value array for one use.<br>Not setting this property or setting it to 0, will mean to the maximum based on system settings.
   * @format int64
   */
  max_occurrence: number;
  /** List of additional allowed **DataTypes** for this **MetaDataKey**.<br>If left empty only the *default_data_type* is allowed for the values of this **MetaDataKey**. */
  alternative_data_types: DataType[];
}

/**
 * MetaDataKeyArrayResponse
 * Response that holds **MetaDataKeys**
 */
export interface MetaDataKeyArrayRes {
  /**
   * Total number of **MetadataKeys** in the system.
   * @format int64
   */
  total_meta_data_keys?: number;
  /** The **MetaDataKeys** within the requested window */
  meta_data_keys: MetaDataKey[];
}

/**
 * MetaDataKeyResponse
 * Response that holds a **MetaDataKey**
 */
export interface MetaDataKeyRes {
  /** A single **MetaDataKey** definition */
  meta_data_key: MetaDataKey;
}

/**
 * MetaDataKeyValue
 * MetaData Key Value for inserts
 */
export interface MetaDataKeyValue {
  /** is the MetaDataKey name */
  key_name: string;
  /** is the string value of the MetaDataValue */
  values: string[];
  /**
   * DataTypeFormat override = 3 [
   *     (grpc.gateway.protoc_gen_openapiv2.options.openapiv2_field) = {
   *         description: "overwrites the default_data_type from the MetaDataKey definition and/or provides a format string to parse the values"
   *     }
   * ];
   * allows to add special handling for a value, based on its position in the array (1-based)
   */
  specs?: ValueSpec[];
}

/**
 * MetaDataOut
 * MetaData out Object
 */
export interface MetaDataOut {
  /** A MetaData record. */
  meta_data_key_value?: MetaDataRecord;
}

/**
 * MetaDataRecord
 * MetaDataRecord
 */
export interface MetaDataRecord {
  /** is the MetaDataKey name */
  key_name?: string;
  /** is the string value of the MetaDataValue */
  values?: string[];
  /** Is the MetaData DataType for all the values. */
  data_type?: DataType;
}

/**
 * ObjectDataType
 * - ODT_Text: object is of type TEXT, which can be indexed by the FullTextIndex (if enabled)
 *  - ODT_Blob: object is of type binary
 *  - ODT_Clob: object is of type character
 * @default "InvalidObjectDataType"
 */
export enum ObjectDataType {
  InvalidObjectDataType = "InvalidObjectDataType",
  ODTText = "ODT_Text",
  ODTBlob = "ODT_Blob",
  ODTClob = "ODT_Clob",
}

/**
 * ObjectReference
 * Message to hold a **ObjectReference*
 */
export interface ObjectRef {
  /** The URI referencing the **Object**. */
  ref_uri?: string;
  /** The type of the **Object**. */
  type?: ObjectDataType;
}

/**
 * ObjectResponse
 * This holds the Object reference URI
 */
export interface ObjectRes {
  /** The **ObjectReference**. */
  object_ref: ObjectRef;
}

/** Response which holds an array of **Object** UUIDs. */
export interface ObjectUUIDArrayRes {
  /**
   * Total number of **Object** UUIDs in the system
   * @format int64
   */
  total_objects: number;
  /** This holds an array of **Object** UUIDs. */
  object_uuids?: string[];
}

/**
 * OrderField
 * - Created: order by created
 *  - Name: order by name
 * @default "Created"
 */
export enum OrderField {
  Created = "Created",
  Name = "Name",
}

/** Returns **PlotData** which match the filter */
export interface PlotRes {
  /** Name of the plot */
  plot_name?: string;
  /** JSON data of the Plot */
  plot_json?: string;
}

/** Performs the Bind of Parameter values */
export interface QueryBindReq {
  /** The name of the prepared Statement */
  statement?: string;
  /** The name of the portal, this is what the execute references */
  portal?: string;
  /** The DQL parameter. */
  parameters: DQLParam[];
}

/** Cancels the execution of a portal. */
export interface QueryCancelReq {
  /** The portal of which the query is executing. */
  portal?: string;
}

/**
 * Cleans up the session data for weither a portal or a statement.
 * If a statement close is issued all associated portals are closed too.
 */
export interface QueryCloseReq {
  /** [DEPRECATED] The unique identifier for the DQL Result to close. Legacy compatibility field, use close_for instead. */
  result_handle?: string;
  /** The name of the prepared Statement */
  statement?: string;
  /** The name of the portal, this is what the execute references */
  portal?: string;
}

/** Returns **DataElements** which match a contains value */
export interface QueryDataElementsReq {
  /** string to search for */
  contains: string;
  /** List of allowed **Actions** for the returned **DataElement**.<br>If left empty the search scope will include all **Actions**. */
  actions?: string[];
  /** List of allowed **DataTypes** for the returned **DataElement**.<br>If left empty all **DataTypes** will be returned. */
  data_types?: DataType[];
  /**
   * List of **Instance** - filters for the returned **DataElement**.<br>If left empty no additional filter will by applied.
   * To limit the ActionLabel to a specific **Action** or specific **Actions** use the actions property.
   */
  filters?: InstanceFilter[];
  /**
   * Defines the maximum length of the **DataElement** to be returned.
   * @format int64
   */
  max_length?: number;
  /**
   * int64 top = 6 [
   *        (grpc.gateway.protoc_gen_openapiv2.options.openapiv2_field) = {
   *            description: "Defines the topN **DataElement** to be returned based on occurrence."
   *        }
   *    ];
   * List of allowed **ComponentTypes** for the returned **DataElement**.<br>If left empty all **ComponentTypes** will be returned.
   */
  component_types?: ComponentType[];
  /** Flag to enable or disable the list split up by **ComponentType** for the resulting **DataElements** the default is off. */
  with_component_type_split?: boolean;
}

/** Returns **DataElements** which match a contains value */
export interface QueryDataElementsRes {
  /**
   * Number of found **DataElements**
   * @format int64
   */
  total_dataelements: number;
  /** List of allowed **Actions** for the returned **DataElement**.<br>If left empty the search socpe will include all **Actions**. */
  dataelements: DataElement[];
}

/**
 * Performs the Describe for wither a statement or a portal.
 * A statement description will return the definition for both the Parameters as well as the ActionLabels.
 * A portal description will just return the ActionLabels.
 */
export interface QueryDescribePortalReq {
  /** The name of the portal, this is what the execute references */
  portal: string;
}

/** Returns the description of the ActionLabels and DQL-Parameter */
export interface QueryDescribePortalRes {
  /** The **ActionLabels** of the result */
  result_labels: ResultLabelWithOverride[];
  /** The DQL parameter. */
  parameters?: DQLParam[];
  /** The type of the executed query */
  type?: DQLType;
}

/**
 * Performs the Describe for wither a statement or a portal.
 * A statement description will return the definition for both the Parameters as well as the ActionLabels.
 * A portal description will just return the ActionLabels.
 */
export interface QueryDescribeStatementReq {
  /** The name of the prepared Statement */
  statement: string;
}

/** Returns the description of the ActionLabels */
export interface QueryDescribeStatementRes {
  /** The **ActionLabels** of the statement */
  result_labels: ResultLabelWithOverride[];
  /** The Parameter descriptions of the statement */
  parameter_descriptions?: DQLParamDescription[];
  /** The type of the executed query */
  type?: DQLType;
}

/** Executes a prepared and bound query */
export interface QueryExecuteReq {
  /** The name of the portal, this is what the execute references */
  portal: string;
  /** The window of rows within the result to be returned ... limit 0 means all rows */
  window?: Window;
}

/**
 * QueryExecuteResponse
 * an array of ResultRow messages for the list request
 */
export interface QueryExecuteRes {
  /** **Instance** rows */
  rows: ResultRow[];
  /** This is propegated if any cell would have a dataype override from the default. */
  alternative_data_types?: ResultValueAlternativeMetaDataPosition[];
  /** Set to true when all rows have been fetched */
  completed?: boolean;
  /** The type of the executed query */
  type?: DQLType;
  /**
   * The total count of the executed query
   * @format int64
   */
  count?: string;
}

/** DynizerQuery Statement to be parsed */
export interface QueryParseReq {
  /** The name for the Statement for later reference */
  statement?: string;
  /** The DQL string */
  dql: string;
  /** The Parameter descriptions of the statement */
  parameter_descriptions?: DQLParamDescription[];
}

/**
 * QueryResultResponse
 * an array of ResultRow messages for the list request
 */
export interface QueryResultRes {
  /** **Instance** rows */
  rows: ResultRow[];
  /** This is propegated if any cell would have a dataype override from the default. */
  alternative_data_types?: ResultValueAlternativeMetaDataPosition[];
}

/**
 * ReadActionInstancesResponse
 * Response the **Instances** for an **Action**
 */
export interface ReadActionInstancesRes {
  /** All the **ActionLabels** of the **Instance** rows. */
  result_labels: ResultLabel[];
  /**
   * Total number of **Instancs** for this **Action**.
   * @format int64
   */
  total_instances: number;
  /** The actual **Action**-**Instance** rows with data. */
  rows: ActionInstanceRecord[];
  /** This is propergated if any cell would have a dataype override from the default. */
  alternative_data_types: ResultValueAlternativeMetaDataPosition[];
}

/**
 * ResultLabel
 * Message to hold a result label
 */
export interface ResultLabel {
  /** This is the name of the **Action** for which the **Instance** will be created */
  action_name?: string;
  /** The unique name of the **ActionLabel**. */
  result_label_name?: string;
  /**
   * The relative index within the **ActionLabel**.
   * @format int64
   */
  result_label_index?: number;
  /** The **ComponentType** of the **ActionLabel** */
  component_type?: ComponentType;
  /** The default **DataType** of the **ActionLabel**.<br>If an **InstanceCell* has that overwritten, than this will be provided with the specific cell data row */
  default_data_type?: DataType;
}

/**
 * ResultLabelWithOverride
 * Message to hold a result label
 */
export interface ResultLabelWithOverride {
  /** This is the name of the **Action** for which the **Instance** will be created */
  action_name?: string;
  /** The unique name of the **ActionLabel**. */
  result_label_name?: string;
  /**
   * The relative index within the **ActionLabel**.
   * @format int64
   */
  result_label_index?: number;
  /** The **ComponentType** of the **ActionLabel** */
  component_type?: ComponentType;
  /** The default **DataType** of the **ActionLabel**.<br>If an **InstanceCell* has that overwritten, than this will be provided with the specific cell data row */
  default_data_type?: DataType;
  /** The override **DataType** of the **ActionLabel**.<br>If **InstanceCells* have more than one datatype, the override datatype will specify what to use as general datatype */
  override_data_type?: DataType;
}

/**
 * ResultRow
 * Message to hold a result row
 */
export interface ResultRow {
  /** the string representation of the values. */
  instance_cell_values?: string[];
}

/**
 * ResultValueAlternativeMetaDataPosition
 * Message to hold a the alternative DataType for a Cell in the result
 */
export interface ResultValueAlternativeMetaDataPosition {
  /**
   * The row number relative in the result window.<br> The value is 1-based.
   * @format int64
   */
  row?: number;
  /**
   * The absolut cell number in the row.<br> The value is 1-based.
   * @format int64
   */
  col?: number;
  /** This **DataType** different from the default **DataType** was used when creating the entry. */
  data_type?: DataType;
}

/**
 * SelectDQLResult
 * Message to hold a DQL SELECT result cursor handle with count and metadata
 */
export interface SelectDQLResult {
  /**
   * Number of result records.
   * @format int64
   */
  result_count?: number;
  /** The Hhandle of the result.<br>Used to walk the result with a window or a stream. */
  result_handle?: string;
  /** The **ActionLabels** of the result */
  result_labels?: ResultLabel[];
}

/**
 * ServiceLoginReq
 * Service Login holds a service token for the server to check and inpersonate.
 */
export interface ServiceLoginReq {
  /** Is the service token which will be checked. */
  token: string;
}

/**
 * ServiceTokenResponse
 * ServieToken response for a successful issue
 */
export interface ServiceTokenRes {
  /** The service token. */
  token: string;
}

/**
 * SessionID
 * Holds Dynizer session ID
 */
export interface SessionID {
  /** The Dynizer session ID */
  session_id: string;
}

/** The answers to the simple query, one for each query in the full string */
export interface SimpleQueryRes {
  /** The list of results to the simple query */
  result: SimpleQueryResItem[];
}

/** The answer to a part of the simple query, either the rows affected for INSERT, UPDATE, DELETE, DROP, ... or the LabelDescription and rows for SELECT */
export interface SimpleQueryResItem {
  /** Holds a SELECT result */
  select_result?: SimpleSelectResult;
  /**
   * Number of rows affected by the DQL statement.
   * @format int64
   */
  rows_affected?: number;
  /** The type of the executed query */
  type?: DQLType;
}

/** LabelDescription and rows for SELECT */
export interface SimpleSelectResult {
  /**
   * Number of result records.
   * @format int64
   */
  result_count: number;
  /** The **ActionLabels** of the result */
  result_labels: ResultLabelWithOverride[];
  /** **Instance** rows */
  rows: ResultRow[];
  /** This is propagated if any cell would have a datatype override from the default. */
  alternative_data_types?: ResultValueAlternativeMetaDataPosition[];
}

/**
 * SortOrder
 * - Asc: order ascending
 *  - Desc: order descending
 * @default "Asc"
 */
export enum SortOrder {
  Asc = "Asc",
  Desc = "Desc",
}

export interface StreamInstanceIDRes {
  user_reference?: string;
  instance_id?: string;
  /** Error message based on the [RFC 7807](https://tools.ietf.org/html/rfc7807) standard */
  error?: ErrorResponse;
}

/**
 * StreamObjectDataOutResponse
 * Stream data for an **ObjectReference**
 */
export interface StreamObjectDataOutRes {
  /**
   * The **Object** data as bytes.
   * @format byte
   */
  data: string;
}

/** Returns **DataElements** which match a contains value */
export interface TopNDataElement {
  /** The string Value of the returned **DataElement**. */
  value: string;
  /** The **DataTypes** of the returned **DataElement**. */
  data_type: DataType;
  /**
   * Indicates in how many different **Instances** the **DataElement** was found.
   * @format int64
   */
  num_instances: number;
}

/**
 * User
 * Defines user in the Dynizer
 */
export interface User {
  /** Unique name of user. */
  user_name: string;
  /** All roles assigned to the user. */
  roles: DynizerRole[];
  /** Flag if the user can log on to the Dynizer. */
  enabled: boolean;
}

/**
 * User Count Response
 * Holds the count of all types of users and the total count.
 */
export interface UserCountRes {
  /**
   * Number of user which are active and can login.
   * @format int64
   */
  active_users: number;
  /**
   * Number of user which are not active and are login inhibited.
   * @format int64
   */
  disabled_users: number;
  /**
   * Number of *ALL* users independent of the state.
   * @format int64
   */
  total_users: number;
}

/**
 * UserResponse
 * Response which holds a user.
 */
export interface UserRes {
  /** The full user information */
  user: User;
}

/**
 * ValueSpec
 * message which defines a ValueSpec this is for how to treat the string input in a special way, also the option to not use the default DataType
 */
export interface ValueSpec {
  /**
   * 1-based to identify for which value from the referenced array this treatment is to be used. <br>0 ... indicates that all values get the same overwrite
   * @format int64
   */
  position: number;
  /** overwrites the default_data_type from the MetaDataKey definition and/or provides a format string to parse the values */
  override: DataTypeFormat;
}

/**
 * GetVersionResponse
 * Returns the version of the **Dynizer**
 */
export interface VersionRes {
  /** The version of the **Dynizer** */
  version: string;
  /** The build of the **Dynizer** */
  build: string;
  /** The mode of the **Dynizer** */
  mode: string;
  /** The date of the **Dynizer** build */
  build_date: string;
  /** The git-commit id of the **Dynizer** build */
  commit: string;
}

/**
 * Window
 * Keeping limit and offset together
 */
export interface Window {
  /**
   * Defines how many records at max are returned.<br>If fewer records are available, all will be returned.<br>Setting this to -1 will be interpreted as no limit
   * @format int64
   * @min -1
   */
  limit: number;
  /**
   * Defines the offset at which to start reading.<br>An offset of 0 will bean to start from the first possible record.
   * @format int64
   */
  offset: number;
  /** The Dynizer keeps track of the last read position, this flag indicates wether the offset is relative to the current read position or absolute. */
  relative?: boolean;
}

export interface ProtobufAny {
  "@type"?: string;
  [key: string]: any;
}

export interface RpcStatus {
  /** @format int32 */
  code?: number;
  message?: string;
  details?: ProtobufAny[];
}

import type { AxiosInstance, AxiosRequestConfig, AxiosResponse, HeadersDefaults, ResponseType } from "axios";
import axios from "axios";

export type QueryParamsType = Record<string | number, any>;

export interface FullRequestParams extends Omit<AxiosRequestConfig, "data" | "params" | "url" | "responseType"> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean;
  /** request path */
  path: string;
  /** content type of request body */
  type?: ContentType;
  /** query params */
  query?: QueryParamsType;
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseType;
  /** request body */
  body?: unknown;
}

export type RequestParams = Omit<FullRequestParams, "body" | "method" | "query" | "path">;

export interface ApiConfig<SecurityDataType = unknown> extends Omit<AxiosRequestConfig, "data" | "cancelToken"> {
  securityWorker?: (
    securityData: SecurityDataType | null,
  ) => Promise<AxiosRequestConfig | void> | AxiosRequestConfig | void;
  secure?: boolean;
  format?: ResponseType;
}

export enum ContentType {
  Json = "application/json",
  FormData = "multipart/form-data",
  UrlEncoded = "application/x-www-form-urlencoded",
  Text = "text/plain",
}

export class HttpClient<SecurityDataType = unknown> {
  public instance: AxiosInstance;
  private securityData: SecurityDataType | null = null;
  private securityWorker?: ApiConfig<SecurityDataType>["securityWorker"];
  private secure?: boolean;
  private format?: ResponseType;

  constructor({ securityWorker, secure, format, ...axiosConfig }: ApiConfig<SecurityDataType> = {}) {
    this.instance = axios.create({ ...axiosConfig, baseURL: axiosConfig.baseURL || "https://api.dynizer.com" });
    this.secure = secure;
    this.format = format;
    this.securityWorker = securityWorker;
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data;
  };

  protected mergeRequestParams(params1: AxiosRequestConfig, params2?: AxiosRequestConfig): AxiosRequestConfig {
    const method = params1.method || (params2 && params2.method);

    return {
      ...this.instance.defaults,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...((method && this.instance.defaults.headers[method.toLowerCase() as keyof HeadersDefaults]) || {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {}),
      },
    };
  }

  protected stringifyFormItem(formItem: unknown) {
    if (typeof formItem === "object" && formItem !== null) {
      return JSON.stringify(formItem);
    } else {
      return `${formItem}`;
    }
  }

  protected createFormData(input: Record<string, unknown>): FormData {
    return Object.keys(input || {}).reduce((formData, key) => {
      const property = input[key];
      const propertyContent: any[] = property instanceof Array ? property : [property];

      for (const formItem of propertyContent) {
        const isFileType = formItem instanceof Blob || formItem instanceof File;
        formData.append(key, isFileType ? formItem : this.stringifyFormItem(formItem));
      }

      return formData;
    }, new FormData());
  }

  public request = async <T = any, _E = any>({
    secure,
    path,
    type,
    query,
    format,
    body,
    ...params
  }: FullRequestParams): Promise<AxiosResponse<T>> => {
    const secureParams =
      ((typeof secure === "boolean" ? secure : this.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {};
    const requestParams = this.mergeRequestParams(params, secureParams);
    const responseFormat = format || this.format || undefined;

    if (type === ContentType.FormData && body && body !== null && typeof body === "object") {
      body = this.createFormData(body as Record<string, unknown>);
    }

    if (type === ContentType.Text && body && body !== null && typeof body !== "string") {
      body = JSON.stringify(body);
    }

    return this.instance.request({
      ...requestParams,
      headers: {
        ...(requestParams.headers || {}),
        ...(type && type !== ContentType.FormData ? { "Content-Type": type } : {}),
      },
      params: query,
      responseType: responseFormat,
      data: body,
      url: path,
    });
  };
}

/**
 * @title Dynizer API
 * @version 2.0
 * @baseUrl https://api.dynizer.com
 * @contact Dynactionize NV <api-requests@dynactionize.com> (https://dynactionize.com)
 *
 * This is the [Dynizer API](https://api.dynizer.com)
 */
export class Api<SecurityDataType extends unknown> extends HttpClient<SecurityDataType> {
  api = {
    /**
     * @description Creates a **Action** with the provided name.<br>The name needs to be unqiue, the CheckActionName call can be used to verify.<br>The name can not start or end with a whitespace character.<br>The name can not contain any control characters at any position.
     *
     * @tags Actions
     * @name CreateAction
     * @summary Create Action
     * @request POST:/api/v2/actions
     * @secure
     */
    createAction: (body: CreateActionReq, params: RequestParams = {}) =>
      this.request<EmptyRes, ErrorResponse | RpcStatus>({
        path: `/api/v2/actions`,
        method: "POST",
        body: body,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Define a Link between **Actions** via a **ActionLabel**
     *
     * @tags Actions
     * @name CreateActionLabelLink
     * @summary Create a new Link via Action Label
     * @request POST:/api/v2/actions/link
     * @secure
     */
    createActionLabelLink: (body: ActionLabelLinkReq, params: RequestParams = {}) =>
      this.request<EmptyRes, ErrorResponse | RpcStatus>({
        path: `/api/v2/actions/link`,
        method: "POST",
        body: body,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Reads a Link between **Actions** via a **ActionLabel**
     *
     * @tags Actions
     * @name GetActionLabelLink
     * @summary Read a Link via Action Label
     * @request GET:/api/v2/actions/link/{action_label_link_name}
     * @secure
     */
    getActionLabelLink: (actionLabelLinkName: string, params: RequestParams = {}) =>
      this.request<ActionLabelLinkRes, ErrorResponse | RpcStatus>({
        path: `/api/v2/actions/link/${actionLabelLinkName}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Returns the total number of **Actions**
     *
     * @tags Actions
     * @name CountActions
     * @summary Count Actions
     * @request GET:/api/v2/actions/types/{action_type_label}/count
     * @secure
     */
    countActions: (actionTypeLabel: string, params: RequestParams = {}) =>
      this.request<CountRes, ErrorResponse | RpcStatus>({
        path: `/api/v2/actions/types/${actionTypeLabel}/count`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Returns a list of **Action** names.<br>Providing the optional window, paging is supported.
     *
     * @tags Actions
     * @name ListActionNames
     * @summary List Action Names
     * @request GET:/api/v2/actions/types/{action_type_label}/names/all
     * @secure
     */
    listActionNames: (
      actionTypeLabel: string,
      query: {
        /**
         * Defines how many records at max are returned.<br>If fewer records are available, all will be returned.<br>Setting this to -1 will be interpreted as no limit
         * @format int64
         */
        windowLimit: number;
        /**
         * Defines the offset at which to start reading.<br>An offset of 0 will bean to start from the first possible record.
         * @format int64
         */
        windowOffset: number;
        /** The Dynizer keeps track of the last read position, this flag indicates wether the offset is relative to the current read position or absolute. */
        windowRelative?: boolean;
        /**
         * By which field to order **Action** names.
         *
         *  - Created: order by created
         *  - Name: order by name
         * @default "Created"
         */
        order_field?: "Created" | "Name";
        /**
         * Order in which to load the **Action** names.
         *
         *  - Asc: order ascending
         *  - Desc: order descending
         * @default "Asc"
         */
        order_direction?: "Asc" | "Desc";
      },
      params: RequestParams = {},
    ) =>
      this.request<ActionNameArrayRes, ErrorResponse | RpcStatus>({
        path: `/api/v2/actions/types/${actionTypeLabel}/names/all`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Reads an **Action** definition with all **ActionLabels**
     *
     * @tags Actions
     * @name ReadAction
     * @summary Read Action
     * @request GET:/api/v2/actions/{action_name}
     * @secure
     */
    readAction: (actionName: string, params: RequestParams = {}) =>
      this.request<ActionRes, ErrorResponse | RpcStatus>({
        path: `/api/v2/actions/${actionName}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description A **Action** can only be deleted if no **Instances** are attached.<br>If the **Action** has **Instances** attached and *cascade* is not set to *true* and Error will be returned.<br>The *cascade* flag can be used to mark the **Action** and all its **Instances** as deleted.
     *
     * @tags Actions
     * @name DeleteAction
     * @summary Delete Action
     * @request DELETE:/api/v2/actions/{action_name}
     * @secure
     */
    deleteAction: (
      actionName: string,
      query?: {
        /** This flag allows to mark all associated **Instances** as deleted.<br>If cascade == <span style="color:blue">false</span> an error is raised if this **Action** has **Instances**.<br>**Action** with no **Instances** can be deleted no matter what value cascade has. */
        cascade?: boolean;
      },
      params: RequestParams = {},
    ) =>
      this.request<EmptyRes, ErrorResponse | RpcStatus>({
        path: `/api/v2/actions/${actionName}`,
        method: "DELETE",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Updates a **Action**, at the moment this is only the name.<br>The new_name needs to be unqiue.<br>The *new_name* can not start or end with a whitespace character.<br>The *new_name* can not contain any control characters at any position
     *
     * @tags Actions
     * @name UpdateAction
     * @summary Update Action
     * @request PATCH:/api/v2/actions/{action_name}
     * @secure
     */
    updateAction: (actionName: string, new_action_name: ActionName, params: RequestParams = {}) =>
      this.request<EmptyRes, ErrorResponse | RpcStatus>({
        path: `/api/v2/actions/${actionName}`,
        method: "PATCH",
        body: new_action_name,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Adds an **ActionLabel** to an existing **Action**.<br>Use the CheckActionLabelName call to verify that the ActionLabel name can be added.
     *
     * @tags Actions
     * @name AddActionLabel
     * @summary Add ActionLabel
     * @request POST:/api/v2/actions/{action_name}/actionlabels
     * @secure
     */
    addActionLabel: (
      actionName: string,
      body: {
        /** if set to true -> NO ERROR is returned if a **Action** already has an **ActionLabel** by the provided name.<br>No changes to the ActionLabel will be performed when if_not_exists == true and the **ActionLabel** already exists. */
        if_not_exists?: boolean;
        /** The unique name of the **ActionLabel** within the **Action.<br>Use the CheckActionLabelName, call to verify the name, without creating it. */
        action_label_name: string;
        /** The **ComponentType** for this **ActionLabel**. */
        component: ComponentType;
        /** The **DataType** for this **ActionLabel**.<br>This **DataType** will be used when creating **Instances** with this **ActionLabel**. */
        default_data_type: DataType;
        /**
         * Allows to set minimum number of values, which need to be provided when using this **ActionLabell*** in an **Instance**.
         * @format int64
         */
        min_occurrence?: number;
        /**
         * Allows to set maximum number of values, which need to be provided when using this **ActionLabell*** in an **Instance**.<br>This value needs to be either zero or it can be equal or greater then *min_occurrence*.<br>Setting it to 0 will be interpreted as open-ended.
         * @format int64
         */
        max_occurrence?: number;
        /** List of additional allowed **DataTypes** for this **ActionLabel**.<br>If left empty only the *default_data_type* is allowed for the values of this **ActionLabel**. */
        alternative_data_types?: DataType[];
        /** The **ShareName** to use by default when an new instance is created and a value is set for this **ActionLabel** */
        share_name?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<EmptyRes, ErrorResponse | RpcStatus>({
        path: `/api/v2/actions/${actionName}/actionlabels`,
        method: "POST",
        body: body,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Reads an **ActionLabel** definition
     *
     * @tags Actions
     * @name ReadActionLabel
     * @summary Read ActionLabel
     * @request GET:/api/v2/actions/{action_name}/actionlabels/{action_label}
     * @secure
     */
    readActionLabel: (actionName: string, actionLabel: string, params: RequestParams = {}) =>
      this.request<LabelRes, ErrorResponse | RpcStatus>({
        path: `/api/v2/actions/${actionName}/actionlabels/${actionLabel}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Delete a **UserLabel**, will prevent any new **Instances** with this **ActionLabel** can be created.<br>Allready created **Instances** will not be affected.
     *
     * @tags Actions
     * @name DeleteActionLabel
     * @summary Delete ActionLabel
     * @request DELETE:/api/v2/actions/{action_name}/actionlabels/{action_label}
     * @secure
     */
    deleteActionLabel: (actionName: string, actionLabel: string, params: RequestParams = {}) =>
      this.request<EmptyRes, ErrorResponse | RpcStatus>({
        path: `/api/v2/actions/${actionName}/actionlabels/${actionLabel}`,
        method: "DELETE",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Updates a **ActionLabel** for an **Action**.<br>If the *cascade* flag is set to true, all **Instances** for the **Action** which would violate the **ActionLabel** defition, will get makred as deleted.
     *
     * @tags Actions
     * @name UpdateActionLabel
     * @summary Update ActionLabel
     * @request PUT:/api/v2/actions/{action_name}/actionlabels/{action_label}
     * @secure
     */
    updateActionLabel: (
      actionName: string,
      actionLabel: string,
      body: {
        /** The new unique name of the **ActionLabel** within the **Action.<br>Use the CheckActionLabelName, call to verify the name, without creating it. */
        new_action_label_name?: string;
        /** The **DataType** for this **ActionLabel**.<br>This **DataType** will be used when creating **Instances** with this **ActionLabel**. */
        default_data_type: DataType;
        /**
         * Allows to set minimum number of values, which need to be provided when using this **ActionLabell*** in an **Instance**.
         * @format int64
         */
        min_occurrence: number;
        /**
         * Allows to set maximum number of values, which need to be provided when using this **ActionLabell*** in an **Instance**.<br>This value needs to be either zero or it can be equal or greater then *min_occurrence*.<br>Setting it to 0 will be interpreted as open-ended.
         * @format int64
         */
        max_occurrence: number;
        /** List of additional allowed **DataTypes** for this **ActionLabel**.<br>If left empty only the *default_data_type* is allowed for the values of this **ActionLabel**. */
        alternative_data_types: DataType[];
        /** Flag to define the behaviour if the update will cause a violation of existing **Instances**.<br>If set to <span style="color:blue">true</span> the **Instances** in violation will be marked as deleted. */
        cascade?: boolean;
      },
      params: RequestParams = {},
    ) =>
      this.request<EmptyRes, ErrorResponse | RpcStatus>({
        path: `/api/v2/actions/${actionName}/actionlabels/${actionLabel}`,
        method: "PUT",
        body: body,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Reads all **Instances** for an **Action**.<br>With the optional window pageing is supported.
     *
     * @tags Actions
     * @name ReadActionInstances
     * @summary Read Action Instances
     * @request GET:/api/v2/actions/{action_name}/instances
     * @secure
     */
    readActionInstances: (
      actionName: string,
      query: {
        /**
         * Defines how many records at max are returned.<br>If fewer records are available, all will be returned.<br>Setting this to -1 will be interpreted as no limit
         * @format int64
         */
        windowLimit: number;
        /**
         * Defines the offset at which to start reading.<br>An offset of 0 will bean to start from the first possible record.
         * @format int64
         */
        windowOffset: number;
        /** The Dynizer keeps track of the last read position, this flag indicates wether the offset is relative to the current read position or absolute. */
        windowRelative?: boolean;
      },
      params: RequestParams = {},
    ) =>
      this.request<ReadActionInstancesRes, ErrorResponse | RpcStatus>({
        path: `/api/v2/actions/${actionName}/instances`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Creates a new **ActionType**
     *
     * @tags ActionTypes
     * @name CreateActionType
     * @summary Create ActionType
     * @request POST:/api/v2/actiontypes
     * @secure
     */
    createActionType: (body: CreateActionTypeReq, params: RequestParams = {}) =>
      this.request<EmptyRes, ErrorResponse | RpcStatus>({
        path: `/api/v2/actiontypes`,
        method: "POST",
        body: body,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Reads an **ActionType** definition with all **ActionLabels**
     *
     * @tags ActionTypes
     * @name ReadActionType
     * @summary Read ActionType
     * @request GET:/api/v2/actiontypes/{action_type_label}
     * @secure
     */
    readActionType: (actionTypeLabel: string, params: RequestParams = {}) =>
      this.request<ActionTypeRes, ErrorResponse | RpcStatus>({
        path: `/api/v2/actiontypes/${actionTypeLabel}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Updates a **ActionType**, at the moment this is only the name.<br>The new_name needs to be unqiue.<br>The *new_name* can not start or end with a whitespace character.<br>The *new_name* can not contain any control characters at any position
     *
     * @tags ActionTypes
     * @name UpdateActionType
     * @summary Update ActionType
     * @request PATCH:/api/v2/actiontypes/{action_type_label}
     * @secure
     */
    updateActionType: (actionTypeLabel: string, new_action_type_label: ActionTypeLabel, params: RequestParams = {}) =>
      this.request<EmptyRes, ErrorResponse | RpcStatus>({
        path: `/api/v2/actiontypes/${actionTypeLabel}`,
        method: "PATCH",
        body: new_action_type_label,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Verifies if the **Action** name is unique and only uses supported characters.
     *
     * @tags Actions
     * @name CheckActionName
     * @summary Check Action Name
     * @request GET:/api/v2/check/actions/{action_name}
     * @secure
     */
    checkActionName: (actionName: string, params: RequestParams = {}) =>
      this.request<EmptyRes, ErrorResponse | RpcStatus>({
        path: `/api/v2/check/actions/${actionName}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Checks a string if it can be used as an **ActionLabel** name.
     *
     * @tags Actions
     * @name CheckActionLabelName
     * @summary Check ActionLabel Name
     * @request GET:/api/v2/check/actions/{action_name}/actionlabels/{action_label}
     * @secure
     */
    checkActionLabelName: (actionName: string, actionLabel: string, params: RequestParams = {}) =>
      this.request<EmptyRes, ErrorResponse | RpcStatus>({
        path: `/api/v2/check/actions/${actionName}/actionlabels/${actionLabel}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Checks a string if it can be used as a **MetaDataKey** name.
     *
     * @tags MetaData, MetaDataKey
     * @name CheckMetaDataKeyName
     * @summary Check MetaDataKey Name
     * @request GET:/api/v2/check/metadata/keys/{key_name}
     * @secure
     */
    checkMetaDataKeyName: (keyName: string, params: RequestParams = {}) =>
      this.request<EmptyRes, ErrorResponse | RpcStatus>({
        path: `/api/v2/check/metadata/keys/${keyName}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Checks if the password can be used, and returns a score of it's strength.
     *
     * @tags Users
     * @name CheckPassword
     * @summary Check Password
     * @request POST:/api/v2/check/password
     * @secure
     */
    checkPassword: (body: CheckPasswordReq, params: RequestParams = {}) =>
      this.request<CheckPasswordRes, ErrorResponse | RpcStatus>({
        path: `/api/v2/check/password`,
        method: "POST",
        body: body,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Checks if the user name can be used.
     *
     * @tags Users
     * @name CheckUserName
     * @summary Check User Name
     * @request GET:/api/v2/check/user/{user}
     * @secure
     */
    checkUserName: (user: string, params: RequestParams = {}) =>
      this.request<EmptyRes, ErrorResponse | RpcStatus>({
        path: `/api/v2/check/user/${user}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Returns all **Dynizer** configuration values as key::value pairs
     *
     * @tags Config
     * @name GetDynizerConfiguration
     * @request GET:/api/v2/config
     * @secure
     */
    getDynizerConfiguration: (params: RequestParams = {}) =>
      this.request<GetDynizerConfigurationRes, ErrorResponse | RpcStatus>({
        path: `/api/v2/config`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description This can be used to query **DataElements** by with contains and **DataType**.<br>Optional filter possibilities.
     *
     * @tags DataElements
     * @name QueryDataElements
     * @summary Query DataElements Contains
     * @request POST:/api/v2/dataelements/query/contains
     * @secure
     */
    queryDataElements: (body: QueryDataElementsReq, params: RequestParams = {}) =>
      this.request<QueryDataElementsRes, ErrorResponse | RpcStatus>({
        path: `/api/v2/dataelements/query/contains`,
        method: "POST",
        body: body,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description This can be used to fetch the top N **DataElements** used in **Instances**<br>Optional filter possibilities.
     *
     * @tags DataElements
     * @name GetTopNDataElements
     * @summary Query DataElements TopN
     * @request POST:/api/v2/dataelements/top
     * @secure
     */
    getTopNDataElements: (body: GetTopNDataElementsReq, params: RequestParams = {}) =>
      this.request<GetTopNDataElementsRes, ErrorResponse | RpcStatus>({
        path: `/api/v2/dataelements/top`,
        method: "POST",
        body: body,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Finds the **ActionLabels** with shared datalements within a set of **Actions**
     *
     * @tags Actions
     * @name FindActionLabelLinks
     * @summary finds the actionlabels with shared datalements within a set of actions
     * @request POST:/api/v2/find_actionlabel_links
     * @secure
     */
    findActionLabelLinks: (body: FindActionLabelLinksReq, params: RequestParams = {}) =>
      this.request<FindActionLabelLinksRes, ErrorResponse | RpcStatus>({
        path: `/api/v2/find_actionlabel_links`,
        method: "POST",
        body: body,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description This can be used to create a new **Instance** with values.<br>Each used **ActionLabel** can support as many values as defined.<br>For each value the **DataType** can be overwritten from the **ActionLabel** default.<br>In addition is it also possible to add MetaData on all levels.
     *
     * @tags Instances
     * @name CreateInstance
     * @summary Create Instance
     * @request POST:/api/v2/instances
     * @secure
     */
    createInstance: (body: CreateInstanceReq, params: RequestParams = {}) =>
      this.request<InstanceIDRes, ErrorResponse | RpcStatus>({
        path: `/api/v2/instances`,
        method: "POST",
        body: body,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Reads an **Instance** directly.
     *
     * @tags Instances
     * @name ReadInstance
     * @summary Read Instance
     * @request GET:/api/v2/instances/{instance_id}
     * @secure
     */
    readInstance: (instanceId: string, params: RequestParams = {}) =>
      this.request<InstanceRes, ErrorResponse | RpcStatus>({
        path: `/api/v2/instances/${instanceId}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Delete an existing **Instance** will just mark it as deleted, but not remove it from the persistance layer.
     *
     * @tags Instances
     * @name DeleteInstance
     * @summary Delete Instance
     * @request DELETE:/api/v2/instances/{instance_id}
     * @secure
     */
    deleteInstance: (instanceId: string, params: RequestParams = {}) =>
      this.request<EmptyRes, ErrorResponse | RpcStatus>({
        path: `/api/v2/instances/${instanceId}`,
        method: "DELETE",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description This can be used to update an existing **Instance** with values.<br>Only the changed values need to be provided.<br>A special array with the **ActionLabels** to be removed can be provided.<br>Each used **ActionLabel** can support as many values as defined.<br>For each value the **DataType** can be overwritten from the **ActionLabel** default.<br>In addition is it also possible to add MetaData on all levels.
     *
     * @tags Instances
     * @name UpdateInstance
     * @summary Update Instance
     * @request PATCH:/api/v2/instances/{instance_id}
     * @secure
     */
    updateInstance: (
      instanceId: string,
      body: {
        /** Qualify a cell which is to be updated.<br>Using the virtual **DataType** DT_Delete */
        instance_cell_elements?: InstanceUpdateElementCell[];
      },
      params: RequestParams = {},
    ) =>
      this.request<EmptyRes, ErrorResponse | RpcStatus>({
        path: `/api/v2/instances/${instanceId}`,
        method: "PATCH",
        body: body,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Validates the security token
     *
     * @tags Auth
     * @name ValidateToken
     * @summary Validate Token
     * @request GET:/api/v2/jwt/{token}/validate
     */
    validateToken: (token: string, params: RequestParams = {}) =>
      this.request<SessionID, ErrorResponse | RpcStatus>({
        path: `/api/v2/jwt/${token}/validate`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * @description To Login to the Dynizer using a password.<br>If the user has access, a [Json Web Token](https://jwt.io/) [(RFC 7519)](https://tools.ietf.org/html/rfc7519) will be rturned.
     *
     * @tags Auth
     * @name Login
     * @summary Login
     * @request POST:/api/v2/login
     */
    login: (body: LoginReq, params: RequestParams = {}) =>
      this.request<LoginRes, ErrorResponse | RpcStatus>({
        path: `/api/v2/login`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Logout will clean server side resources.<br>This invalidates the security token as well.
     *
     * @tags Auth
     * @name Logout
     * @summary Logout
     * @request GET:/api/v2/logout
     * @secure
     */
    logout: (params: RequestParams = {}) =>
      this.request<EmptyRes, ErrorResponse | RpcStatus>({
        path: `/api/v2/logout`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Starts the rebuild of an index
     *
     * @tags Maintenance
     * @name ReIndex
     * @summary Dynizer Index rebuild
     * @request GET:/api/v2/maintenance/index/{index_name}
     * @secure
     */
    reIndex: (indexName: string, params: RequestParams = {}) =>
      this.request<EmptyRes, ErrorResponse | RpcStatus>({
        path: `/api/v2/maintenance/index/${indexName}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Adds a **MetaDataKey** and its values to an **Instance**
     *
     * @tags MetaData, Instances MetaData
     * @name AddInstanceMetaData
     * @summary Add Instance MetaData
     * @request POST:/api/v2/metadata/instances/{instance_id}
     * @secure
     */
    addInstanceMetaData: (
      instanceId: string,
      body: {
        /** Unique name of the **MetaDataKey**. */
        key_name: string;
        /** String representation of the value for the **MetaDataKey**.<br>To parse non standard string represetations set a proper parse-format string in *format* */
        values: string[];
        /** allows to add special handling for a value, based on its position in the array (1-based) */
        specs?: ValueSpec[];
      },
      params: RequestParams = {},
    ) =>
      this.request<EmptyRes, ErrorResponse | RpcStatus>({
        path: `/api/v2/metadata/instances/${instanceId}`,
        method: "POST",
        body: body,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Adds a **MetaDataKey** and its values to all **InstanceCells** under the **ActionLabel**
     *
     * @tags MetaData, Instances ActionLabels MetaData
     * @name AddInstanceActionLabelMetaData
     * @summary Add Instance ActionLabel MetaData
     * @request POST:/api/v2/metadata/instances/{instance_id}/actionlabels/{action_label}
     * @secure
     */
    addInstanceActionLabelMetaData: (
      instanceId: string,
      actionLabel: string,
      body: {
        /** Unique name of the **MetaDataKey**. */
        key_name: string;
        /** String representation of the value for the **MetaDataKey**.<br>To parse non standard string represetations set a proper parse-format string in *format* */
        values: string[];
        /** allows to add special handling for a value */
        specs?: ValueSpec[];
      },
      params: RequestParams = {},
    ) =>
      this.request<EmptyRes, ErrorResponse | RpcStatus>({
        path: `/api/v2/metadata/instances/${instanceId}/actionlabels/${actionLabel}`,
        method: "POST",
        body: body,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Removes a **MetaDataKey** and *all* its values from all the **InstanceCells** under the **ActionLabel**
     *
     * @tags MetaData, Instances ActionLabels MetaData
     * @name RemoveInstanceActionLabelMetaData
     * @summary Remove Instance ActionLabel MetaData
     * @request DELETE:/api/v2/metadata/instances/{instance_id}/actionlabels/{action_label}/keys/{key_name}
     * @secure
     */
    removeInstanceActionLabelMetaData: (
      instanceId: string,
      actionLabel: string,
      keyName: string,
      params: RequestParams = {},
    ) =>
      this.request<EmptyRes, ErrorResponse | RpcStatus>({
        path: `/api/v2/metadata/instances/${instanceId}/actionlabels/${actionLabel}/keys/${keyName}`,
        method: "DELETE",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Adds an additional value to the values of a particular **MetaDataKey** linked to all **InstanceCells* under the **ActionLabel**.<br>If the value should already be in the array of values, an error is returned.
     *
     * @tags MetaData, Instances ActionLabels MetaData
     * @name AddInstanceActionLabelMetaDataValue
     * @summary Add Instance ActionLabel MetaData Value
     * @request POST:/api/v2/metadata/instances/{instance_id}/actionlabels/{action_label}/keys/{key_name}/values
     * @secure
     */
    addInstanceActionLabelMetaDataValue: (
      instanceId: string,
      actionLabel: string,
      keyName: string,
      body: {
        /** String representation of the value for the **MetaDataKey**.<br>To parse non standard string represetations set a proper parse-format string in *format* */
        value: string;
        /** Set this value if you want to use a different **DataType** from the default **DataType** for the values of the **MetaDataKey**. */
        spec?: DataTypeFormat;
      },
      params: RequestParams = {},
    ) =>
      this.request<EmptyRes, ErrorResponse | RpcStatus>({
        path: `/api/v2/metadata/instances/${instanceId}/actionlabels/${actionLabel}/keys/${keyName}/values`,
        method: "POST",
        body: body,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Removes an existing value from the values of a particular **MetaDataKey** linked to all **InstanceCells* under the **ActionLabel**.<br>If the value is not in the array of values, an error is returned.
     *
     * @tags MetaData, Instances ActionLabels MetaData
     * @name RemoveInstanceActionLabelMetaDataValue
     * @summary Remove Instance ActionLabel MetaData Value
     * @request DELETE:/api/v2/metadata/instances/{instance_id}/actionlabels/{action_label}/keys/{key_name}/values/{value}
     * @secure
     */
    removeInstanceActionLabelMetaDataValue: (
      instanceId: string,
      actionLabel: string,
      keyName: string,
      value: string,
      query?: {
        /**
         * overwrites the default_data_type from the default value
         *
         *  - DT_Integer: Integer
         *  - DT_String: String
         *  - DT_Boolean: Boolean
         *  - DT_Decimal: Decimal fixed
         *  - DT_Timestamp: Timestamp
         *  - DT_Uri: URI
         *  - DT_Void: VOID is a designated DataType to indicate no value
         *  - DT_Float: Float
         *  - DT_UnsignedInteger: UInt
         *  - DT_Binary: Byte Array
         *  - DT_Uuid: UUID
         *  - DT_Text: FullTextIndex large object
         *  - DT_Blob: Binary large object
         *  - DT_Clob: Character large object
         *  - DT_Any: DT_Date = 16;
         * DT_Time = 17;
         * special flag to indiacte all DataTypes, to be used in AlternativeDataTypes for ActionLabels and MetaDataKeys
         *  - DT_Delete: special flag to indiacte thet the value is to be removed
         * @default "InvalidDataType"
         */
        specDataType?:
          | "InvalidDataType"
          | "DT_Integer"
          | "DT_String"
          | "DT_Boolean"
          | "DT_Decimal"
          | "DT_Timestamp"
          | "DT_Uri"
          | "DT_Void"
          | "DT_Float"
          | "DT_UnsignedInteger"
          | "DT_Binary"
          | "DT_Uuid"
          | "DT_Text"
          | "DT_Blob"
          | "DT_Clob"
          | "DT_Any"
          | "DT_Delete";
        /** is a format string which allows to specify on how the input-value is to be parsed from string to the internal DataType for storing */
        specFormat?: string;
        /** Defines if the **ShareName** value is used to either clear the **ShareName** if a default is set or to use one or to use one which differs from the default one. */
        specShareNameOverride?: boolean;
        /** The **ShareName** to use by default when an new instance is created and a value is set for this **ActionLabel** */
        specShareName?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<EmptyRes, ErrorResponse | RpcStatus>({
        path: `/api/v2/metadata/instances/${instanceId}/actionlabels/${actionLabel}/keys/${keyName}/values/${value}`,
        method: "DELETE",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Adds a **MetaDataKey** and its values to an **InstanceCell**
     *
     * @tags MetaData, Instances Cells MetaData
     * @name AddInstanceCellMetaData
     * @summary Add InstanceCell MetaData
     * @request POST:/api/v2/metadata/instances/{instance_id}/actionlabels/{action_label}/position/{value_position}
     * @secure
     */
    addInstanceCellMetaData: (
      instanceId: string,
      actionLabel: string,
      valuePosition: number,
      body: {
        /** Unique name of the **MetaDataKey**. */
        key_name: string;
        /** String representation of the value for the **MetaDataKey**.<br>To parse non standard string represetations set a proper parse-format string in *format* */
        values: string[];
        /** allows to add special handling for a value, based on its position in the array (1-based) */
        specs?: ValueSpec[];
      },
      params: RequestParams = {},
    ) =>
      this.request<EmptyRes, ErrorResponse | RpcStatus>({
        path: `/api/v2/metadata/instances/${instanceId}/actionlabels/${actionLabel}/position/${valuePosition}`,
        method: "POST",
        body: body,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Returns all **MetaDataKeys** linked to an **Instance** with all set values
     *
     * @tags MetaData, Instances Cells MetaData
     * @name ListInstanceCellMetaData
     * @summary List InstanceCell MetaData
     * @request GET:/api/v2/metadata/instances/{instance_id}/actionlabels/{action_label}/position/{value_position}/keys
     * @secure
     */
    listInstanceCellMetaData: (
      instanceId: string,
      actionLabel: string,
      valuePosition: number,
      params: RequestParams = {},
    ) =>
      this.request<ListInstanceCellMetaDataRes, ErrorResponse | RpcStatus>({
        path: `/api/v2/metadata/instances/${instanceId}/actionlabels/${actionLabel}/position/${valuePosition}/keys`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Returns the number of **MetaDataKeys** which are associated with the **InstanceCell**
     *
     * @tags MetaData, Instances Cells MetaData
     * @name CountInstanceCellMetaDataKeys
     * @summary Count InstanceCell MetaDataKeys
     * @request GET:/api/v2/metadata/instances/{instance_id}/actionlabels/{action_label}/position/{value_position}/keys/all/count
     * @secure
     */
    countInstanceCellMetaDataKeys: (
      instanceId: string,
      actionLabel: string,
      valuePosition: number,
      params: RequestParams = {},
    ) =>
      this.request<CountRes, ErrorResponse | RpcStatus>({
        path: `/api/v2/metadata/instances/${instanceId}/actionlabels/${actionLabel}/position/${valuePosition}/keys/all/count`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Returns all the values for the given **MetaDataKey** linked to the give **InstanceCell**.
     *
     * @tags MetaData, Instances Cells MetaData
     * @name GetInstanceCellMetaDataValues
     * @summary Get InstanceCell MetaData
     * @request GET:/api/v2/metadata/instances/{instance_id}/actionlabels/{action_label}/position/{value_position}/keys/{key_name}
     * @secure
     */
    getInstanceCellMetaDataValues: (
      instanceId: string,
      actionLabel: string,
      valuePosition: number,
      keyName: string,
      params: RequestParams = {},
    ) =>
      this.request<GetInstanceCellMetaDataKeyRes, ErrorResponse | RpcStatus>({
        path: `/api/v2/metadata/instances/${instanceId}/actionlabels/${actionLabel}/position/${valuePosition}/keys/${keyName}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Removes a **MetaDataKey** and *all* its values from an **InstanceCell**
     *
     * @tags MetaData, Instances Cells MetaData
     * @name RemoveInstanceCellMetaData
     * @summary Remove InstanceCell MetaData
     * @request DELETE:/api/v2/metadata/instances/{instance_id}/actionlabels/{action_label}/position/{value_position}/keys/{key_name}
     * @secure
     */
    removeInstanceCellMetaData: (
      instanceId: string,
      actionLabel: string,
      valuePosition: number,
      keyName: string,
      params: RequestParams = {},
    ) =>
      this.request<EmptyRes, ErrorResponse | RpcStatus>({
        path: `/api/v2/metadata/instances/${instanceId}/actionlabels/${actionLabel}/position/${valuePosition}/keys/${keyName}`,
        method: "DELETE",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Adds an additional value to the values of a particular **MetaDataKey** linked to an **InstanceCell*.<br>If the value should already be in the array of values, an error is returned.
     *
     * @tags MetaData, Instances Cells MetaData
     * @name AddInstanceCellMetaDataValue
     * @summary Add InstanceCell MetaData Value
     * @request POST:/api/v2/metadata/instances/{instance_id}/actionlabels/{action_label}/position/{value_position}/keys/{key_name}/values
     * @secure
     */
    addInstanceCellMetaDataValue: (
      instanceId: string,
      actionLabel: string,
      valuePosition: number,
      keyName: string,
      body: {
        /** String representation of the value of the **MetaDataKey**. */
        value: string;
        /** Use this to specify on how to parse the input value from string to the internal **DataType** */
        spec?: DataTypeFormat;
      },
      params: RequestParams = {},
    ) =>
      this.request<EmptyRes, ErrorResponse | RpcStatus>({
        path: `/api/v2/metadata/instances/${instanceId}/actionlabels/${actionLabel}/position/${valuePosition}/keys/${keyName}/values`,
        method: "POST",
        body: body,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Returns the number of values set for a given **MetaDataKey** of the **InstanceCell**
     *
     * @tags MetaData, Instances Cells MetaData
     * @name CountInstanceCellMetaDataKeyValues
     * @summary Count InstanceCell MetaDataKey Values
     * @request GET:/api/v2/metadata/instances/{instance_id}/actionlabels/{action_label}/position/{value_position}/keys/{key_name}/values/all/count
     * @secure
     */
    countInstanceCellMetaDataKeyValues: (
      instanceId: string,
      actionLabel: string,
      valuePosition: number,
      keyName: string,
      params: RequestParams = {},
    ) =>
      this.request<CountRes, ErrorResponse | RpcStatus>({
        path: `/api/v2/metadata/instances/${instanceId}/actionlabels/${actionLabel}/position/${valuePosition}/keys/${keyName}/values/all/count`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Removes an existing value from the values of a particular **MetaDataKey** linked to an **InstanceCell*.<br>If the value is not in the array of values, an error is returned.
     *
     * @tags MetaData, Instances Cells MetaData
     * @name RemoveInstanceCellMetaDataValue
     * @summary Remove InstanceCell MetaData Value
     * @request DELETE:/api/v2/metadata/instances/{instance_id}/actionlabels/{action_label}/position/{value_position}/keys/{key_name}/values/{value}
     * @secure
     */
    removeInstanceCellMetaDataValue: (
      instanceId: string,
      actionLabel: string,
      valuePosition: number,
      keyName: string,
      value: string,
      query?: {
        /**
         * overwrites the default_data_type from the default value
         *
         *  - DT_Integer: Integer
         *  - DT_String: String
         *  - DT_Boolean: Boolean
         *  - DT_Decimal: Decimal fixed
         *  - DT_Timestamp: Timestamp
         *  - DT_Uri: URI
         *  - DT_Void: VOID is a designated DataType to indicate no value
         *  - DT_Float: Float
         *  - DT_UnsignedInteger: UInt
         *  - DT_Binary: Byte Array
         *  - DT_Uuid: UUID
         *  - DT_Text: FullTextIndex large object
         *  - DT_Blob: Binary large object
         *  - DT_Clob: Character large object
         *  - DT_Any: DT_Date = 16;
         * DT_Time = 17;
         * special flag to indiacte all DataTypes, to be used in AlternativeDataTypes for ActionLabels and MetaDataKeys
         *  - DT_Delete: special flag to indiacte thet the value is to be removed
         * @default "InvalidDataType"
         */
        specDataType?:
          | "InvalidDataType"
          | "DT_Integer"
          | "DT_String"
          | "DT_Boolean"
          | "DT_Decimal"
          | "DT_Timestamp"
          | "DT_Uri"
          | "DT_Void"
          | "DT_Float"
          | "DT_UnsignedInteger"
          | "DT_Binary"
          | "DT_Uuid"
          | "DT_Text"
          | "DT_Blob"
          | "DT_Clob"
          | "DT_Any"
          | "DT_Delete";
        /** is a format string which allows to specify on how the input-value is to be parsed from string to the internal DataType for storing */
        specFormat?: string;
        /** Defines if the **ShareName** value is used to either clear the **ShareName** if a default is set or to use one or to use one which differs from the default one. */
        specShareNameOverride?: boolean;
        /** The **ShareName** to use by default when an new instance is created and a value is set for this **ActionLabel** */
        specShareName?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<EmptyRes, ErrorResponse | RpcStatus>({
        path: `/api/v2/metadata/instances/${instanceId}/actionlabels/${actionLabel}/position/${valuePosition}/keys/${keyName}/values/${value}`,
        method: "DELETE",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Returns all **MetaDataKeys** linked to an **Instance** with all set values
     *
     * @tags MetaData, Instances MetaData
     * @name ListInstanceMetaData
     * @summary List Instance MetaData
     * @request GET:/api/v2/metadata/instances/{instance_id}/keys
     * @secure
     */
    listInstanceMetaData: (instanceId: string, params: RequestParams = {}) =>
      this.request<ListInstanceMetaDataRes, ErrorResponse | RpcStatus>({
        path: `/api/v2/metadata/instances/${instanceId}/keys`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Returns the number of **MetaDataKeys** which are associated with the **Instance**
     *
     * @tags MetaData, Instances MetaData
     * @name CountInstanceMetaDataKeys
     * @summary Count Instance MetaDataKeys
     * @request GET:/api/v2/metadata/instances/{instance_id}/keys/all/count
     * @secure
     */
    countInstanceMetaDataKeys: (instanceId: string, params: RequestParams = {}) =>
      this.request<CountRes, ErrorResponse | RpcStatus>({
        path: `/api/v2/metadata/instances/${instanceId}/keys/all/count`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Returns all the values for the given **MetaDataKey** linked to the give **Instance**.
     *
     * @tags MetaData, Instances MetaData
     * @name GetInstanceMetaDataValues
     * @summary Get Instance MetaData Values
     * @request GET:/api/v2/metadata/instances/{instance_id}/keys/{key_name}
     * @secure
     */
    getInstanceMetaDataValues: (instanceId: string, keyName: string, params: RequestParams = {}) =>
      this.request<GetInstanceMetaDataKeyRes, ErrorResponse | RpcStatus>({
        path: `/api/v2/metadata/instances/${instanceId}/keys/${keyName}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Removes a **MetaDataKey** and *all* its values from an **Instance**
     *
     * @tags MetaData, Instances MetaData
     * @name RemoveInstanceMetaData
     * @summary Remove Instance MetaData
     * @request DELETE:/api/v2/metadata/instances/{instance_id}/keys/{key_name}
     * @secure
     */
    removeInstanceMetaData: (instanceId: string, keyName: string, params: RequestParams = {}) =>
      this.request<EmptyRes, ErrorResponse | RpcStatus>({
        path: `/api/v2/metadata/instances/${instanceId}/keys/${keyName}`,
        method: "DELETE",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Adds an additional value to the values of a particular **MetaDataKey** linked to an **Instance*.<br>If the value should already be in the array of values, an error is returned.
     *
     * @tags MetaData, Instances MetaData
     * @name AddInstanceMetaDataValue
     * @summary Add Instance MetaData Value
     * @request POST:/api/v2/metadata/instances/{instance_id}/keys/{key_name}/values
     * @secure
     */
    addInstanceMetaDataValue: (
      instanceId: string,
      keyName: string,
      body: {
        /** String representation of the value for the **MetaDataKey**.<br>To parse non standard string represetations set a proper parse-format string in *format* */
        value: string;
        /** Use this to specify on how to parse the input value from string to the internal **DataType** */
        spec?: DataTypeFormat;
      },
      params: RequestParams = {},
    ) =>
      this.request<EmptyRes, ErrorResponse | RpcStatus>({
        path: `/api/v2/metadata/instances/${instanceId}/keys/${keyName}/values`,
        method: "POST",
        body: body,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Returns the number of values set for a given **MetaDataKey** of the **Instance**
     *
     * @tags MetaData, Instances MetaData
     * @name CountInstanceMetaDataKeyValues
     * @summary Count Instance MetaDataKey Values
     * @request GET:/api/v2/metadata/instances/{instance_id}/keys/{key_name}/values/all/count
     * @secure
     */
    countInstanceMetaDataKeyValues: (instanceId: string, keyName: string, params: RequestParams = {}) =>
      this.request<CountRes, ErrorResponse | RpcStatus>({
        path: `/api/v2/metadata/instances/${instanceId}/keys/${keyName}/values/all/count`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Removes an existing value from the values of a particular **MetaDataKey** linked to an **Instance*.<br>If the value is not in the array of values, an error is returned.
     *
     * @tags MetaData, Instances MetaData
     * @name RemoveInstanceMetaDataValue
     * @summary Remove Instance MetaData Value
     * @request DELETE:/api/v2/metadata/instances/{instance_id}/keys/{key_name}/values/{value}
     * @secure
     */
    removeInstanceMetaDataValue: (
      instanceId: string,
      keyName: string,
      value: string,
      query?: {
        /**
         * overwrites the default_data_type from the default value
         *
         *  - DT_Integer: Integer
         *  - DT_String: String
         *  - DT_Boolean: Boolean
         *  - DT_Decimal: Decimal fixed
         *  - DT_Timestamp: Timestamp
         *  - DT_Uri: URI
         *  - DT_Void: VOID is a designated DataType to indicate no value
         *  - DT_Float: Float
         *  - DT_UnsignedInteger: UInt
         *  - DT_Binary: Byte Array
         *  - DT_Uuid: UUID
         *  - DT_Text: FullTextIndex large object
         *  - DT_Blob: Binary large object
         *  - DT_Clob: Character large object
         *  - DT_Any: DT_Date = 16;
         * DT_Time = 17;
         * special flag to indiacte all DataTypes, to be used in AlternativeDataTypes for ActionLabels and MetaDataKeys
         *  - DT_Delete: special flag to indiacte thet the value is to be removed
         * @default "InvalidDataType"
         */
        specDataType?:
          | "InvalidDataType"
          | "DT_Integer"
          | "DT_String"
          | "DT_Boolean"
          | "DT_Decimal"
          | "DT_Timestamp"
          | "DT_Uri"
          | "DT_Void"
          | "DT_Float"
          | "DT_UnsignedInteger"
          | "DT_Binary"
          | "DT_Uuid"
          | "DT_Text"
          | "DT_Blob"
          | "DT_Clob"
          | "DT_Any"
          | "DT_Delete";
        /** is a format string which allows to specify on how the input-value is to be parsed from string to the internal DataType for storing */
        specFormat?: string;
        /** Defines if the **ShareName** value is used to either clear the **ShareName** if a default is set or to use one or to use one which differs from the default one. */
        specShareNameOverride?: boolean;
        /** The **ShareName** to use by default when an new instance is created and a value is set for this **ActionLabel** */
        specShareName?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<EmptyRes, ErrorResponse | RpcStatus>({
        path: `/api/v2/metadata/instances/${instanceId}/keys/${keyName}/values/${value}`,
        method: "DELETE",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Lists all MetaDataKeys in the Dynizer.<br>The optional window allows for pageing support.
     *
     * @tags MetaData, MetaDataKey
     * @name ListMetaDataKeys
     * @summary List MetaDataKeys
     * @request GET:/api/v2/metadata/keys
     * @secure
     */
    listMetaDataKeys: (
      query: {
        /**
         * Defines how many records at max are returned.<br>If fewer records are available, all will be returned.<br>Setting this to -1 will be interpreted as no limit
         * @format int64
         */
        windowLimit: number;
        /**
         * Defines the offset at which to start reading.<br>An offset of 0 will bean to start from the first possible record.
         * @format int64
         */
        windowOffset: number;
        /** The Dynizer keeps track of the last read position, this flag indicates wether the offset is relative to the current read position or absolute. */
        windowRelative?: boolean;
      },
      params: RequestParams = {},
    ) =>
      this.request<MetaDataKeyArrayRes, ErrorResponse | RpcStatus>({
        path: `/api/v2/metadata/keys`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Create a **MetaDataKey** definition.<br>
     *
     * @tags MetaData, MetaDataKey
     * @name CreateMetaDataKey
     * @summary Create MetaDataKey
     * @request POST:/api/v2/metadata/keys
     * @secure
     */
    createMetaDataKey: (body: CreateMetaDataKeyReq, params: RequestParams = {}) =>
      this.request<EmptyRes, ErrorResponse | RpcStatus>({
        path: `/api/v2/metadata/keys`,
        method: "POST",
        body: body,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Returns the number of MetaDataKeys in the Dynizer
     *
     * @tags MetaData, MetaDataKey
     * @name CountMetaDataKeys
     * @summary Count MetaDataKeys
     * @request GET:/api/v2/metadata/keys/all/count
     * @secure
     */
    countMetaDataKeys: (params: RequestParams = {}) =>
      this.request<CountRes, ErrorResponse | RpcStatus>({
        path: `/api/v2/metadata/keys/all/count`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Reads the **MetaDataKey** definition
     *
     * @tags MetaData, MetaDataKey
     * @name ReadMetaDataKey
     * @summary Read MetaDataKey
     * @request GET:/api/v2/metadata/keys/{key_name}
     * @secure
     */
    readMetaDataKey: (keyName: string, params: RequestParams = {}) =>
      this.request<MetaDataKeyRes, ErrorResponse | RpcStatus>({
        path: `/api/v2/metadata/keys/${keyName}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Deletes a **MetaDataKey**.<br>If *cascade* is set to true, all usages of the **MetaDataKey** are deleted as well.
     *
     * @tags MetaData, MetaDataKey
     * @name DeleteMetaDataKey
     * @summary Delete MetaDataKey
     * @request DELETE:/api/v2/metadata/keys/{key_name}
     * @secure
     */
    deleteMetaDataKey: (
      keyName: string,
      query?: {
        /** Flag which contrls the behaviour of the delete.<br>If set to <span style="color:green">**true**</span>, all usages of the **MetaDataKey** will get deleted as well.<br>If set to <span style="color:red">**false**</span> an error will be raised if the **MetaDataKey** is in use. */
        cascade?: boolean;
      },
      params: RequestParams = {},
    ) =>
      this.request<EmptyRes, ErrorResponse | RpcStatus>({
        path: `/api/v2/metadata/keys/${keyName}`,
        method: "DELETE",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description The *name* of the MetaDataKey can be changed.<br>The default DataType can be changed.<br>The max occurrence can be changed.<br>The list of alternative DataTypes can be adopted as well.<br>If an update to the **MetaDataKey** should lead to a violation of any MetaDataKey - value, and error is raised, unless the *cascade* flag is set to true, in which case we will do TBD
     *
     * @tags MetaData, MetaDataKey
     * @name UpdateMetaDataKey
     * @summary Update MetaDataKey
     * @request PATCH:/api/v2/metadata/keys/{key_name}
     * @secure
     */
    updateMetaDataKey: (
      keyName: string,
      body: {
        /** New unique name of the **MetaDataKey**.<br>The CheckMetaDataKeyName call can be used to check if the name can be used. */
        new_key_name?: string;
        /** The **DataType** for this **MetaDataKey** value.<br>This **DataType** will be used when creating values for the MetaData with this **MetaDataKey**. */
        default_data_type?: DataType;
        /**
         * How many values at most can be stored in the **MetaDataKey** value array for one use.<br>Not setting this property or setting it to 0, will mean to the maximum based on system settings.
         * @format int64
         */
        max_occurrence?: number;
        /** List of additional allowed **DataTypes** for this **MetaDataKey**.<br>If left empty only the *default_data_type* is allowed for the values of this **MetaDataKey**. */
        alternative_data_types?: DataType[];
      },
      params: RequestParams = {},
    ) =>
      this.request<EmptyRes, ErrorResponse | RpcStatus>({
        path: `/api/v2/metadata/keys/${keyName}`,
        method: "PATCH",
        body: body,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Can be used by any user to change ones own password.<br>It requires the current password for added security.
     *
     * @tags Users
     * @name ChangeMyPassword
     * @summary Change My Password
     * @request PUT:/api/v2/my/password
     * @secure
     */
    changeMyPassword: (body: ChangePasswordReq, params: RequestParams = {}) =>
      this.request<EmptyRes, ErrorResponse | RpcStatus>({
        path: `/api/v2/my/password`,
        method: "PUT",
        body: body,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Returns the current users information.<br>This includes all roles.
     *
     * @tags Users
     * @name GetMyProfile
     * @summary Get My Profile
     * @request GET:/api/v2/my/profile
     * @secure
     */
    getMyProfile: (params: RequestParams = {}) =>
      this.request<UserRes, ErrorResponse | RpcStatus>({
        path: `/api/v2/my/profile`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Returns the current logged on users roles.
     *
     * @tags Users
     * @name GetMyRoles
     * @summary Get My Roles
     * @request GET:/api/v2/my/roles
     * @secure
     */
    getMyRoles: (params: RequestParams = {}) =>
      this.request<ListUserRolesRes, ErrorResponse | RpcStatus>({
        path: `/api/v2/my/roles`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Returns a list of all **Object** UUIDs.
     *
     * @tags Objects
     * @name ListObjectUuids
     * @summary List Object UUIDs
     * @request GET:/api/v2/objects
     * @secure
     */
    listObjectUuids: (params: RequestParams = {}) =>
      this.request<ObjectUUIDArrayRes, ErrorResponse | RpcStatus>({
        path: `/api/v2/objects`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description This deletes all large object blocks
     *
     * @tags Objects
     * @name DeleteAllObjects
     * @summary Delete all Objects
     * @request DELETE:/api/v2/objects
     * @secure
     */
    deleteAllObjects: (params: RequestParams = {}) =>
      this.request<EmptyRes, ErrorResponse | RpcStatus>({
        path: `/api/v2/objects`,
        method: "DELETE",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description creates a reference uri value in the Dynizer which is needed to upload a large value of type *TEXT*, *CLOB*, or *BLOB*.<br>*TEXT* is the DataType which will get indexed by the **FullTextIndex** if enabled.<br>*CLOB* is a charatecr LOB, unindexed large string<br>*BLOB* is a binary LOB, unindexed bytes
     *
     * @tags Objects
     * @name CreateObject
     * @summary Create Object
     * @request POST:/api/v2/objects
     * @secure
     */
    createObject: (body: CreateObjectReq, params: RequestParams = {}) =>
      this.request<ObjectRes, ErrorResponse | RpcStatus>({
        path: `/api/v2/objects`,
        method: "POST",
        body: body,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description This allows to get the object download url
     *
     * @tags Objects
     * @name DownloadObjectData
     * @summary Download Object Data
     * @request GET:/api/v2/objects/{type}/{ref_uuid}
     * @secure
     */
    downloadObjectData: (type: string, refUuid: string, params: RequestParams = {}) =>
      this.request<DownloadObjectDataRes, ErrorResponse | RpcStatus>({
        path: `/api/v2/objects/${type}/${refUuid}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description This deletes the referenced large object block
     *
     * @tags Objects
     * @name DeleteObject
     * @summary Delete Object
     * @request DELETE:/api/v2/objects/{type}/{ref_uuid}
     * @secure
     */
    deleteObject: (type: string, refUuid: string, params: RequestParams = {}) =>
      this.request<EmptyRes, ErrorResponse | RpcStatus>({
        path: `/api/v2/objects/${type}/${refUuid}`,
        method: "DELETE",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description This allows for streaming in a large object block
     *
     * @tags Objects
     * @name UploadObjectData
     * @summary Upload Object Data
     * @request POST:/api/v2/objects/{type}/{ref_uuid}
     * @secure
     */
    uploadObjectData: (
      type: string,
      refUuid: string,
      body: {
        /**
         * The **Object** data as base64 encoded string.
         * @format byte
         */
        data?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<EmptyRes, ErrorResponse | RpcStatus>({
        path: `/api/v2/objects/${type}/${refUuid}`,
        method: "POST",
        body: body,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description This clears the content of the referenced large object block
     *
     * @tags Objects
     * @name ClearObjectData
     * @summary Clear Object Data
     * @request PATCH:/api/v2/objects/{type}/{ref_uuid}
     * @secure
     */
    clearObjectData: (
      type: "InvalidObjectDataType" | "ODT_Text" | "ODT_Blob" | "ODT_Clob",
      refUuid: string,
      params: RequestParams = {},
    ) =>
      this.request<EmptyRes, ErrorResponse | RpcStatus>({
        path: `/api/v2/objects/${type}/${refUuid}`,
        method: "PATCH",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Sends in a filtered statement to retrieve plot data json
     *
     * @tags Plots
     * @name DynizerFilteredPlot
     * @summary Dynizer Filtered Plot
     * @request POST:/api/v2/plots/filtered/{top}/{plot_name}
     * @secure
     */
    dynizerFilteredPlot: (
      top: string,
      plotName: string,
      body: {
        /** List of allowed **Actions** for the returned **Instances**.<br>If left empty the search scope will include all **Actions**. */
        actions?: string[];
        /** List of allowed **DataTypes** for the returned **DataElement**.<br>If left empty all **DataTypes** will be returned. */
        data_types?: DataType[];
        /** List of allowed **ComponentTypes** for the returned **DataElement**.<br>If left empty all **ComponentTypes** will be returned. */
        component_types?: ComponentType[];
        /** The filter for the **Instance** requested */
        filters?: InstanceFilter[];
      },
      params: RequestParams = {},
    ) =>
      this.request<PlotRes, ErrorResponse | RpcStatus>({
        path: `/api/v2/plots/filtered/${top}/${plotName}`,
        method: "POST",
        body: body,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Sends in a DynizerQueryLanguage statement.<br>*SELECT* to retrive information.<br>INSERT, UPDATE, DELETE, CREATE, DROP, ... are not supported at this point in time.
     *
     * @tags Query
     * @name DynizerQuery
     * @summary Dynizer Query
     * @request POST:/api/v2/query
     * @secure
     */
    dynizerQuery: (body: DQLReq, params: RequestParams = {}) =>
      this.request<DQLRes, ErrorResponse | RpcStatus>({
        path: `/api/v2/query`,
        method: "POST",
        body: body,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Binds parameter to a parsed DynizerQueryLanguage statement
     *
     * @tags Query
     * @name QueryBind
     * @summary binds the parameter to a parsed query
     * @request POST:/api/v2/query/bind
     * @secure
     */
    queryBind: (body: QueryBindReq, params: RequestParams = {}) =>
      this.request<EmptyRes, ErrorResponse | RpcStatus>({
        path: `/api/v2/query/bind`,
        method: "POST",
        body: body,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Cancels the execution of a running DynizerQuery
     *
     * @tags Query
     * @name QueryCancel
     * @summary cancels a running query
     * @request POST:/api/v2/query/cancel
     * @secure
     */
    queryCancel: (body: QueryCancelReq, params: RequestParams = {}) =>
      this.request<EmptyRes, ErrorResponse | RpcStatus>({
        path: `/api/v2/query/cancel`,
        method: "POST",
        body: body,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Closes a DynizerQueryLanguage statement
     *
     * @tags Query
     * @name QueryClose
     * @summary closes and cleans parsed and/or bind queries
     * @request POST:/api/v2/query/close
     * @secure
     */
    queryClose: (body: QueryCloseReq, params: RequestParams = {}) =>
      this.request<EmptyRes, ErrorResponse | RpcStatus>({
        path: `/api/v2/query/close`,
        method: "POST",
        body: body,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Fetches the ResultRows for the parsed and bound DynizerQueryLanguage statement
     *
     * @tags Query
     * @name QueryExecute
     * @summary executes a parsed and bound query
     * @request POST:/api/v2/query/execute
     * @secure
     */
    queryExecute: (body: QueryExecuteReq, params: RequestParams = {}) =>
      this.request<QueryExecuteRes, ErrorResponse | RpcStatus>({
        path: `/api/v2/query/execute`,
        method: "POST",
        body: body,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Sends in a filtered statement to retrive **instances**
     *
     * @tags Query
     * @name DynizerFilteredQuery
     * @summary Dynizer Filtered Query
     * @request POST:/api/v2/query/filtered
     * @secure
     */
    dynizerFilteredQuery: (body: FilterQueryReq, params: RequestParams = {}) =>
      this.request<DQLRes, ErrorResponse | RpcStatus>({
        path: `/api/v2/query/filtered`,
        method: "POST",
        body: body,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Parses a DynizerQueryLanguage statement.<br>
     *
     * @tags Query
     * @name QueryParse
     * @summary parses a dql and stores the result in the session
     * @request POST:/api/v2/query/parse
     * @secure
     */
    queryParse: (body: QueryParseReq, params: RequestParams = {}) =>
      this.request<EmptyRes, ErrorResponse | RpcStatus>({
        path: `/api/v2/query/parse`,
        method: "POST",
        body: body,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Fetches the ResultLabels for the parsed DynizerQueryLanguage statement and bound parameters
     *
     * @tags Query
     * @name QueryDescribePortal
     * @summary returns the description for the result labels and optionally for the bound parameters
     * @request POST:/api/v2/query/portal/describe
     * @secure
     */
    queryDescribePortal: (body: QueryDescribePortalReq, params: RequestParams = {}) =>
      this.request<QueryDescribePortalRes, ErrorResponse | RpcStatus>({
        path: `/api/v2/query/portal/describe`,
        method: "POST",
        body: body,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Sends in a DynizerQueryLanguage statement.<br>*SELECT* to retrieve information.<br>INSERT, UPDATE, DELETE, CREATE, DROP
     *
     * @tags Query
     * @name SimpleQuery
     * @summary Simple Query with immediate results
     * @request POST:/api/v2/query/simple
     * @secure
     */
    simpleQuery: (body: DQLReq, params: RequestParams = {}) =>
      this.request<SimpleQueryRes, ErrorResponse | RpcStatus>({
        path: `/api/v2/query/simple`,
        method: "POST",
        body: body,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Fetches the ResultLabels for the parsed DynizerQueryLanguage statement
     *
     * @tags Query
     * @name QueryDescribeStatement
     * @summary returns the description for the result labels and optionally for the bound parameters
     * @request POST:/api/v2/query/statement/describe
     * @secure
     */
    queryDescribeStatement: (body: QueryDescribeStatementReq, params: RequestParams = {}) =>
      this.request<QueryDescribeStatementRes, ErrorResponse | RpcStatus>({
        path: `/api/v2/query/statement/describe`,
        method: "POST",
        body: body,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Close a ResultHandle.<br>This will free all result cache resources
     *
     * @tags Query
     * @name QueryResultClose
     * @summary QueryResultClose
     * @request GET:/api/v2/query/{result_handle}/close
     * @secure
     */
    queryResultClose: (
      resultHandle: string,
      query?: {
        /** The name of the prepared Statement */
        statement?: string;
        /** The name of the portal, this is what the execute references */
        portal?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<EmptyRes, ErrorResponse | RpcStatus>({
        path: `/api/v2/query/${resultHandle}/close`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Reads ResultRows from a ResultHandle.<br>The window can be used have server-side pageing.
     *
     * @tags Query
     * @name QueryResultNext
     * @summary Query Result Next
     * @request GET:/api/v2/query/{result_handle}/next
     * @secure
     */
    queryResultNext: (
      resultHandle: string,
      query: {
        /**
         * Defines how many records at max are returned.<br>If fewer records are available, all will be returned.<br>Setting this to -1 will be interpreted as no limit
         * @format int64
         */
        windowLimit: number;
        /**
         * Defines the offset at which to start reading.<br>An offset of 0 will bean to start from the first possible record.
         * @format int64
         */
        windowOffset: number;
        /** The Dynizer keeps track of the last read position, this flag indicates wether the offset is relative to the current read position or absolute. */
        windowRelative?: boolean;
      },
      params: RequestParams = {},
    ) =>
      this.request<QueryResultRes, ErrorResponse | RpcStatus>({
        path: `/api/v2/query/${resultHandle}/next`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Reads ResultRows from a ResultHandle in a streamed way.<br>The window can be used have server-side pageing.
     *
     * @tags Query
     * @name QueryResultStreamed
     * @summary Query Result Streamed
     * @request GET:/api/v2/query/{result_handle}/stream
     * @secure
     */
    queryResultStreamed: (
      resultHandle: string,
      query: {
        /**
         * Defines how many records at max are returned.<br>If fewer records are available, all will be returned.<br>Setting this to -1 will be interpreted as no limit
         * @format int64
         */
        windowLimit: number;
        /**
         * Defines the offset at which to start reading.<br>An offset of 0 will bean to start from the first possible record.
         * @format int64
         */
        windowOffset: number;
        /** The Dynizer keeps track of the last read position, this flag indicates wether the offset is relative to the current read position or absolute. */
        windowRelative?: boolean;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          /** Message to hold a result row */
          result?: ResultRow;
          error?: RpcStatus;
        },
        ErrorResponse | RpcStatus
      >({
        path: `/api/v2/query/${resultHandle}/stream`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Returns all roles defined in the Dynizer
     *
     * @tags Roles
     * @name ListRoles
     * @summary List Roles
     * @request GET:/api/v2/roles
     * @secure
     */
    listRoles: (params: RequestParams = {}) =>
      this.request<ListRolesRes, ErrorResponse | RpcStatus>({
        path: `/api/v2/roles`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description To Login to the Dynizer using a service token.<br>If the service-token is valid and the associated user has access, a [Json Web Token](https://jwt.io/) [(RFC 7519)](https://tools.ietf.org/html/rfc7519) will be rturned.
     *
     * @tags Auth, Service
     * @name ServiceLogin
     * @summary Service Login
     * @request POST:/api/v2/servicelogin
     */
    serviceLogin: (body: ServiceLoginReq, params: RequestParams = {}) =>
      this.request<LoginRes, ErrorResponse | RpcStatus>({
        path: `/api/v2/servicelogin`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Issues a service token for the current;y authenticated and authorized user.
     *
     * @tags Auth, Service
     * @name IssueServiceToken
     * @summary Issue ServiceToken
     * @request GET:/api/v2/servicetoken/issue
     */
    issueServiceToken: (params: RequestParams = {}) =>
      this.request<ServiceTokenRes, ErrorResponse | RpcStatus>({
        path: `/api/v2/servicetoken/issue`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * @description Creates a new **ShareName**
     *
     * @tags ShareName
     * @name CreateShareName
     * @summary Create ShareName
     * @request POST:/api/v2/sharename
     * @secure
     */
    createShareName: (body: CreateShareNameReq, params: RequestParams = {}) =>
      this.request<EmptyRes, ErrorResponse | RpcStatus>({
        path: `/api/v2/sharename`,
        method: "POST",
        body: body,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Deletes a Value for an existing **ShareName**
     *
     * @tags ShareName
     * @name DeleteShareNameValue
     * @summary Delete ShareNameValue
     * @request POST:/api/v2/sharename/{share_name}/delete/value
     * @secure
     */
    deleteShareNameValue: (
      shareName: string,
      body: {
        /** Value for the share */
        current_value: string;
        /** DataType of the share value */
        data_type: DataType;
      },
      params: RequestParams = {},
    ) =>
      this.request<EmptyRes, ErrorResponse | RpcStatus>({
        path: `/api/v2/sharename/${shareName}/delete/value`,
        method: "POST",
        body: body,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Creates a new Value for an existing **ShareName**
     *
     * @tags ShareName
     * @name CreateShareNameValue
     * @summary Create ShareNameValue
     * @request POST:/api/v2/sharename/{share_name}/value
     * @secure
     */
    createShareNameValue: (
      shareName: string,
      body: {
        /** Value for the share */
        value: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<EmptyRes, ErrorResponse | RpcStatus>({
        path: `/api/v2/sharename/${shareName}/value`,
        method: "POST",
        body: body,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Updates a new Value for an existing **ShareName**
     *
     * @tags ShareName
     * @name UpdateShareNameValue
     * @summary Update ShareNameValue
     * @request PATCH:/api/v2/sharename/{share_name}/value
     * @secure
     */
    updateShareNameValue: (
      shareName: string,
      body: {
        /** old value for the share */
        old_value: string;
        /** Value for the share */
        new_value: string;
        /** DataType of the share value */
        data_type: DataType;
      },
      params: RequestParams = {},
    ) =>
      this.request<EmptyRes, ErrorResponse | RpcStatus>({
        path: `/api/v2/sharename/${shareName}/value`,
        method: "PATCH",
        body: body,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Drops **all data**. **Attention:** This is an **irreversible** operation!
     *
     * @tags SystemMntg
     * @name DropData
     * @request DELETE:/api/v2/system/data
     * @secure
     */
    dropData: (params: RequestParams = {}) =>
      this.request<EmptyRes, ErrorResponse | RpcStatus>({
        path: `/api/v2/system/data`,
        method: "DELETE",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Exports all **Actions**
     *
     * @tags SystemMntg
     * @name ExportUserActions
     * @request POST:/api/v2/system/export/useractions
     * @secure
     */
    exportUserActions: (body: ExportUserActionsReq, params: RequestParams = {}) =>
      this.request<ExportUserActionsRes, ErrorResponse | RpcStatus>({
        path: `/api/v2/system/export/useractions`,
        method: "POST",
        body: body,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Imports all **Actions**
     *
     * @tags SystemMntg
     * @name ImportUserActions
     * @request POST:/api/v2/system/import/useractions
     * @secure
     */
    importUserActions: (body: ImportUserActionsReq, params: RequestParams = {}) =>
      this.request<EmptyRes, ErrorResponse | RpcStatus>({
        path: `/api/v2/system/import/useractions`,
        method: "POST",
        body: body,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Get the version of the **Dynizer**
     *
     * @tags SystemMntg
     * @name GetDynizerVersion
     * @summary Get Dynizer Version
     * @request GET:/api/v2/system/version
     * @secure
     */
    getDynizerVersion: (params: RequestParams = {}) =>
      this.request<VersionRes, ErrorResponse | RpcStatus>({
        path: `/api/v2/system/version`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Returns all users from the Dynizer.<br>Optionally a window can be provided to fetch just a specific sub-set of the users to support pageing.<br>It requires the current password for added security.
     *
     * @tags Users
     * @name ListUsers
     * @summary List Users
     * @request GET:/api/v2/users
     * @secure
     */
    listUsers: (
      query: {
        /**
         * Defines how many records at max are returned.<br>If fewer records are available, all will be returned.<br>Setting this to -1 will be interpreted as no limit
         * @format int64
         */
        windowLimit: number;
        /**
         * Defines the offset at which to start reading.<br>An offset of 0 will bean to start from the first possible record.
         * @format int64
         */
        windowOffset: number;
        /** The Dynizer keeps track of the last read position, this flag indicates wether the offset is relative to the current read position or absolute. */
        windowRelative?: boolean;
      },
      params: RequestParams = {},
    ) =>
      this.request<ListUsersRes, ErrorResponse | RpcStatus>({
        path: `/api/v2/users`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Adds a user to the Dynizer.<br>Requires the admin role to perform this.
     *
     * @tags Users
     * @name AddUser
     * @summary Add User
     * @request POST:/api/v2/users
     * @secure
     */
    addUser: (body: AddUserReq, params: RequestParams = {}) =>
      this.request<EmptyRes, ErrorResponse | RpcStatus>({
        path: `/api/v2/users`,
        method: "POST",
        body: body,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Returns the total number of users.<br>Additional an individual count for enabled and disabled users will be returned as well.<br>It requires the current password for added security.
     *
     * @tags Users
     * @name GetUserCount
     * @summary Get User Count
     * @request GET:/api/v2/users/all/count
     * @secure
     */
    getUserCount: (params: RequestParams = {}) =>
      this.request<UserCountRes, ErrorResponse | RpcStatus>({
        path: `/api/v2/users/all/count`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Get the user information.<br>All assigned roles and the current state are returned.<br>It requires the current password for added security.
     *
     * @tags Users
     * @name GetUser
     * @summary Get User
     * @request GET:/api/v2/users/{user}
     * @secure
     */
    getUser: (user: string, params: RequestParams = {}) =>
      this.request<UserRes, ErrorResponse | RpcStatus>({
        path: `/api/v2/users/${user}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Removes a user completly form the Dynizer.<br>This can not be undone.<br>It requires the current password for added security.
     *
     * @tags Users
     * @name DeleteUser
     * @summary Delete User
     * @request DELETE:/api/v2/users/{user}
     * @secure
     */
    deleteUser: (user: string, params: RequestParams = {}) =>
      this.request<EmptyRes, ErrorResponse | RpcStatus>({
        path: `/api/v2/users/${user}`,
        method: "DELETE",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Disables a user.<br>This will inhibit the user to log on to the Dynizer.<br>Requires the admin role to perform this.
     *
     * @tags Users
     * @name DisableUser
     * @summary Disable User
     * @request PATCH:/api/v2/users/{user}/disable
     * @secure
     */
    disableUser: (user: string, params: RequestParams = {}) =>
      this.request<EmptyRes, ErrorResponse | RpcStatus>({
        path: `/api/v2/users/${user}/disable`,
        method: "PATCH",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Enables a user.<br>Only enabled users can log onto the Dynizer.<br>Requires the admin role to perform this.
     *
     * @tags Users
     * @name EnableUser
     * @summary Enable User
     * @request PATCH:/api/v2/users/{user}/enable
     * @secure
     */
    enableUser: (user: string, params: RequestParams = {}) =>
      this.request<EmptyRes, ErrorResponse | RpcStatus>({
        path: `/api/v2/users/${user}/enable`,
        method: "PATCH",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Sets a new password for a user.<br>Requires the admin role to perform this.
     *
     * @tags Users
     * @name SetUserPassword
     * @summary Set User Password
     * @request PUT:/api/v2/users/{user}/password
     * @secure
     */
    setUserPassword: (
      user: string,
      body: {
        /** The new password string which will be security hashed and stored. */
        new_password: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<EmptyRes, ErrorResponse | RpcStatus>({
        path: `/api/v2/users/${user}/password`,
        method: "PUT",
        body: body,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Returnes all roles which are assigned to a user.<br>It requires the current password for added security.
     *
     * @tags Users
     * @name ListUserRoles
     * @summary List User Roles
     * @request GET:/api/v2/users/{user}/roles
     * @secure
     */
    listUserRoles: (user: string, params: RequestParams = {}) =>
      this.request<ListUserRolesRes, ErrorResponse | RpcStatus>({
        path: `/api/v2/users/${user}/roles`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Assigns a specific role to a user.<br>Requires the admin role to perform this.
     *
     * @tags Users
     * @name AssignUserRole
     * @summary Assign User Role
     * @request POST:/api/v2/users/{user}/roles
     * @secure
     */
    assignUserRole: (
      user: string,
      body: {
        /** Name of the role which is to be added or removed. */
        role: DynizerRole;
      },
      params: RequestParams = {},
    ) =>
      this.request<EmptyRes, ErrorResponse | RpcStatus>({
        path: `/api/v2/users/${user}/roles`,
        method: "POST",
        body: body,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Revokes a specific role from a user.<br>Requires the admin role to perform this.
     *
     * @tags Users
     * @name RevokeUserRole
     * @summary Revoke User Role
     * @request DELETE:/api/v2/users/{user}/roles/{role.name}
     * @secure
     */
    revokeUserRole: (user: string, roleName: string, params: RequestParams = {}) =>
      this.request<EmptyRes, ErrorResponse | RpcStatus>({
        path: `/api/v2/users/${user}/roles/${roleName}`,
        method: "DELETE",
        secure: true,
        format: "json",
        ...params,
      }),
  };
}
