import {
    Button,
    DialogActions,
    DialogBody,
    DialogContent,
    DialogSurface,
    DialogTrigger,
} from "@fluentui/react-components";
import { ProviderState, Providers } from "@microsoft/mgt-element";
import { DriveItem } from "@microsoft/microsoft-graph-types";
import { useEffect, useState } from "react";
import { pipeline } from "../models/pipeline";
import FileCustom from "./FileCustom";
import { canBeAnalyzed } from "./FileList";
import Loading from "./Loading";
import VirtualScroller from "./VirtualScroller";

export type DriveItemWithStatus = {
    driveItem: DriveItem;
    checked: boolean;
};

type Props = {
    startAnalysis: (files: DriveItem[]) => void;
    selectedItems: DriveItem[];
    graphBaseUri: any;
    setError: (err: string) => void;
    fetchPipelines: (itemIds: DriveItem[]) => Promise<pipeline[]>;
};

export default function FileListModalAnalyze({
    startAnalysis,
    selectedItems,
    graphBaseUri,
    setError,
    fetchPipelines,
}: Props) {
    const [files, setFiles] = useState<DriveItemWithStatus[]>([]);
    const [loading, setLoading] = useState(true);
    const [pipelines, setPipelines] = useState<pipeline[]>([]);

    useEffect(() => {
        fetchFiles();
    }, []);

    let nbFilesSelected = files.filter((file) => file.checked).length;

    const fetchFiles = async () => {
        setLoading(true);

        let { files, error } = await getFilesAndChildren(
            selectedItems,
            graphBaseUri
        );

        if (error) {
            setError(error.message);
            return;
        }

        let gotPipelines = await fetchPipelines(files);

        setPipelines(gotPipelines);

        let filesToAnalyze: DriveItemWithStatus[] = files
            .filter((item) => canBeAnalyzed(item, gotPipelines))
            .map((item) => ({
                checked: true,
                driveItem: item,
            }));

        setFiles(filesToAnalyze);

        setLoading(false);
    };

    const handleCheckChange = async (item: DriveItem, checked: boolean) => {
        setFiles(
            files.map((itemIn) => ({
                ...itemIn,
                checked:
                    itemIn.driveItem.id === item.id
                        ? !itemIn.checked
                        : itemIn.checked,
            }))
        );
    };

    const fileList = (items: DriveItemWithStatus[]) =>
        items.map((item) => (
            <FileCustom
                file={item.driveItem}
                checked={item.checked}
                checkChange={handleCheckChange}
                onClick={() => {}}
                key={item.driveItem.id}
                pipeline={pipelines.find(
                    (pipeline) => pipeline.teamsId === item.driveItem.id
                )}
                selected={false}
            ></FileCustom>
        ));

    const handleStart = () => {
        startAnalysis(
            files.filter((file) => file.checked).map((el) => el.driveItem)
        );
    };

    return (
        <DialogSurface>
            <DialogBody>
                <DialogContent>
                    {loading ? (
                        <div style={{ height: "60px" }}>
                            <Loading />
                        </div>
                    ) : files.length ? (
                        <VirtualScroller
                            hasMore={false}
                            jsxItems={fileList(files)}
                            loadNextPage={() => {}}
                            loadingMore={false}
                            nbLoadedFiled={files.length}
                        ></VirtualScroller>
                    ) : (
                        <div>No processable files in selection</div>
                    )}
                </DialogContent>
                <DialogActions>
                    <DialogTrigger disableButtonEnhancement>
                        <Button appearance="secondary">Close</Button>
                    </DialogTrigger>
                    <Button
                        appearance="primary"
                        disabled={!nbFilesSelected}
                        onClick={handleStart}
                    >
                        Start Analysis{" "}
                        {nbFilesSelected ? <>({nbFilesSelected})</> : <></>}
                    </Button>
                </DialogActions>
            </DialogBody>
        </DialogSurface>
    );
}

export const getFilesAndChildren = async (
    itemsIn: DriveItem[],
    graphBaseUri: string
) => {
    let files: DriveItem[] = [];

    for (let item of itemsIn) {
        if (item.folder && item.id) {
            let children: DriveItem[];

            try {
                children = await recursivelyFetchFiles(item.id, graphBaseUri);
            } catch (err: any) {
                if (err.statusCode === 401) {
                    Providers.globalProvider.setState(ProviderState.SignedOut);
                }

                files = [];

                return { files, error: err.message };
            }

            files = [...files, ...children];
        } else {
            files = [...files, item];
        }
    }

    return { files, error: null };
};

export const recursivelyFetchFiles = async (
    folderId: string,
    graphBaseUri: string
) => {
    let response = await Providers.globalProvider.graph.client
        .api(`/${graphBaseUri}/drive/items/${folderId}/children`)
        .top(200)
        .get();

    let items: DriveItem[] = response.value;
    let nextUrl: string = response["@odata.nextLink"] || "";

    while (nextUrl) {
        response = await Providers.globalProvider.graph.client
            .api(nextUrl)
            .get();

        items = [...items, ...response.value];

        nextUrl = response["@odata.nextLink"] || "";
    }

    let files: DriveItem[] = [];

    for (const item of items) {
        if (item.folder && item.id) {
            const children = await recursivelyFetchFiles(item.id, graphBaseUri);
            files = [...files, ...children];
        } else {
            files = [...files, item];
        }
    }

    return [...files];
};
