import React, { useEffect, useRef } from "react";

type Props = { inJson: any };

const ToneAnalysis: React.FC<Props> = ({ inJson }) => {
    const refElm = useRef<any>(null);

    useEffect(() => {
        if (inJson && refElm.current) {
            refElm.current.data = JSON.parse(inJson).document;
        }
    }, [inJson]);

    return <consono-tone-analysis ref={refElm}></consono-tone-analysis>;
};

export default ToneAnalysis;
