/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

/** Limits the amount of records to return. */
export type Limit = number;

/** Offsets the records to return. */
export type Offset = number;

/** ID of an object within the collection. */
export type ID = string;

/** Name of an object within the collection (searchable). */
export type Name = string;

/** Search string to search for objects within a collection (by name). */
export type SearchString = string;

/** Raw text string. */
export type Data = string;

/** Encoding of a document. */
export type Encoding = string;

/** A client side ID that can be set to reference a document. */
export type ExternalID = string;

/** Path part of a URI. */
export type URIPath = string;

/** Full URI string. */
export type URI = string;

/** Total number of records available within a collection query */
export type Total = number;

/** User name. */
export type User = string;

/** User password */
export type Password = string;

/** Authentication token */
export type Token = string;

/** Service token to be used to login to Dynizer */
export type ServiceToken = string;

/** hostname of a service */
export type Host = string;

/** port of a service */
export type Port = number;

/** Number of processes available */
export type ProcessAvailable = number;

/** Amount of memory available */
export type MemoryAvailable = string;

/** Number of processes queued */
export type ProcessQueued = number;

/** Include output data */
export type IncludeOutput = boolean;

/** Include document */
export type IncludeDocument = boolean;

/** List of names. */
export type NameList = Name[];

/** Login parameters. Currently only dynizer supported. */
export interface Login {
    /** Dynizer authentication parameters. Either a user and password or a service token. */
    dynizer: DynizerAuth;
}

/** Login response token. */
export interface LoginToken {
    /** Authentication token */
    token: Token;
}

/** Basic authentication parameters. User and password. */
export interface BasicAuth {
    /** User name. */
    user: User;
    /** User password */
    password: Password;
}

/** Bearer authentication parameters. Token. */
export interface BearerAuth {
    /** Authentication token */
    token: Token;
}

/** Dynizer user authentication parameters. User and password. */
export interface DynizerUserAuth {
    /** User name. */
    user: User;
    /** User password */
    password: Password;
}

/** Dynizer service token authentication parameters. Service token string. */
export interface DynizerServiceTokenAuth {
    /** Service token to be used to login to Dynizer */
    token: ServiceToken;
}

/** Dynizer authentication parameters. Either a user and password or a service token. */
export interface DynizerAuth {
    /** Dynizer user authentication parameters. User and password. */
    user?: DynizerUserAuth;
    /** Dynizer service token authentication parameters. Service token string. */
    service_token?: DynizerServiceTokenAuth;
}

/**
 * Authentication configuration parameters
 * Set (only) one of the supported methods: 'basic', 'bearer', 'dynizer'
 */
export interface AuthenticationParameters {
    /** Basic authentication parameters. User and password. */
    basic?: BasicAuth;
    /** Bearer authentication parameters. Token. */
    bearer?: BearerAuth;
    /** Dynizer authentication parameters. Either a user and password or a service token. */
    dynizer?: DynizerAuth;
}

/** Create authentication configuration request object. Contains a name and the authentication parameters to use. */
export interface CreateAuthenticationConfiguration {
    /** Name of an object within the collection (searchable). */
    name: Name;
    /**
     * Authentication configuration parameters
     * Set (only) one of the supported methods: 'basic', 'bearer', 'dynizer'
     */
    parameters: AuthenticationParameters;
}

/** Update authentication configuration request object. Both the name as well as the authentication parameters can be updated */
export interface UpdateAuthenticationConfiguration {
    /** Name of an object within the collection (searchable). */
    name?: Name;
    /**
     * Authentication configuration parameters
     * Set (only) one of the supported methods: 'basic', 'bearer', 'dynizer'
     */
    parameters?: AuthenticationParameters;
}

/** Create authentication configuration response object. Contains a unique ID to refer to the configuration. */
export interface CreateAuthenticationConfigurationResult {
    /** ID of an object within the collection. */
    id: ID;
}

/** Get authentication configuration response object. A list of authentication configurations along with the total number that can be returned. */
export interface GetAuthenticationConfigurations {
    /** Total number of records available within a collection query */
    total: Total;
    /** List of authentication configurations. */
    records?: GetAuthenticationConfigurationsList;
}

/** List of authentication configurations. */
export type GetAuthenticationConfigurationsList =
    GetAuthenticationConfiguration[];

/** Get authentication configuration response object. Contains the ID along with the configured name and authentication parameters. */
export interface GetAuthenticationConfiguration {
    /** ID of an object within the collection. */
    id: ID;
    /** Name of an object within the collection (searchable). */
    name: Name;
    /**
     * Authentication configuration parameters
     * Set (only) one of the supported methods: 'basic', 'bearer', 'dynizer'
     */
    parameters: AuthenticationParameters;
}

/** Connection parameters. */
export interface ConnectionParameters {
    /** hostname of a service */
    host: Host;
    /** port of a service */
    port: Port;
    /** ID of an object within the collection. */
    authentication_configuration_id?: ID;
}

/** Create connection request object. Contains a name, type and the authentication parameters to use. Use /connectiontypes to get a list of supported connection types. */
export interface CreateConnection {
    /** Name of an object within the collection (searchable). */
    name: Name;
    /** Name of an object within the collection (searchable). */
    type_name: Name;
    /** Connection parameters. */
    parameters: ConnectionParameters;
}

/** Update connection request object. Both the name, type as well as the connection parameters can be updated. Use /connectiontypes to get a list of supported connection types. */
export interface UpdateConnection {
    /** Name of an object within the collection (searchable). */
    name?: Name;
    /** Name of an object within the collection (searchable). */
    type_name?: Name;
    /** Connection parameters. */
    parameters?: ConnectionParameters;
}

/** Create connection response object. Contains a unique ID to refer to the connection. */
export interface CreateConnectionResult {
    /** ID of an object within the collection. */
    id: ID;
}

/** Get connection response object. A list of connections along with the total number that can be returned. */
export interface GetConnections {
    /** Total number of records available within a collection query */
    total: Total;
    /** List of connections. */
    records?: GetConnectionsList;
}

/** List of connections. */
export type GetConnectionsList = GetConnection[];

/** Get connection response object. Contains the ID along with the configured name, type and connection parameters. Use /connectiontypes to get a list of supported connection types. */
export interface GetConnection {
    /** ID of an object within the collection. */
    id: ID;
    /** Name of an object within the collection (searchable). */
    name: Name;
    /** Name of an object within the collection (searchable). */
    type_name: Name;
    /** Connection parameters. */
    parameters: ConnectionParameters;
}

/** Document raw text */
export interface TextDocument {
    /** Raw text string. */
    data: Data;
}

/** Document serialized in base64 string */
export interface Base64Document {
    /** Raw text string. */
    data: Data;
}

/** Document stored in a private storage. Needs a **Connection** defined to store the parameters on where to reach the storage, and a path to the file on that storage. */
export interface PrivateDocument {
    /** Path part of a URI. */
    path: URIPath;
    /** ID of an object within the collection. */
    connection_id: ID;
}

/** Document which is publicly available. Full URI must be given. */
export interface PublicDocument {
    /** Full URI string. */
    uri: URI;
}

export type FiletypeConfiguration = any;

export type FlowConfiguration = any;

export type PluginConfiguration = any;

/** Filetype and configuration of a document */
export interface DocumentFiletypeConfiguration {
    /** Name of an object within the collection (searchable). */
    name: Name;
    config?: FiletypeConfiguration;
}

/**
 * Data to store for the document. The filetype describes the type of document. Call /filetype_configurations endpoint to get a list of supported filetypes.
 * Optionally the encoding of the document can be given. If this is not set, the process will try to detect the encoding of the data.
 * To add data, set (only) one of the following fields: text, base64, private, public.
 * In case the data is passed on as 'text' or 'base64', it is not stored permanently and cannot be accessed again.
 */
export interface Document {
    /** Filetype and configuration of a document */
    filetype_configuration: DocumentFiletypeConfiguration;
    /** Encoding of a document. */
    encoding?: Encoding;
    /** Document raw text */
    text?: TextDocument;
    /** Document serialized in base64 string */
    base64?: Base64Document;
    /** Document stored in a private storage. Needs a **Connection** defined to store the parameters on where to reach the storage, and a path to the file on that storage. */
    private?: PrivateDocument;
    /** Document which is publicly available. Full URI must be given. */
    public?: PublicDocument;
    /** A client side ID that can be set to reference a document. */
    external_id?: ExternalID;
}

/** Create document request object. Contains a name and the document data. */
export interface CreateDocument {
    /** Name of an object within the collection (searchable). */
    name: Name;
    /**
     * Data to store for the document. The filetype describes the type of document. Call /filetype_configurations endpoint to get a list of supported filetypes.
     * Optionally the encoding of the document can be given. If this is not set, the process will try to detect the encoding of the data.
     * To add data, set (only) one of the following fields: text, base64, private, public.
     * In case the data is passed on as 'text' or 'base64', it is not stored permanently and cannot be accessed again.
     */
    document: Document;
}

/** Create document response object. Contains a unique ID to refer to the document. */
export interface CreateDocumentResult {
    /** ID of an object within the collection. */
    id: ID;
}

/** Get documents response object. A list of documents along with the total number of documents that can be returned. */
export interface GetDocuments {
    /** Total number of records available within a collection query */
    total: Total;
    /** List of documents. */
    records?: GetDocumentsList;
}

/** List of documents. */
export type GetDocumentsList = GetDocument[];

/** Get document response object. Contains the ID along with the configured name and document. */
export interface GetDocument {
    /** ID of an object within the collection. */
    id: ID;
    /** Name of an object within the collection (searchable). */
    name: Name;
    /**
     * Data to store for the document. The filetype describes the type of document. Call /filetype_configurations endpoint to get a list of supported filetypes.
     * Optionally the encoding of the document can be given. If this is not set, the process will try to detect the encoding of the data.
     * To add data, set (only) one of the following fields: text, base64, private, public.
     * In case the data is passed on as 'text' or 'base64', it is not stored permanently and cannot be accessed again.
     */
    document: Document;
}

/** Update document request object. Both name and document can be updated. */
export interface UpdateDocument {
    /** Name of an object within the collection (searchable). */
    name?: Name;
    /**
     * Data to store for the document. The filetype describes the type of document. Call /filetype_configurations endpoint to get a list of supported filetypes.
     * Optionally the encoding of the document can be given. If this is not set, the process will try to detect the encoding of the data.
     * To add data, set (only) one of the following fields: text, base64, private, public.
     * In case the data is passed on as 'text' or 'base64', it is not stored permanently and cannot be accessed again.
     */
    document?: Document;
}

/** Get flows response object. A list of flows along with the total number of flows that can be returned. */
export interface GetFlows {
    /** Total number of records available within a collection query */
    total: Total;
    /** List of flows. */
    records?: GetFlowsList;
}

/** List of flows. */
export type GetFlowsList = GetFlow[];

/** Get flow response object. Contains the name of the flow and a JSON Schema of the flow config. */
export interface GetFlow {
    /** Name of an object within the collection (searchable). */
    name: Name;
    /** JSON Schema definition */
    config_model: JSONSchema;
}

/** Get filetype configurations response object. A list of filetype configurations along with the total number that can be returned. */
export interface GetFiletypeConfigurations {
    /** Total number of records available within a collection query */
    total: Total;
    /** List of filetype configurations. */
    records?: GetFiletypeConfigurationsList;
}

/** List of filetype configurations. */
export type GetFiletypeConfigurationsList = GetFiletypeConfiguration[];

/** Get filetype configuration response object. Contains the name of the filetype and a JSON Schema of the filetype config. */
export interface GetFiletypeConfiguration {
    /** Name of an object within the collection (searchable). */
    name: Name;
    /** JSON Schema definition */
    config_model: JSONSchema;
}

/** Get connectiontypes response object. A list of connectiontypes along with the total number of connectiontypes that can be returned. */
export interface GetConnectiontypes {
    /** Total number of records available within a collection query */
    total: Total;
    /** List of connectiontypes. */
    records?: GetConnectiontypesList;
}

/** List of connectiontypes. */
export type GetConnectiontypesList = GetConnectiontype[];

/** Wether the connection can be used to refer documents. */
export type DocumentSupport = boolean;

/** Wether the connection can be used to store the output of the pipeline and plugins. */
export type DestinationSupport = boolean;

/** Get connectiontype response object. Contains the name of the connectiontype and wether it is a document and/or destination connection. */
export interface GetConnectiontype {
    /** Name of an object within the collection (searchable). */
    name: Name;
    /** Wether the connection can be used to refer documents. */
    document_support: DocumentSupport;
    /** Wether the connection can be used to store the output of the pipeline and plugins. */
    destination_support: DestinationSupport;
}

/** Plugin to execute on the flow result once it has been completed. */
export interface FlowPlugin {
    /** Name of an object within the collection (searchable). */
    name: Name;
    config?: PluginConfiguration;
}

/** List of flow plugins. */
export type FlowPluginsList = FlowPlugin[];

/** Flow configuration of a pipeline */
export interface PipelineFlowConfiguration {
    /** Name of an object within the collection (searchable). */
    name: Name;
    config?: FlowConfiguration;
}

/**
 * Create pipeline configuration request object. Contains a name, the flow, config and plugin(s) to execute, the Dynizer connection to store the result, webhook configuration and a client ID.
 * Each field except 'name' can be omitted. In case fields connection_id or flow_configuration are empty, they will have to be provided when starting the pipeline.
 */
export interface CreatePipelineConfiguration {
    /** Name of an object within the collection (searchable). */
    name: Name;
    /** ID of an object within the collection. */
    connection_id?: ID;
    /** Flow configuration of a pipeline */
    flow_configuration?: PipelineFlowConfiguration;
    /** List of flow plugins. */
    plugins?: FlowPluginsList;
    /** Setting this will add these static values to the resulting instances. */
    instance?: Instance;
    /**
     * Webhook configuration object. Optionally configure a webhook that will be called on status updates.
     * The endpoint will be called with method POST. The body contains the pipeline ID and status.
     * The provided endpoint should be reachable without authentication by the API and should return HTTP 200 when succesfully handled.
     * If the endpoint is unreachable or returns a status code that is not HTTP 200, the API will retry the webhook up until a maximum of 10 times with an increasing timeout.
     * The receiving webhook handler should always fetch the pipeline by its ID to be sure the incoming request and/or data is valid.
     * The status is only informative to be able to discard updates that aren't relevant to an implementation, but should only be used for this purpose.
     */
    webhook?: Webhook;
    /** Optional ID for client side verification */
    client_id?: ClientID;
}

/** Create pipeline configuration response object. Contains a unique ID to refer to the pipeline configuration. */
export interface CreatePipelineConfigurationResult {
    /** ID of an object within the collection. */
    id: ID;
}

/** Get pipeline configurations response object. A list of pipeline configurations along with the total number of configurations that can be returned. */
export interface GetPipelineConfigurations {
    /** Total number of records available within a collection query */
    total: Total;
    /** List of pipeline configurations. */
    records?: GetPipelineConfigurationsList;
}

/** List of pipeline configurations. */
export type GetPipelineConfigurationsList = GetPipelineConfiguration[];

/** Get pipeline configuration response object. Contains the ID along with the configured name, flow, config, plugins, connection, instance, webhook and client ID. */
export interface GetPipelineConfiguration {
    /** ID of an object within the collection. */
    id: ID;
    /** Name of an object within the collection (searchable). */
    name: Name;
    /** ID of an object within the collection. */
    connection_id?: ID;
    /** Flow configuration of a pipeline */
    flow_configuration?: PipelineFlowConfiguration;
    /** List of flow plugins. */
    plugins?: FlowPluginsList;
    /** Setting this will add these static values to the resulting instances. */
    instance?: Instance;
    /**
     * Webhook configuration object. Optionally configure a webhook that will be called on status updates.
     * The endpoint will be called with method POST. The body contains the pipeline ID and status.
     * The provided endpoint should be reachable without authentication by the API and should return HTTP 200 when succesfully handled.
     * If the endpoint is unreachable or returns a status code that is not HTTP 200, the API will retry the webhook up until a maximum of 10 times with an increasing timeout.
     * The receiving webhook handler should always fetch the pipeline by its ID to be sure the incoming request and/or data is valid.
     * The status is only informative to be able to discard updates that aren't relevant to an implementation, but should only be used for this purpose.
     */
    webhook?: Webhook;
    /** Optional ID for client side verification */
    client_id?: ClientID;
}

/** Update pipeline configuration request object. The name, flow, config, plugins, connection, instance, webhook and client ID can be updated. */
export interface UpdatePipelineConfiguration {
    /** Name of an object within the collection (searchable). */
    name?: Name;
    /** ID of an object within the collection. */
    connection_id?: ID;
    /** Flow configuration of a pipeline */
    flow_configuration?: PipelineFlowConfiguration;
    /** List of flow plugins. */
    plugins?: FlowPluginsList;
    /** Setting this will add these static values to the resulting instances. */
    instance?: Instance;
    /**
     * Webhook configuration object. Optionally configure a webhook that will be called on status updates.
     * The endpoint will be called with method POST. The body contains the pipeline ID and status.
     * The provided endpoint should be reachable without authentication by the API and should return HTTP 200 when succesfully handled.
     * If the endpoint is unreachable or returns a status code that is not HTTP 200, the API will retry the webhook up until a maximum of 10 times with an increasing timeout.
     * The receiving webhook handler should always fetch the pipeline by its ID to be sure the incoming request and/or data is valid.
     * The status is only informative to be able to discard updates that aren't relevant to an implementation, but should only be used for this purpose.
     */
    webhook?: Webhook;
    /** Optional ID for client side verification */
    client_id?: ClientID;
}

/**
 * Execute pipeline request object. Set the 'configuration_id' field to the pipeline configuration ID to execute.
 * Additionally, 'configuration' can be set to override any parameters from the pipeline configuration. The configurations will be merged before execution.
 * It is allowed to omit the 'configuration_id' and pass a full config in the 'configuration' field.
 * Either the document ID can be set in the 'document_id' field to refer to a previously configured document, or a full document can be passed in the 'document' field.
 * In the case a full document is given, it will not be stored and available via the /documents endpoint.
 */
export interface CreatePipeline {
    /** ID of an object within the collection. */
    configuration_id?: ID;
    /**
     * Create pipeline configuration request object. Contains a name, the flow, config and plugin(s) to execute, the Dynizer connection to store the result, webhook configuration and a client ID.
     * Each field except 'name' can be omitted. In case fields connection_id or flow_configuration are empty, they will have to be provided when starting the pipeline.
     */
    configuration?: CreatePipelineConfiguration;
    /** ID of an object within the collection. */
    document_id?: ID;
    /**
     * Data to store for the document. The filetype describes the type of document. Call /filetype_configurations endpoint to get a list of supported filetypes.
     * Optionally the encoding of the document can be given. If this is not set, the process will try to detect the encoding of the data.
     * To add data, set (only) one of the following fields: text, base64, private, public.
     * In case the data is passed on as 'text' or 'base64', it is not stored permanently and cannot be accessed again.
     */
    document?: Document;
}

/**
 * A request object used for retrieving pipelines based on specific filtering criteria.
 * Use the 'client_ids' property to retrieve only those pipelines associated with any of the specified client IDs.
 * No pipelines will be returned if client_ids is not given or empty.
 */
export interface GetFilteredPipelines {
    /** List of client IDs. */
    client_ids?: ClientIDs;
}

/**
 * Webhook configuration object. Optionally configure a webhook that will be called on status updates.
 * The endpoint will be called with method POST. The body contains the pipeline ID and status.
 * The provided endpoint should be reachable without authentication by the API and should return HTTP 200 when succesfully handled.
 * If the endpoint is unreachable or returns a status code that is not HTTP 200, the API will retry the webhook up until a maximum of 10 times with an increasing timeout.
 * The receiving webhook handler should always fetch the pipeline by its ID to be sure the incoming request and/or data is valid.
 * The status is only informative to be able to discard updates that aren't relevant to an implementation, but should only be used for this purpose.
 */
export interface Webhook {
    /** Full URI string. */
    endpoint: URI;
}

/**
 * Restart pipeline request object. Contains optional plugin(s) to execute, instance to write as metadata, webhook configuration and a client ID.
 * All options will overwite the current stored values of the pipeline. If the options are not given, the run will use the original values for these fields.
 * Setting flag 'reanalyze' to 'true' reanalyzes the document and clears the output. If 'false', only the plugins are executed.
 * The flag 'clear_data' will cause the plugins to erase all data, if they support it. Data is cleared from the current configured plugins, not the new ones passed in the body (if there are any).
 * Status, start and finish times are reset.
 */
export interface PipelineRestart {
    /** Wether to reanalyze the result */
    reanalyze?: Reanalyze;
    /** Wether to clear the data generated by the plugins (if the plugin supports it) */
    clear_data?: ClearData;
    /** List of flow plugins. */
    plugins?: FlowPluginsList;
    /** Setting this will add these static values to the resulting instances. */
    instance?: Instance;
    /**
     * Webhook configuration object. Optionally configure a webhook that will be called on status updates.
     * The endpoint will be called with method POST. The body contains the pipeline ID and status.
     * The provided endpoint should be reachable without authentication by the API and should return HTTP 200 when succesfully handled.
     * If the endpoint is unreachable or returns a status code that is not HTTP 200, the API will retry the webhook up until a maximum of 10 times with an increasing timeout.
     * The receiving webhook handler should always fetch the pipeline by its ID to be sure the incoming request and/or data is valid.
     * The status is only informative to be able to discard updates that aren't relevant to an implementation, but should only be used for this purpose.
     */
    webhook?: Webhook;
    /** Optional ID for client side verification */
    client_id?: ClientID;
}

/** Wether to reanalyze the result */
export type Reanalyze = boolean;

/** Wether to clear the data generated by the plugins (if the plugin supports it) */
export type ClearData = boolean;

/** Wether to clear all the plugins (empty array does no changes) */
export type ClearPlugins = boolean;

/** List of client IDs. */
export type ClientIDs = ClientID[];

/** Optional ID for client side verification */
export type ClientID = string;

/** Timestamp */
export type Timestamp = string;

/** The status the pipeline is currently in. The following statuses can be returned: QUEUED_LOCAL, QUEUED, RUNNING, FAILED, SUCCESS, CANCELED, PAUSED */
export type PipelineStatus = string;

/** A pipeline step. Contains data about the start/end time and the status it is currently in */
export interface PipelineStep {
    /** Name of an object within the collection (searchable). */
    name: Name;
    /** Timestamp */
    started_at: Timestamp;
    /** Timestamp */
    finished_at?: Timestamp;
    /** The status the pipeline is currently in. The following statuses can be returned: QUEUED_LOCAL, QUEUED, RUNNING, FAILED, SUCCESS, CANCELED, PAUSED */
    status: PipelineStatus;
}

/** The pipeline individual steps' */
export type PipelineSteps = PipelineStep[];

/** Info of a pipeline. Contains data about the start/end time, status/step it is currently in and additional error info if the pipeline failed. */
export interface PipelineInfo {
    /** Timestamp */
    requested_at?: Timestamp;
    /** Timestamp */
    started_at?: Timestamp;
    /** Timestamp */
    finished_at?: Timestamp;
    /** The status the pipeline is currently in. The following statuses can be returned: QUEUED_LOCAL, QUEUED, RUNNING, FAILED, SUCCESS, CANCELED, PAUSED */
    status: PipelineStatus;
    /** The pipeline individual steps' */
    steps?: PipelineSteps;
    /** Pipeline error information */
    error?: PipelineError;
}

/** Pipeline error information */
export interface PipelineError {
    /** Pipeline error message */
    message: PipelineErrorMessage;
    /** Pipeline error log */
    log?: PipelineErrorLog;
}

/** Pipeline error message */
export type PipelineErrorMessage = string;

/** Pipeline error log */
export type PipelineErrorLog = string;

/** Execute pipeline response object. Contains a unique ID to refer to the pipeline and an info object describing the current status of the pipeline. */
export interface CreatePipelineResult {
    /** ID of an object within the collection. */
    id: ID;
    /** Info of a pipeline. Contains data about the start/end time, status/step it is currently in and additional error info if the pipeline failed. */
    info: PipelineInfo;
}

/** Get pipelines response object. A list of pipelines along with the total number of pipelines that can be returned. */
export interface GetPipelines {
    /** Total number of records available within a collection query */
    total: Total;
    /** List of pipelines. */
    records?: GetPipelinesList;
}

/** List of pipelines. */
export type GetPipelinesList = GetPipeline[];

/**
 * Get pipeline response object. Contains the ID along with the status, configuration and document of the pipeline run.
 * Optionally contains the output of the the pipeline, if there is any.
 */
export interface GetPipeline {
    /** ID of an object within the collection. */
    id: ID;
    /** ID of an object within the collection. */
    configuration_id?: ID;
    /**
     * Create pipeline configuration request object. Contains a name, the flow, config and plugin(s) to execute, the Dynizer connection to store the result, webhook configuration and a client ID.
     * Each field except 'name' can be omitted. In case fields connection_id or flow_configuration are empty, they will have to be provided when starting the pipeline.
     */
    configuration?: CreatePipelineConfiguration;
    /** Info of a pipeline. Contains data about the start/end time, status/step it is currently in and additional error info if the pipeline failed. */
    info: PipelineInfo;
    /** ID of an object within the collection. */
    document_id?: ID;
    /** Get document response object. Contains the ID along with the configured name and document. */
    document?: GetDocument;
    output?: PipelineOutput;
}

export type PipelineOutput = any;

/** The operation to execute on the pipeline update. */
export type Operation = string;

/** Update pipeline request object. The following operations are supported: CANCEL, PAUSE, RESUME. */
export interface UpdatePipeline {
    /** The operation to execute on the pipeline update. */
    operation: Operation;
}

/** The docker repository of the plugin container */
export type DockerRepository = string;

/** The docker image tag of the plugin container */
export type DockerImageTag = string;

/** Create a plugin. Contains a name, the docker repository and image tag, documentation URL and an icon URL. The name of the plugin must be unique. */
export interface CreatePlugin {
    /** Name of an object within the collection (searchable). */
    name: Name;
    /** The docker repository of the plugin container */
    docker_repository: DockerRepository;
    /** The docker image tag of the plugin container */
    docker_image_tag: DockerImageTag;
    /** Full URI string. */
    documentation_url?: URI;
    /** Full URI string. */
    icon?: URI;
}

/** Get plugins response object. A list of plugins along with the total number that can be returned. */
export interface GetPlugins {
    /** Total number of records available within a collection query */
    total: Total;
    /** List of plugins. */
    records?: GetPluginsList;
}

/** List of plugins. */
export type GetPluginsList = GetPlugin[];

/** Get plugin response object. Contains the name along with the configuration. */
export interface GetPlugin {
    /** Name of an object within the collection (searchable). */
    name?: Name;
    /** The docker repository of the plugin container */
    docker_repository?: DockerRepository;
    /** The docker image tag of the plugin container */
    docker_image_tag?: DockerImageTag;
    /** Full URI string. */
    documentation_url?: URI;
    /** Full URI string. */
    icon?: URI;
    /** JSON Schema definition */
    config_model?: JSONSchema;
}

/** Update plugin request object. */
export interface UpdatePlugin {
    /** Name of an object within the collection (searchable). */
    name?: Name;
    /** The docker repository of the plugin container */
    docker_repository?: DockerRepository;
    /** The docker image tag of the plugin container */
    docker_image_tag?: DockerImageTag;
    /** Full URI string. */
    documentation_url?: URI;
    /** Full URI string. */
    icon?: URI;
}

/** Get analyzers response object. A list of registered analyzers. */
export interface GetAnalyzers {
    records?: GetAnalyzerList;
}

export type GetAnalyzerList = GetAnalyzer[];

/** A registered Analyzer. Contains the endpoint of the analyzer, how much processes are available and the flow names it supports. */
export interface GetAnalyzer {
    /** Full URI string. */
    endpoint: URI;
    /** Number of processes available */
    process_available: ProcessAvailable;
    /** Amount of memory available */
    memory_available: MemoryAvailable;
    /** Number of processes queued */
    process_queued: ProcessQueued;
    /** List of names. */
    flow_names: NameList;
    /** Timestamp */
    updated_at: Timestamp;
}

/** Result information about an uploaded large object. */
export interface UploadLOB {
    /** Path part of a URI. */
    path: URIPath;
}

/** The $id keyword defines a URI for the schema, and the base URI that other URI references within the schema are resolved against. */
export type JSONSchemaID = string;

/** The $schema keyword states that this schema is written according to a specific draft of the standard and used for a variety of reasons, primarily version control. */
export type JSONSchemaSchema = string;

/** The title and description annotation keywords are descriptive only. They do not add constraints to the data being validated. The intent of the schema is stated with these two keywords. */
export type JSONSchemaTitle = string;

/** The title and description annotation keywords are descriptive only. They do not add constraints to the data being validated. The intent of the schema is stated with these two keywords. */
export type JSONSchemaDescription = string;

/** The type validation keyword defines the first constraint on our JSON data. */
export type JSONSchemaType = string;

export type JSONSchemaRequired = string[];

export type JSONSchemaProperties = any;

/** JSON Schema definition */
export interface JSONSchema {
    /** The $id keyword defines a URI for the schema, and the base URI that other URI references within the schema are resolved against. */
    $id: JSONSchemaID;
    /** The $schema keyword states that this schema is written according to a specific draft of the standard and used for a variety of reasons, primarily version control. */
    $schema: JSONSchemaSchema;
    /** The title and description annotation keywords are descriptive only. They do not add constraints to the data being validated. The intent of the schema is stated with these two keywords. */
    title?: JSONSchemaTitle;
    /** The title and description annotation keywords are descriptive only. They do not add constraints to the data being validated. The intent of the schema is stated with these two keywords. */
    description?: JSONSchemaDescription;
    /** The type validation keyword defines the first constraint on our JSON data. */
    type: JSONSchemaType;
    required?: JSONSchemaRequired;
    properties: JSONSchemaProperties;
}

/** Setting this will add these static values to the resulting instances. */
export interface Instance {
    /** A list of Dynizer instance elements */
    instance_elements: InstanceElements;
}

/** A list of Dynizer instance elements */
export type InstanceElements = InstanceElement[];

export interface InstanceElement {
    /** A label within a Dynizer instance. */
    action_label: ActionLabel;
    /** A component of a Dynizer action label. */
    component: Component;
    /** A datatype of a Dynizer action label. */
    default_data_type: DataType;
    /** A list of value specs. */
    specs: Specs;
    /** Amount of values that have to occur for a dynizer action label */
    min_occurence: MinOccurence;
    /** Amount of values that may occur for a dynizer action label */
    max_occurence: MaxOccurence;
    /** A list of dynizer instance values. */
    values: InstanceValues;
}

/**
 * Amount of values that have to occur for a dynizer action label
 * @example 0
 */
export type MinOccurence = number;

/**
 * Amount of values that may occur for a dynizer action label
 * @example 3
 */
export type MaxOccurence = number;

/** A list of value specs. */
export type Specs = Spec[];

/**
 * A position of a Dynizer action label.
 * @example 1
 */
export type Position = number;

/** Spec of a value. Contains the position of the value and a datatype/format pair. */
export interface Spec {
    /** A position of a Dynizer action label. */
    position?: Position;
    /** Datatype and/or format of a value */
    override?: DataTypeFormat;
}

/** Datatype and/or format of a value */
export interface DataTypeFormat {
    /** A datatype of a Dynizer action label. */
    data_type?: DataType;
    /** A format string of a Dynizer instance value. */
    format?: Format;
}

/**
 * A datatype of a Dynizer action label.
 * @example "DT_String"
 */
export type DataType = string;

/**
 * A format string of a Dynizer instance value.
 * @example "2006-01-02T15:04:05Z"
 */
export type Format = string;

/**
 * A component of a Dynizer action label.
 * @example "What"
 */
export type Component = string;

/** A list of dynizer instance values. */
export type InstanceValues = InstanceValue[];

/**
 * A label within a Dynizer instance.
 * @example "LabelName"
 */
export type ActionLabel = string;

/**
 * A value within a Dynizer instance.
 * @example "test"
 */
export type InstanceValue = string;

/** API error. */
export interface Error {
    /** @format int32 */
    code: number;
    message: string;
}

import type {
    AxiosInstance,
    AxiosRequestConfig,
    AxiosResponse,
    HeadersDefaults,
    ResponseType,
} from "axios";
import axios from "axios";

export type QueryParamsType = Record<string | number, any>;

export interface FullRequestParams
    extends Omit<
        AxiosRequestConfig,
        "data" | "params" | "url" | "responseType"
    > {
    /** set parameter to `true` for call `securityWorker` for this request */
    secure?: boolean;
    /** request path */
    path: string;
    /** content type of request body */
    type?: ContentType;
    /** query params */
    query?: QueryParamsType;
    /** format of response (i.e. response.json() -> format: "json") */
    format?: ResponseType;
    /** request body */
    body?: unknown;
}

export type RequestParams = Omit<
    FullRequestParams,
    "body" | "method" | "query" | "path"
>;

export interface ApiConfig<SecurityDataType = unknown>
    extends Omit<AxiosRequestConfig, "data" | "cancelToken"> {
    securityWorker?: (
        securityData: SecurityDataType | null
    ) => Promise<AxiosRequestConfig | void> | AxiosRequestConfig | void;
    secure?: boolean;
    format?: ResponseType;
}

export enum ContentType {
    Json = "application/json",
    FormData = "multipart/form-data",
    UrlEncoded = "application/x-www-form-urlencoded",
    Text = "text/plain",
}

export class HttpClient<SecurityDataType = unknown> {
    public instance: AxiosInstance;
    private securityData: SecurityDataType | null = null;
    private securityWorker?: ApiConfig<SecurityDataType>["securityWorker"];
    private secure?: boolean;
    private format?: ResponseType;

    constructor({
        securityWorker,
        secure,
        format,
        ...axiosConfig
    }: ApiConfig<SecurityDataType> = {}) {
        this.instance = axios.create({
            ...axiosConfig,
            baseURL: axiosConfig.baseURL || "",
        });
        this.secure = secure;
        this.format = format;
        this.securityWorker = securityWorker;
    }

    public setSecurityData = (data: SecurityDataType | null) => {
        this.securityData = data;
    };

    protected mergeRequestParams(
        params1: AxiosRequestConfig,
        params2?: AxiosRequestConfig
    ): AxiosRequestConfig {
        const method = params1.method || (params2 && params2.method);

        return {
            ...this.instance.defaults,
            ...params1,
            ...(params2 || {}),
            headers: {
                ...((method &&
                    this.instance.defaults.headers[
                        method.toLowerCase() as keyof HeadersDefaults
                    ]) ||
                    {}),
                ...(params1.headers || {}),
                ...((params2 && params2.headers) || {}),
            },
        };
    }

    protected stringifyFormItem(formItem: unknown) {
        if (typeof formItem === "object" && formItem !== null) {
            return JSON.stringify(formItem);
        } else {
            return `${formItem}`;
        }
    }

    protected createFormData(input: Record<string, unknown>): FormData {
        return Object.keys(input || {}).reduce((formData, key) => {
            const property = input[key];
            const propertyContent: any[] =
                property instanceof Array ? property : [property];

            for (const formItem of propertyContent) {
                const isFileType =
                    formItem instanceof Blob || formItem instanceof File;
                formData.append(
                    key,
                    isFileType ? formItem : this.stringifyFormItem(formItem)
                );
            }

            return formData;
        }, new FormData());
    }

    public request = async <T = any, _E = any>({
        secure,
        path,
        type,
        query,
        format,
        body,
        ...params
    }: FullRequestParams): Promise<AxiosResponse<T>> => {
        const secureParams =
            ((typeof secure === "boolean" ? secure : this.secure) &&
                this.securityWorker &&
                (await this.securityWorker(this.securityData))) ||
            {};
        const requestParams = this.mergeRequestParams(params, secureParams);
        const responseFormat = format || this.format || undefined;

        if (
            type === ContentType.FormData &&
            body &&
            body !== null &&
            typeof body === "object"
        ) {
            body = this.createFormData(body as Record<string, unknown>);
        }

        if (
            type === ContentType.Text &&
            body &&
            body !== null &&
            typeof body !== "string"
        ) {
            body = JSON.stringify(body);
        }

        return this.instance.request({
            ...requestParams,
            headers: {
                ...(requestParams.headers || {}),
                ...(type && type !== ContentType.FormData
                    ? { "Content-Type": type }
                    : {}),
            },
            params: query,
            responseType: responseFormat,
            data: body,
            url: path,
        });
    };
}

/**
 * @title Pipeline API
 * @version 1.0.0
 * @license TODO
 * @termsOfService TODO
 * @contact Consono <info@consono.ai> (https://consono.ai)
 *
 * TODO
 */
export class Api<
    SecurityDataType extends unknown
> extends HttpClient<SecurityDataType> {
    login = {
        /**
         * @description Send a login authentication request.
         *
         * @tags Login
         * @name PostLogin
         * @request POST:/login
         */
        postLogin: (data: Login, params: RequestParams = {}) =>
            this.request<LoginToken, Error>({
                path: `/login`,
                method: "POST",
                body: data,
                ...params,
            }),
    };
    authenticationConfigurations = {
        /**
         * @description Get all defined authentication configurations.
         *
         * @tags Authentication Configurations
         * @name GetAuthenticationConfigurations
         * @request GET:/authentication_configurations
         * @secure
         */
        getAuthenticationConfigurations: (
            query?: {
                /** Search string to search within the collection. */
                search?: SearchString;
                /**
                 * Defines how many records at max are returned.
                 * If fewer records are available, all will be returned.
                 * Omitting or setting this to -1 will be interpreted as no limit
                 */
                limit?: Limit;
                /**
                 * Defines the offset at which to start reading.
                 * An offset of 0 means to start from the first possible record.
                 */
                offset?: Offset;
            },
            params: RequestParams = {}
        ) =>
            this.request<GetAuthenticationConfigurations, Error>({
                path: `/authentication_configurations`,
                method: "GET",
                query: query,
                secure: true,
                ...params,
            }),

        /**
         * @description Create a new authentication configuration.
         *
         * @tags Authentication Configurations
         * @name PostAuthenticationConfigurations
         * @request POST:/authentication_configurations
         * @secure
         */
        postAuthenticationConfigurations: (
            data: CreateAuthenticationConfiguration,
            params: RequestParams = {}
        ) =>
            this.request<CreateAuthenticationConfigurationResult, Error>({
                path: `/authentication_configurations`,
                method: "POST",
                body: data,
                secure: true,
                ...params,
            }),

        /**
         * @description Get an authentication configuration by ID.
         *
         * @tags Authentication Configurations
         * @name GetAuthenticationConfigurationById
         * @request GET:/authentication_configurations/{id}
         * @secure
         */
        getAuthenticationConfigurationById: (
            id: ID,
            params: RequestParams = {}
        ) =>
            this.request<GetAuthenticationConfiguration, Error>({
                path: `/authentication_configurations/${id}`,
                method: "GET",
                secure: true,
                ...params,
            }),

        /**
         * @description Update an authentication configuration by ID.
         *
         * @tags Authentication Configurations
         * @name PutAuthenticationConfigurationById
         * @request PUT:/authentication_configurations/{id}
         * @secure
         */
        putAuthenticationConfigurationById: (
            id: ID,
            data: UpdateAuthenticationConfiguration,
            params: RequestParams = {}
        ) =>
            this.request<void, Error>({
                path: `/authentication_configurations/${id}`,
                method: "PUT",
                body: data,
                secure: true,
                ...params,
            }),

        /**
         * @description Delete an authentication configuration by ID.
         *
         * @tags Authentication Configurations
         * @name DeleteAuthenticationConfigurationById
         * @request DELETE:/authentication_configurations/{id}
         * @secure
         */
        deleteAuthenticationConfigurationById: (
            id: ID,
            params: RequestParams = {}
        ) =>
            this.request<void, Error>({
                path: `/authentication_configurations/${id}`,
                method: "DELETE",
                secure: true,
                ...params,
            }),
    };
    connections = {
        /**
         * @description Get all defined connections.
         *
         * @tags Connections
         * @name GetConnections
         * @request GET:/connections
         * @secure
         */
        getConnections: (
            query?: {
                /** Search string to search within the collection. */
                search?: SearchString;
                /**
                 * Defines how many records at max are returned.
                 * If fewer records are available, all will be returned.
                 * Omitting or setting this to -1 will be interpreted as no limit
                 */
                limit?: Limit;
                /**
                 * Defines the offset at which to start reading.
                 * An offset of 0 means to start from the first possible record.
                 */
                offset?: Offset;
            },
            params: RequestParams = {}
        ) =>
            this.request<GetConnections, Error>({
                path: `/connections`,
                method: "GET",
                query: query,
                secure: true,
                ...params,
            }),

        /**
         * @description Create a new connection.
         *
         * @tags Connections
         * @name PostConnections
         * @request POST:/connections
         * @secure
         */
        postConnections: (data: CreateConnection, params: RequestParams = {}) =>
            this.request<CreateConnectionResult, Error>({
                path: `/connections`,
                method: "POST",
                body: data,
                secure: true,
                ...params,
            }),

        /**
         * @description Get a connection by ID.
         *
         * @tags Connections
         * @name GetConnectionById
         * @request GET:/connections/{id}
         * @secure
         */
        getConnectionById: (id: ID, params: RequestParams = {}) =>
            this.request<GetConnection, Error>({
                path: `/connections/${id}`,
                method: "GET",
                secure: true,
                ...params,
            }),

        /**
         * @description Update a connection by ID.
         *
         * @tags Connections
         * @name PutConnectionById
         * @request PUT:/connections/{id}
         * @secure
         */
        putConnectionById: (
            id: ID,
            data: UpdateConnection,
            params: RequestParams = {}
        ) =>
            this.request<void, Error>({
                path: `/connections/${id}`,
                method: "PUT",
                body: data,
                secure: true,
                ...params,
            }),

        /**
         * @description Delete a connection by ID. This operation will not delete any data on the storage itself.
         *
         * @tags Connections
         * @name DeleteConnectionById
         * @request DELETE:/connections/{id}
         * @secure
         */
        deleteConnectionById: (id: ID, params: RequestParams = {}) =>
            this.request<void, Error>({
                path: `/connections/${id}`,
                method: "DELETE",
                secure: true,
                ...params,
            }),

        /**
         * @description Download a large object from a connection. Specify the connection ID in the path parameter, and the server path in the query parameters.
         *
         * @tags Connections
         * @name GetConnectionByIdStorage
         * @request GET:/connections/{id}/storage
         * @secure
         */
        getConnectionByIdStorage: (
            id: ID,
            query: {
                /** Server path to upload/download a large object. */
                path: URIPath;
            },
            params: RequestParams = {}
        ) =>
            this.request<any, Error>({
                path: `/connections/${id}/storage`,
                method: "GET",
                query: query,
                secure: true,
                ...params,
            }),

        /**
         * @description Upload a large object to a connection. Specify the connection ID in the path parameter, and the server path in the query parameters.
         *
         * @tags Connections
         * @name PostConnectionByIdStorage
         * @request POST:/connections/{id}/storage
         * @secure
         */
        postConnectionByIdStorage: (
            id: ID,
            query: {
                /** Server path to upload/download a large object. */
                path: URIPath;
            },
            data: UploadLOB,
            params: RequestParams = {}
        ) =>
            this.request<UploadLOB, Error>({
                path: `/connections/${id}/storage`,
                method: "POST",
                query: query,
                body: data,
                secure: true,
                ...params,
            }),

        /**
         * @description Delete a large object from a connection. Specify the connection ID in the path parameter, and the server path in the query parameters.
         *
         * @tags Connections
         * @name DeleteConnectionByIdStorage
         * @request DELETE:/connections/{id}/storage
         * @secure
         */
        deleteConnectionByIdStorage: (
            id: ID,
            query: {
                /** Server path to upload/download a large object. */
                path: URIPath;
            },
            params: RequestParams = {}
        ) =>
            this.request<void, Error>({
                path: `/connections/${id}/storage`,
                method: "DELETE",
                query: query,
                secure: true,
                ...params,
            }),
    };
    documents = {
        /**
         * @description Get all defined documents.
         *
         * @tags Documents
         * @name GetDocuments
         * @request GET:/documents
         * @secure
         */
        getDocuments: (
            query?: {
                /** Search string to search within the collection. */
                search?: SearchString;
                /**
                 * Defines how many records at max are returned.
                 * If fewer records are available, all will be returned.
                 * Omitting or setting this to -1 will be interpreted as no limit
                 */
                limit?: Limit;
                /**
                 * Defines the offset at which to start reading.
                 * An offset of 0 means to start from the first possible record.
                 */
                offset?: Offset;
            },
            params: RequestParams = {}
        ) =>
            this.request<GetDocuments, Error>({
                path: `/documents`,
                method: "GET",
                query: query,
                secure: true,
                ...params,
            }),

        /**
         * @description Create a new document.
         *
         * @tags Documents
         * @name PostDocuments
         * @request POST:/documents
         * @secure
         */
        postDocuments: (data: CreateDocument, params: RequestParams = {}) =>
            this.request<CreateDocumentResult, Error>({
                path: `/documents`,
                method: "POST",
                body: data,
                secure: true,
                ...params,
            }),

        /**
         * @description Get a document by ID.
         *
         * @tags Documents
         * @name GetDocumentById
         * @request GET:/documents/{id}
         * @secure
         */
        getDocumentById: (id: ID, params: RequestParams = {}) =>
            this.request<GetDocument, Error>({
                path: `/documents/${id}`,
                method: "GET",
                secure: true,
                ...params,
            }),

        /**
         * @description Update a document by ID.
         *
         * @tags Documents
         * @name PutDocumentById
         * @request PUT:/documents/{id}
         * @secure
         */
        putDocumentById: (
            id: ID,
            data: UpdateDocument,
            params: RequestParams = {}
        ) =>
            this.request<void, Error>({
                path: `/documents/${id}`,
                method: "PUT",
                body: data,
                secure: true,
                ...params,
            }),

        /**
         * @description Delete a document by ID.
         *
         * @tags Documents
         * @name DeleteDocumentById
         * @request DELETE:/documents/{id}
         * @secure
         */
        deleteDocumentById: (id: ID, params: RequestParams = {}) =>
            this.request<void, Error>({
                path: `/documents/${id}`,
                method: "DELETE",
                secure: true,
                ...params,
            }),
    };
    flows = {
        /**
         * @description Get all flows.
         *
         * @tags Flows
         * @name GetFlows
         * @request GET:/flows
         * @secure
         */
        getFlows: (
            query?: {
                /** Search string to search within the collection. */
                search?: SearchString;
                /**
                 * Defines how many records at max are returned.
                 * If fewer records are available, all will be returned.
                 * Omitting or setting this to -1 will be interpreted as no limit
                 */
                limit?: Limit;
                /**
                 * Defines the offset at which to start reading.
                 * An offset of 0 means to start from the first possible record.
                 */
                offset?: Offset;
            },
            params: RequestParams = {}
        ) =>
            this.request<GetFlows, Error>({
                path: `/flows`,
                method: "GET",
                query: query,
                secure: true,
                ...params,
            }),

        /**
         * @description Get a flow by Name.
         *
         * @tags Flows
         * @name GetFlowByName
         * @request GET:/flows/{name}
         * @secure
         */
        getFlowByName: (name: Name, params: RequestParams = {}) =>
            this.request<GetFlow, Error>({
                path: `/flows/${name}`,
                method: "GET",
                secure: true,
                ...params,
            }),
    };
    filetypeConfigurations = {
        /**
         * @description Get all filetype configurations.
         *
         * @tags Filetype Configurations
         * @name GetFiletypeConfigurations
         * @request GET:/filetype_configurations
         * @secure
         */
        getFiletypeConfigurations: (
            query?: {
                /** Search string to search within the collection. */
                search?: SearchString;
                /**
                 * Defines how many records at max are returned.
                 * If fewer records are available, all will be returned.
                 * Omitting or setting this to -1 will be interpreted as no limit
                 */
                limit?: Limit;
                /**
                 * Defines the offset at which to start reading.
                 * An offset of 0 means to start from the first possible record.
                 */
                offset?: Offset;
            },
            params: RequestParams = {}
        ) =>
            this.request<GetFiletypeConfigurations, Error>({
                path: `/filetype_configurations`,
                method: "GET",
                query: query,
                secure: true,
                ...params,
            }),

        /**
         * @description Get a filetype configuration by name.
         *
         * @tags Filetype Configurations
         * @name GetFiletypeConfigurationByName
         * @request GET:/filetype_configurations/{name}
         * @secure
         */
        getFiletypeConfigurationByName: (
            name: Name,
            params: RequestParams = {}
        ) =>
            this.request<GetFiletypeConfiguration, Error>({
                path: `/filetype_configurations/${name}`,
                method: "GET",
                secure: true,
                ...params,
            }),
    };
    connectiontypes = {
        /**
         * @description Get all connectiontypes.
         *
         * @tags Connectiontypes
         * @name GetConnectiontypes
         * @request GET:/connectiontypes
         * @secure
         */
        getConnectiontypes: (
            query?: {
                /** Search string to search within the collection. */
                search?: SearchString;
                /**
                 * Defines how many records at max are returned.
                 * If fewer records are available, all will be returned.
                 * Omitting or setting this to -1 will be interpreted as no limit
                 */
                limit?: Limit;
                /**
                 * Defines the offset at which to start reading.
                 * An offset of 0 means to start from the first possible record.
                 */
                offset?: Offset;
            },
            params: RequestParams = {}
        ) =>
            this.request<GetConnectiontypes, Error>({
                path: `/connectiontypes`,
                method: "GET",
                query: query,
                secure: true,
                ...params,
            }),

        /**
         * @description Get a connectiontype by Name.
         *
         * @tags Connectiontypes
         * @name GetConnectiontypeByName
         * @request GET:/connectiontypes/{name}
         * @secure
         */
        getConnectiontypeByName: (name: Name, params: RequestParams = {}) =>
            this.request<GetConnectiontype, Error>({
                path: `/connectiontypes/${name}`,
                method: "GET",
                secure: true,
                ...params,
            }),
    };
    pipelineConfigurations = {
        /**
         * @description Get all defined pipeline configurations.
         *
         * @tags Pipeline Configurations
         * @name GetPipelineConfigurations
         * @request GET:/pipeline_configurations
         * @secure
         */
        getPipelineConfigurations: (
            query?: {
                /** Search string to search within the collection. */
                search?: SearchString;
                /**
                 * Defines how many records at max are returned.
                 * If fewer records are available, all will be returned.
                 * Omitting or setting this to -1 will be interpreted as no limit
                 */
                limit?: Limit;
                /**
                 * Defines the offset at which to start reading.
                 * An offset of 0 means to start from the first possible record.
                 */
                offset?: Offset;
            },
            params: RequestParams = {}
        ) =>
            this.request<GetPipelineConfigurations, Error>({
                path: `/pipeline_configurations`,
                method: "GET",
                query: query,
                secure: true,
                ...params,
            }),

        /**
         * @description Create a new pipeline configuration.
         *
         * @tags Pipeline Configurations
         * @name PostPipelineConfigurations
         * @request POST:/pipeline_configurations
         * @secure
         */
        postPipelineConfigurations: (
            data: CreatePipelineConfiguration,
            params: RequestParams = {}
        ) =>
            this.request<CreatePipelineConfigurationResult, Error>({
                path: `/pipeline_configurations`,
                method: "POST",
                body: data,
                secure: true,
                ...params,
            }),

        /**
         * @description Get a pipeline configuration by ID.
         *
         * @tags Pipeline Configurations
         * @name GetPipelineConfigurationById
         * @request GET:/pipeline_configurations/{id}
         * @secure
         */
        getPipelineConfigurationById: (id: ID, params: RequestParams = {}) =>
            this.request<GetPipelineConfiguration, Error>({
                path: `/pipeline_configurations/${id}`,
                method: "GET",
                secure: true,
                ...params,
            }),

        /**
         * @description Update a pipeline configuration by ID. This update only applies to new pipelines and does not impact existing pipelines.
         *
         * @tags Pipeline Configurations
         * @name PutPipelineConfigurationById
         * @request PUT:/pipeline_configurations/{id}
         * @secure
         */
        putPipelineConfigurationById: (
            id: ID,
            data: UpdatePipelineConfiguration,
            params: RequestParams = {}
        ) =>
            this.request<void, Error>({
                path: `/pipeline_configurations/${id}`,
                method: "PUT",
                body: data,
                secure: true,
                ...params,
            }),

        /**
         * @description Delete a pipeline configuration by ID.
         *
         * @tags Pipeline Configurations
         * @name DeletePipelineConfigurationById
         * @request DELETE:/pipeline_configurations/{id}
         * @secure
         */
        deletePipelineConfigurationById: (id: ID, params: RequestParams = {}) =>
            this.request<void, Error>({
                path: `/pipeline_configurations/${id}`,
                method: "DELETE",
                secure: true,
                ...params,
            }),
    };
    pipelines = {
        /**
         * @description Get all executed pipelines.
         *
         * @tags Pipelines
         * @name GetPipelines
         * @request GET:/pipelines
         * @secure
         */
        getPipelines: (
            query?: {
                /** Search string to search within the collection. */
                search?: SearchString;
                /** Status string to filter on pipeline status within the collection. */
                status?: PipelineStatus;
                /**
                 * Defines how many records at max are returned.
                 * If fewer records are available, all will be returned.
                 * Omitting or setting this to -1 will be interpreted as no limit
                 */
                limit?: Limit;
                /**
                 * Defines the offset at which to start reading.
                 * An offset of 0 means to start from the first possible record.
                 */
                offset?: Offset;
                /** Flag to indicate wether to include the pipeline output. */
                include_output?: IncludeOutput;
                /** Flag to indicate wether to include the pipeline document. */
                include_document?: IncludeDocument;
            },
            params: RequestParams = {}
        ) =>
            this.request<GetPipelines, Error>({
                path: `/pipelines`,
                method: "GET",
                query: query,
                secure: true,
                ...params,
            }),

        /**
         * @description Execute a new pipeline.
         *
         * @tags Pipelines
         * @name PostPipelines
         * @request POST:/pipelines
         * @secure
         */
        postPipelines: (data: CreatePipeline, params: RequestParams = {}) =>
            this.request<CreatePipelineResult, Error>({
                path: `/pipelines`,
                method: "POST",
                body: data,
                secure: true,
                ...params,
            }),

        /**
         * @description Get a pipeline by ID.
         *
         * @tags Pipelines
         * @name GetPipelineById
         * @request GET:/pipelines/{id}
         * @secure
         */
        getPipelineById: (
            id: ID,
            query?: {
                /** Flag to indicate wether to include the pipeline output. */
                include_output?: IncludeOutput;
                /** Flag to indicate wether to include the pipeline document. */
                include_document?: IncludeDocument;
            },
            params: RequestParams = {}
        ) =>
            this.request<GetPipeline, Error>({
                path: `/pipelines/${id}`,
                method: "GET",
                query: query,
                secure: true,
                ...params,
            }),

        /**
         * @description Update a pipeline by ID.
         *
         * @tags Pipelines
         * @name PutPipelineById
         * @request PUT:/pipelines/{id}
         * @secure
         */
        putPipelineById: (
            id: ID,
            data: UpdatePipeline,
            params: RequestParams = {}
        ) =>
            this.request<void, Error>({
                path: `/pipelines/${id}`,
                method: "PUT",
                body: data,
                secure: true,
                ...params,
            }),

        /**
         * @description Delete a pipeline by ID.
         *
         * @tags Pipelines
         * @name DeletePipelineById
         * @request DELETE:/pipelines/{id}
         * @secure
         */
        deletePipelineById: (id: ID, params: RequestParams = {}) =>
            this.request<void, Error>({
                path: `/pipelines/${id}`,
                method: "DELETE",
                secure: true,
                ...params,
            }),

        /**
         * @description Restart (a part of) a pipeline.
         *
         * @tags Pipelines
         * @name PostPipelinesByIdRestart
         * @request POST:/pipelines/{id}/restart
         * @secure
         */
        postPipelinesByIdRestart: (
            id: ID,
            data: PipelineRestart,
            params: RequestParams = {}
        ) =>
            this.request<void, Error>({
                path: `/pipelines/${id}/restart`,
                method: "POST",
                body: data,
                secure: true,
                ...params,
            }),

        /**
         * @description Get the pipelines that meet the specified filter criteria.
         *
         * @tags Pipelines
         * @name GetFilteredPipelines
         * @request POST:/pipelines/filter
         * @secure
         */
        getFilteredPipelines: (
            data: GetFilteredPipelines,
            query?: {
                /** Flag to indicate wether to include the pipeline output. */
                include_output?: IncludeOutput;
                /** Flag to indicate wether to include the pipeline document. */
                include_document?: IncludeDocument;
            },
            params: RequestParams = {}
        ) =>
            this.request<GetPipelines, Error>({
                path: `/pipelines/filter`,
                method: "POST",
                query: query,
                body: data,
                secure: true,
                ...params,
            }),
    };
    plugins = {
        /**
         * @description Get all configured plugins.
         *
         * @tags Plugins
         * @name GetPlugins
         * @request GET:/plugins
         * @secure
         */
        getPlugins: (
            query?: {
                /** Search string to search within the collection. */
                search?: SearchString;
                /**
                 * Defines how many records at max are returned.
                 * If fewer records are available, all will be returned.
                 * Omitting or setting this to -1 will be interpreted as no limit
                 */
                limit?: Limit;
                /**
                 * Defines the offset at which to start reading.
                 * An offset of 0 means to start from the first possible record.
                 */
                offset?: Offset;
            },
            params: RequestParams = {}
        ) =>
            this.request<GetPlugins, Error>({
                path: `/plugins`,
                method: "GET",
                query: query,
                secure: true,
                ...params,
            }),

        /**
         * @description Create a new plugin.
         *
         * @tags Plugins
         * @name PostPlugins
         * @request POST:/plugins
         * @secure
         */
        postPlugins: (data: CreatePlugin, params: RequestParams = {}) =>
            this.request<void, Error>({
                path: `/plugins`,
                method: "POST",
                body: data,
                secure: true,
                ...params,
            }),

        /**
         * @description Get a plugin by name.
         *
         * @tags Plugins
         * @name GetPluginByName
         * @request GET:/plugins/{name}
         * @secure
         */
        getPluginByName: (name: Name, params: RequestParams = {}) =>
            this.request<GetPlugin, Error>({
                path: `/plugins/${name}`,
                method: "GET",
                secure: true,
                ...params,
            }),

        /**
         * @description Update a plugin by Name.
         *
         * @tags Plugins
         * @name PutPluginByName
         * @request PUT:/plugins/{name}
         * @secure
         */
        putPluginByName: (
            name: Name,
            data: UpdatePlugin,
            params: RequestParams = {}
        ) =>
            this.request<void, Error>({
                path: `/plugins/${name}`,
                method: "PUT",
                body: data,
                secure: true,
                ...params,
            }),

        /**
         * @description Delete a plugin by name.
         *
         * @tags Plugins
         * @name DeletePluginByName
         * @request DELETE:/plugins/{name}
         * @secure
         */
        deletePluginByName: (name: Name, params: RequestParams = {}) =>
            this.request<void, Error>({
                path: `/plugins/${name}`,
                method: "DELETE",
                secure: true,
                ...params,
            }),
    };
    analyzers = {
        /**
         * @description Get all registered analyzers.
         *
         * @tags Analyzers
         * @name GetAnalyzers
         * @request GET:/analyzers
         * @secure
         */
        getAnalyzers: (
            query?: {
                /**
                 * Defines how many records at max are returned.
                 * If fewer records are available, all will be returned.
                 * Omitting or setting this to -1 will be interpreted as no limit
                 */
                limit?: Limit;
                /**
                 * Defines the offset at which to start reading.
                 * An offset of 0 means to start from the first possible record.
                 */
                offset?: Offset;
            },
            params: RequestParams = {}
        ) =>
            this.request<GetAnalyzers, Error>({
                path: `/analyzers`,
                method: "GET",
                query: query,
                secure: true,
                ...params,
            }),
    };
    healthz = {
        /**
         * @description k8s style health check. Returns 200 if running correctly
         *
         * @tags Health check
         * @name GetHealthz
         * @request GET:/healthz
         */
        getHealthz: (params: RequestParams = {}) =>
            this.request<void, Error>({
                path: `/healthz`,
                method: "GET",
                ...params,
            }),
    };
}
