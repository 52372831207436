import { app, authentication } from "@microsoft/teams-js";
import { useEffect, useRef, useState } from "react";
import { v4 as newUuid } from "uuid";
import {
    actionLabelDocument,
    actionLabelTeamsId,
    actionName,
} from "../models/const";
import { Chat, Api as RagApi } from "../services/openapi/rag-axios/RagApi";

type Props = {
    tenant: string;
    fileIDs: string[];
    context: app.Context | undefined;
};

const Rag = ({ tenant, fileIDs, context }: Props) => {
    const refElm = useRef<any>(null);
    const [uuid, setUuid] = useState("");
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setUuid(newUuid());
        refElm.current.data = [];
        refElm.current.config = {
            summary_pct: 50,
            max_tokens: 1000,
            temperature: 0.5,
            frequency_penalty: 0.5,
            top_p: 0.5,
            top_k: 10,
        };
    }, []);

    useEffect(() => {
        const currentRef = refElm.current;

        const handleSend = async (event: any) => {
            if (loading) return;

            if (!event?.detail?.text) return;

            setLoading(true);

            let conversationWithUserMessage = [
                ...refElm.current.data,
                {
                    message: event.detail.text,
                    role: "USER",
                },
            ];

            refElm.current.data = [
                ...conversationWithUserMessage,
                {
                    message: "loading...",
                    role: "CHATBOT",
                    tool_calls: null,
                },
            ];

            let requestBody: Chat = {
                text: event.detail.text,
                frequency_penalty: event.detail.frequency_penalty,
                max_tokens: event.detail.max_tokens,
                summary_pct: event.detail.summary_pct,
                temperature: event.detail.temperature,
                top_k: event.detail.top_k,
                top_p: event.detail.top_p,
                conversation_id: uuid,
                api_url: `https://${tenant}.dynizer.com/api/v2`,
                rag_type: event.detail.dynizerAnnotation ? "ANNOTATE" : "TEXT",
                document_selector: {
                    action: actionName,
                    action_label: actionLabelDocument,
                    filter: [
                        ...fileIDs.map((id) => ({
                            action_label: actionLabelTeamsId,
                            value: id,
                        })),
                        // context?.team?.groupId
                        //     ? {
                        //           action_label: actionLabelTeamsGroupId,
                        //           value: context?.team?.groupId,
                        //       }
                        //     : {
                        //           action_label: actionLabelUserId,
                        //           value: context?.user?.id,
                        //       },
                    ],
                },
            };

            let dynizerJwtToken = await authentication.getAuthToken();

            try {
                let response = await ragApi.cohere.chat(requestBody, {
                    headers: {
                        Authorization: `Bearer ${dynizerJwtToken}`,
                    },
                });
                refElm.current.data = response.data.conversation;
            } catch {
                refElm.current.data = [
                    ...conversationWithUserMessage,
                    {
                        message:
                            "Something went wrong when sending the message",
                        role: "CHATBOT",
                        tool_calls: null,
                    },
                ];
            }

            setLoading(false);
        };

        currentRef.addEventListener("submit", handleSend);

        return () => {
            currentRef.removeEventListener("submit", handleSend);
        };
    }, [loading, tenant, fileIDs, uuid]);

    const ragApi = new RagApi({
        baseURL: "/rag-api",
        headers: {
            "Content-Type": "application/json;charset=UTF-8",
        },
    });

    return <consono-rag ref={refElm}></consono-rag>;
};

export default Rag;
