import { Spinner, teamsLightTheme, tokens } from "@fluentui/react-components";
import { useTeams } from "@microsoft/teamsfx-react";
import { useRef, useState } from "react";
import { ViewportList } from "react-viewport-list";

type Props = {
    jsxItems: JSX.Element[];
    loadNextPage: () => void;
    loadingMore: boolean;
    hasMore: boolean;
    nbLoadedFiled: number;
};

const VirtualScroller = ({
    jsxItems,
    loadNextPage,
    loadingMore,
    hasMore,
    nbLoadedFiled,
}: Props) => {
    const [hover, setHover] = useState(false);
    const [theme] = useTeams({});

    const ref = useRef(null);

    const loadMoreButton = (
        <div
            style={{
                backgroundColor: hover
                    ? tokens.colorNeutralStroke1Hover
                    : tokens.colorNeutralStroke1,
                cursor: "pointer",
                borderBottomLeftRadius: "5px",
                borderBottomRightRadius: "5px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
                height: "40px",
            }}
            onClick={() => !loadingMore && loadNextPage()}
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
            key={"load more"}
        >
            {loadingMore ? (
                <Spinner size="extra-small"></Spinner>
            ) : (
                <>
                    <div>Load more</div>
                    <div>
                        (showing {jsxItems.length} out of {nbLoadedFiled})
                    </div>
                </>
            )}
        </div>
    );

    const itemsAndButton = hasMore ? [...jsxItems, loadMoreButton] : jsxItems;

    return (
        <div
            style={{
                borderRadius: theme.theme === teamsLightTheme ? "0px" : "5px",
                overflow: "hidden",
            }}
        >
            <div className="list" ref={ref}>
                <ViewportList
                    viewportRef={ref}
                    items={itemsAndButton}
                    itemSize={72}
                    overscan={20}
                >
                    {(item, index) => item}
                </ViewportList>
            </div>
        </div>
    );
};
export default VirtualScroller;
