import React, { useEffect, useRef } from "react";

type Props = { inJson: any };

const ComponentDetection: React.FC<Props> = ({ inJson }) => {
    const refElm = useRef<any>(null);

    useEffect(() => {
        if (inJson) {
            refElm.current.data = JSON.parse(inJson).document;
        }
    }, [inJson]);

    return (
        <consono-component-detection ref={refElm}></consono-component-detection>
    );
};

export default ComponentDetection;
