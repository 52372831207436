import { Spinner } from "@fluentui/react-components";
import { center } from "./css";

export default function Loading() {
    return (
        <div style={center}>
            <Spinner></Spinner>
        </div>
    );
}
