import { authentication } from "@microsoft/teams-js";
import { Api as DynizerApi } from "../../services/openapi/dynizer-axios/Api";

export const checkTenant = async (input: string) => {
    let dynizerJwtToken = await authentication.getAuthToken();

    let dynizer = new DynizerApi({
        baseURL: "/dynizer-api",
        headers: {
            "Content-Type": "application/json; charset=UTF-8",
            "dynizer-tenant": input,
            Authorization: `Bearer ${dynizerJwtToken}`,
            "Ocp-Apim-Subscription-Key": "4245cf907d4d4d16af10205dfd761784",
        },
    });

    try {
        await dynizer.api.getMyRoles({});
        // 2XX status code
        return true;
    } catch (e: any) {
        // no 2XX status code
        return false;
    }
};
